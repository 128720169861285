import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SERVER_ENDPOINT);

const NotificationButton = () => {
  const notificationCount = 5;
  const navigate = useNavigate(); // Hook to navigate between routes

  const handleClick = () => {
    navigate("/ResponciveNotification"); // Navigate to the notifications page when clicked
  };

  useEffect(() => {
    // Connect socket on component mount
    socket.connect();

    // Listen for messages
    socket.on("notification", (data) => {
      console.log("Notification received", data);
      alert(data.message);
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div
      className="relative flex items-center justify-center p-[10px] rounded-full bg-[#2c2a2c] hover:bg-[#3C393C] cursor-pointer"
      onClick={handleClick}
    >
      {/* Notification Icon */}
      <img
        src="/Notificationicon.svg"
        alt="Notification Icon"
        className="w-5 h-5 object-contain"
      />

      {/* Notification Count */}
      {notificationCount > 0 && (
        <div
          className="absolute top-0 right-0 transform translate-x-1/1 -translate-y-1/1 w-5 h-5 bg-gradient-to-r from-[#FC10F2] to-[#3078E4] rounded-full flex items-center justify-center text-xs text-white font-bold"
          style={{
            background: "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
          }}
        >
          {notificationCount}
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
