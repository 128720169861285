import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "counter",
  initialState: {
    localState: {},
    userData: {},
  },
  reducers: {
    updateLocalStateData: (state, action) => {
      const { key, value } = action.payload;
      state.localState[key] = value;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    clearEntireStateData: (state, action) => {
      state.localState = {};
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchData.fulfilled, (state, action) => {
    //   state.data = action.payload;
    // });
  },
});

export const { updateLocalStateData, clearEntireStateData, setUserData } =
  userSlice.actions;

export default userSlice.reducer;
