import React from "react";
import BaseCard from "./BaseCard";
import correct from "../.././../assets/images/redcorrect.png";

const EnterpriseCard = ({ isAnnual }) => (
  <BaseCard
    tier="Enterprise"
    monthlyPrice="Custom"
    annualPrice="Custom"
    period="Monthly"
    buttonText="Talk to us"
    features={[
      "Customized minutes of royalty-free music",
      "Unlimited downloads",
      "Access to text, image, video & audio to music capabilities",
      "All business licenses",
      "Music editing & extension",
      "Song templates",
      "Dedicated customer success manager",
    ]}
    isAnnual={isAnnual}
    cardStyle={{
      border: "1px solid var(--Color-Light-Opacity-White-10, #FFFFFF1A)",
    }}
    path={correct}
  />
);

export default EnterpriseCard;
