import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateLocalStateData,
  clearEntireStateData,
} from "./../store/reducers/userSlice";

const LocalStateContext = createContext();

export const LocalStateProvider = ({ children }) => {
  const { localState } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const updateLocalState = (key, value) => {
    dispatch(updateLocalStateData({ key, value }));
  };

  const clearEntireState = () => {
    dispatch(clearEntireStateData({}));
  };

  return (
    <LocalStateContext.Provider
      value={{ localState, updateLocalState, clearEntireState }}
    >
      {children}
    </LocalStateContext.Provider>
  );
};

export const useLocalState = () => useContext(LocalStateContext);
