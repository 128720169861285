import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import subscriptionReducer from "./reducers/subscriptionSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    subscription: subscriptionReducer,
  },
});

export default store;
