import { ArrowRight } from "@gravity-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  setOpenOnboardingModal,
  setRedirectAfterSelectedPlan,
  setSubscriptionModal,
} from "../../store/reducers/subscriptionSlice";
import CreateFirstTrack from "./CreateFirstTrack";
import IntroMusicAnimation from "./IntroMusicAnimation";
import Subscription from "./Subscription/Subscription";

const Onboarding = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [showBillingPlans, setShowBillingPlans] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localState, updateLocalState } = useLocalState();
  const { openOnboardingModal } = useSelector((state) => state.subscription);

  //   useEffect(() => {
  //     const token = getCookie("token");
  //     if (!localState.profileCreated && !token) {
  //       navigate("/");
  //     }
  //   }, []);

  return (
    <>
      {openOnboardingModal && (
        <div className="fixed inset-0 z-50 flex justify-center bg-[#1D1B1D] bg-opacity-100 overflow-auto p-2">
          <div className="flex flex-col w-full max-w-4xl bg-[#100F10] border border-[#2c2a2c] p-4 px-6 pb-6 rounded-lg gap-6 mx-auto">
            <div className="flex justify-between w-full pb-4 items-center border-b border-b-[#2C2A2C]">
              {/* Top Left: Logo and Text */}
              <div
                className="relative h-9 flex items-center gap-2 rounded-2.5"
                id="sidebar-logo"
                onClick={() => navigate("/")}
              >
                <img
                  className="w-10 h-10 object-cover rounded-full cursor-pointer"
                  src="/logo@2x.png"
                  alt="Logo"
                  id="sidebar-logo-image"
                />
                <img
                  className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
                  src="/text_only_white.svg"
                  alt="White Text"
                  id="sidebar-logo-text-white"
                />
                <img
                  className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
                  src="/text_only_gradient.svg"
                  alt="Gradient Text"
                  id="sidebar-logo-text-gradient"
                />
              </div>

              {/* <div className="flex items-center gap-2">
                {showIntro && (
                  <>
                    <div className={`w-3 h-3 rounded-full bg-white`}></div>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                  </>
                )}
                {showBillingPlans && (
                  <>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                    <div className={`w-3 h-3 rounded-full bg-white`}></div>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                  </>
                )}
                {showSuggestions && (
                  <>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                    <div className={`w-3 h-3 rounded-full bg-[#3C393C]`}></div>
                    <div className={`w-3 h-3 rounded-full bg-white`}></div>
                  </>
                )}
              </div> */}

              {/* Right: Continue Button */}
              <div>
                {showIntro ? (
                  <button
                    onClick={() => {
                      // setShowIntro(false);
                      // setShowBillingPlans(true);
                      dispatch(setOpenOnboardingModal(false));
                      dispatch(setRedirectAfterSelectedPlan("/create-team"));
                      dispatch(setSubscriptionModal(true));
                    }}
                    className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500 ${"bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"}`}
                  >
                    Continue
                    <ArrowRight className="ml-2" />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      if (showBillingPlans) {
                        setShowBillingPlans(false);
                        setShowSuggestions(true);
                      } else if (showSuggestions) {
                        setShowSuggestions(false);
                        navigate("/");
                      }
                    }}
                    className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]`}
                  >
                    Skip
                    <ArrowRight className="ml-2" />
                  </button>
                )}
              </div>
            </div>
            {showIntro && <IntroMusicAnimation />}
            {showBillingPlans && <Subscription />}
            {showSuggestions && <CreateFirstTrack />}
          </div>
        </div>
      )}
    </>
  );
};

export default Onboarding;
