import React, { useState, useEffect } from "react";
import { Magnifier, Xmark } from "@gravity-ui/icons";
import { usePostHog } from "posthog-js/react";

const SearchBar = ({
  placeholder,
  className,
  value,
  onSearch,
  onClear,
  onVisibilityChange,
  fromDiscover = true,
}) => {
  const [searchTerm, setSearchTerm] = useState(value || "");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const mdBreakpoint = 868;
  const posthog = usePostHog();

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
    posthog.capture("Click to Search tracks", {
      page: fromDiscover ? "Discover" : "Liked tracks",
    });
  };

  const handleClear = () => {
    posthog.capture("Click to Clear search", {
      page: fromDiscover ? "Discover" : "Liked tracks",
    });
    setSearchTerm("");
    onClear();
  };

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pageWidth <= mdBreakpoint) {
      setIsSearchVisible(false);
    }
  }, [pageWidth]);

  const toggleSearchVisibility = () => {
    const newVisibility = !isSearchVisible;
    setIsSearchVisible(newVisibility);
    onVisibilityChange(newVisibility); // Notify parent component
  };

  return (
    <>
      <div
        className="hidden md:flex relative items-center w-full"
        id="search-bar"
      >
        <Magnifier className="absolute left-3 text-gray-500" />
        <input
          type="text"
          className={`pl-8 ${className}`}
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
        />
        {searchTerm && (
          <button
            onClick={handleClear}
            className="absolute right-4 text-gray-500 hover:text-white"
          >
            <Xmark />
          </button>
        )}
      </div>
      <div
        className="md:hidden relative flex items-center w-full"
        id="search-bar"
      >
        {fromDiscover ? (
          <Magnifier
            className={`absolute ${
              isSearchVisible ? `left-2.5 top-2.5` : `right-3 top-2.5`
            } text-gray-500`}
            onClick={toggleSearchVisibility}
          />
        ) : (
          <Magnifier
            className={`absolute ${
              isSearchVisible ? `left-2.5` : `right-3`
            } text-gray-500`}
            onClick={toggleSearchVisibility}
          />
        )}
        {isSearchVisible && (
          <input
            type="text"
            className={`pl-8 w-full ${className}`}
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleSearch}
          />
        )}
        {isSearchVisible && (
          <button
            onClick={toggleSearchVisibility}
            className="absolute right-3 text-gray-500 hover:text-white"
          >
            <Xmark />
          </button>
        )}
      </div>
    </>
  );
};

export default SearchBar;
