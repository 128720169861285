import React, { useState } from "react";
import { FaceFun, FaceNeutral, FaceSad } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import { usePostHog } from "posthog-js/react";

const FeedbackForm = ({ closeModal }) => {
  const posthog = usePostHog();
  const [feedback, setFeedback] = useState("");
  const [selectedMood, setSelectedMood] = useState(null);
  const isFormValid = feedback.trim() !== "";
  const [toastMessage, setToastMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiService.handleProtectedRoute("storeFeedback", {
        feedback,
        mood: selectedMood,
      });
      setToastMessage("Thanks! You rock! 🤘");
      posthog.capture("User Feedback Submitted");
      setTimeout(() => {
        closeModal();
      }, 1500);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <div className="md:w-[480px] w-[320px] bg-[#1D1B1D] border border-[#4E4A4F] rounded-lg text-white">
      <div className="pb-1 pt-3 px-4">
        <h2 className="text-lg font-medium text-left">Got Feedback?</h2>
      </div>
      <div className="px-4 pb-4">
        <p className="text-[#C5C3C6] text-sm font-extralight text-left pb-3">
          Your thoughts = our glow-up. What's good? What's meh? 💡✨
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            placeholder="Your feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="w-full min-h-[160px] bg-transparent border border-[#4E4A4F] rounded-md text-white text-sm font-extralight placeholder:text-[#878188] p-2"
          />
          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              {[
                { icon: FaceSad, mood: "sad" },
                { icon: FaceNeutral, mood: "neutral" },
                { icon: FaceFun, mood: "happy" },
              ].map(({ icon: Icon, mood }) => (
                <button
                  key={mood}
                  type="button"
                  className={`flex w-8 h-8 border border-[#4E4A4F] items-center justify-center rounded-md ${
                    selectedMood === mood ? "bg-[#4E4A4F]" : "bg-transparent"
                  } hover:bg-[#2D2B2D]`}
                  onClick={() => setSelectedMood(mood)}
                >
                  <Icon
                    className={`w-4 h-4 ${
                      selectedMood === mood ? "text-white" : "text-[#878188]"
                    }`}
                  />
                </button>
              ))}
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={closeModal}
                className="px-2 h-8 text-sm font-normal text-[#878188] hover:text-white"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isFormValid}
                className={`px-2 h-8 text-sm font-normal rounded-md ${
                  isFormValid
                    ? "bg-white text-[#100F10] hover:bg-[#E0DFE1]"
                    : "bg-[#4E4A4F] text-[#878188] cursor-not-allowed"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      {toastMessage && (
        <Toast
          type="success"
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default FeedbackForm;
