const timeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - new Date(date)) / 1000);
    
    const intervals = {
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    for (const [unit, value] of Object.entries(intervals)) {
        const elapsed = Math.floor(seconds / value);
        if (elapsed >= 1) {
            if (elapsed === 1) {
                return `${elapsed} ${unit} ago`;
            } else {
                return `${elapsed} ${unit}s ago`;
            }
        }
    }

    return "just now";
}

export default timeAgo