import React from "react";
import TeamDetails from "./TeamDetails";
import TeamMembers from "./TeamMembers";

const TeamSettings = ({ teamData }) => {
  return (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] rounded-lg relative w-full">
        <div
          id="account-settings-container"
          className="flex flex-col items-center scrollable-content-team-settings overflow-y-auto justify-top w-full"
        >
          <div className="relative max-w-3xl items-center w-full h-full">
            <div className="px-4 py-4 md:max-h-[calc(100vh-90px)] max-h-[calc(100vh-72px)]">
              <TeamDetails
                teamData={teamData?.teamDetails}
                avatarSrc={teamData?.teamDetails?.teamavatar}
              />
              <TeamMembers />
            </div>
          </div>
        </div>
      </div>

      <button className="fixed md:bottom-6 md:right-6 right-4 bottom-24 rounded-full bg-white text-white hover:bg-[#E0DFE1]">
        <img src="/questionMark.svg" alt="Help" className="p-2" />
      </button>
    </div>
  );
};

export default TeamSettings;
