import React from "react";

const EmptyState = ({
  icon, // JSX element for the icon
  title, // Title text
  description, // Description text
  buttonText, // Button label
  onButtonClick, // Button click handler
  goToIcon,
}) => {
  return (
    <div className="flex flex-col h-1/2 items-center justify-center text-white text-center">
      {/* Icon */}
      <div className="mb-4">{icon}</div>

      {/* Title */}
      <h1 className="text-2xl font-medium mb-2">{title}</h1>

      {/* Description */}
      <p className="text-white font-extralight mb-6">{description}</p>

      {/* Button */}
      <button
        onClick={onButtonClick}
        className="flex items-center gap-1 px-2 py-1 text-sm bg-white text-black rounded-md font-medium hover:bg-gray-100 transition"
      >
        {goToIcon}
        {buttonText}
      </button>
    </div>
  );
};

export default EmptyState;
