import React from "react";
import BaseCard from "./BaseCard";
import freeImage from "../.././../assets/images/correctmark.png";

const FreeCard = ({ isAnnual }) => (
  <BaseCard
    tier="Free"
    monthlyPrice="$0"
    annualPrice="$0"
    period="30 days trial"
    isActive={true}
    buttonText="Continue with Free"
    features={[
      "10 minutes of royalty-free music",
      "Unlimited downloads",
      "Access to text, image, video & audio to music capabilities",
      "Music editing & extension",
      "Song templates",
    ]}
    cardStyle={{
      background: "#1D1B1D",
      border: "1px solid var(--Color-Light-Opacity-White-10, #FFFFFF1A)",
    }}
    path={freeImage}
  />
);

export default FreeCard;
