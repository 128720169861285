import React, { useState, useEffect } from "react";
import { FaFileAlt } from "react-icons/fa";
import { MusicNote, Picture, Filmstrip } from "@gravity-ui/icons";
import { useLocalState } from "../../contexts/LocalStateProvider";

const truncateFileName = (fileName, maxLength) => {
  if (fileName) {
    const extension = fileName ? fileName?.split(".").pop() : "";
    const baseName = fileName.substring(
      0,
      fileName.length - extension.length - 1
    );

    if (baseName.length + extension.length + 1 <= maxLength) {
      return fileName;
    }

    const truncatedBaseName = baseName.substring(
      0,
      maxLength - extension.length - 1
    );
    return `${truncatedBaseName}...${extension}`;
  }
};

const UserChatBox = ({ message }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const { localState, updateLocalState } = useLocalState();

  useEffect(() => {
    if (localState.scrollToUserInput === message.id) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        updateLocalState("scrollToUserInput", null);
      }, 2000);
    }
  }, [localState.scrollToUserInput]);

  const getFileIcon = (fileType) => {
    if (fileType?.startsWith("audio/")) {
      return <MusicNote className="w-5 h-5 text-white" />;
    } else if (fileType?.startsWith("image/")) {
      return <Picture className="w-5 h-5 text-white" />;
    } else if (fileType?.startsWith("video/")) {
      return <Filmstrip className="w-5 h-5 text-white" />;
    } else {
      return <FaFileAlt className="w-5 h-5 text-white" />;
    }
  };

  return (
    <div
      id={`user-input-${message.id}`}
      className="flex flex-col items-end hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-3 rounded-lg mb-4 w-full max-w-screen-lg lg:max-w-screen-md"
      style={
        isHighlighted
          ? {
              background:
                "linear-gradient(0deg,rgba(0,0,0,0.72) 0%,rgba(0,0,0,0.72) 100%),linear-gradient(135deg,#FC10F2 0%,#3078E4 100%)",
            }
          : {}
      }
    >
      <div className="bg-[#2C2A2C] px-4 py-2 rounded-3xl md:max-w-[40vw] max-w-[70vw] inline-block">
        {message.file && (
          <div className="flex items-center mb-1 p-1.5 rounded-lg border border-solid border-[#3c393c]">
            <div className="w-10 h-10 py-2.5 mr-2 px-2.5 text-center bg-[#3c393c] rounded-md">
              {getFileIcon(message.file.type)}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-normal text-base text-white leading-none">
                {truncateFileName(message.file.name, 20)}
              </span>
              <span className="text-sm font-extralight text-gray-400 leading-none">
                {message.file.type}
              </span>
            </div>
          </div>
        )}
        <p className="font-extralight text-base text-white">{message.text}</p>
      </div>
    </div>
  );
};

export default UserChatBox;
