import React, { useState, useRef, useEffect } from "react";
import { ArrowLeft } from "@gravity-ui/icons";
import { useLocalState } from "../../contexts/LocalStateProvider";

const chunk = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
    array.slice(i * size, i * size + size)
  );
};

// const getRowSize = () => {
//   const width = window.innerWidth;
//   if (width < 640) return 2; // sm
//   if (width < 768) return 3; // md
//   if (width < 850) return 3; // lg
//   if (width < 1024) return 4; // lg
//   if (width < 1280) return 5; // xl
//   if (width < 1536) return 5; // 2xl
//   return 8; // 2xl and above
// };

const VideoCard = ({
  card,
  videoRef,
  onMouseEnter,
  onMouseLeave,
  onCardClick,
}) => (
  <div
    className="flex flex-col cursor-pointer"
    onMouseEnter={() => onMouseEnter(card.id)}
    onMouseLeave={() => onMouseLeave(card.id)}
    onClick={() => onCardClick(card)}
  >
    <div className="relative w-full h-[150px] rounded-lg overflow-hidden cursor-pointer hover:scale-[1.02] transition-transform duration-300 group">
      <video
        ref={(el) => videoRef(card.id, el)}
        src={card.video}
        loop
        muted
        playsInline
        className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-100"
      />
    </div>
    <div className="flex p-1">
      <h3 className="text-base font-medium text-white">{card.title}</h3>
    </div>
  </div>
);

const VinylCard = ({ card, onCardClick }) => (
  <div
    onClick={() => onCardClick(card)}
    className="flex-none group cursor-pointer w-full"
  >
    <div className="relative transition-all duration-700 w-full group-hover:w-[full] overflow-visible">
      <div
        className={`bg-card ${card.background} rounded-lg overflow-hidden w-full relative z-10`}
      >
        <img
          src="/CircularPattern.svg?height=300&width=300"
          alt={card.title}
          style={{ filter: "brightness(0.85)" }}
          className="w-full h-[200px] object-cover"
        />
        <div className="absolute left-[50%] top-[45%] -translate-x-1/2 -translate-y-1/2 z-10 w-[9rem] h-[9rem]">
          <img
            src={card.image}
            alt="Center Icon"
            className="w-full h-full rounded-full"
          />
        </div>
        <div className="">
          <h3 className="absolute left-2 bottom-2 text-md font-medium">
            {card.title}
          </h3>
        </div>
      </div>
      <div className="absolute top-1/2 left-[4%] transform -translate-y-1/2 transition-all duration-700 ease-in-out group-hover:left-[28%] group-hover:rotate-[60deg]">
        <div className="w-50 h-50 rounded-full">
          <img src="/Vinyl.svg" alt="Vinyl" />
        </div>
      </div>
    </div>
  </div>
);

const CardsCollection = ({
  onGoBackClick,
  title,
  cards,
  cardType = "video",
  onCardClick,
}) => {
  const videoRefs = useRef({});
  // const [rowSize, setRowSize] = useState(getRowSize());
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const { localState } = useLocalState();

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  const handleMouseEnter = (cardId) => {
    const videoObj = videoRefs.current[cardId];
    if (videoObj && videoObj.el) {
      videoObj.shouldPlay = true;
      const playPromise = videoObj.el.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // console.log("Play was prevented:", error);
        });
      }
    }
  };

  const handleMouseLeave = (cardId) => {
    const videoObj = videoRefs.current[cardId];
    if (videoObj && videoObj.el) {
      videoObj.shouldPlay = false;
      if (videoObj.isPlaying) {
        videoObj.el.pause();
        videoObj.el.currentTime = 0;
        videoObj.isPlaying = false;
      }
    }
  };

  // useEffect(() => {
  //   const handleResize = () => setRowSize(getRowSize());
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);

  //     // Clean up event listeners on unmount
  //     Object.values(videoRefs.current).forEach((videoObj) => {
  //       if (videoObj && videoObj.el && videoObj.listenersAdded) {
  //         videoObj.el.removeEventListener("playing", () => {});
  //         videoObj.el.removeEventListener("pause", () => {});
  //       }
  //     });
  //   };
  // }, []);

  const setVideoRef = (cardId, el) => {
    if (el) {
      if (!videoRefs.current[cardId]) {
        videoRefs.current[cardId] = {
          el,
          isPlaying: false,
          shouldPlay: false,
          listenersAdded: false,
        };
      } else {
        videoRefs.current[cardId].el = el;
      }

      if (!videoRefs.current[cardId].listenersAdded) {
        el.addEventListener("playing", () => {
          if (videoRefs.current[cardId]) {
            videoRefs.current[cardId].isPlaying = true;

            if (!videoRefs.current[cardId].shouldPlay) {
              // User is not hovering; pause the video
              videoRefs.current[cardId].el.pause();
              videoRefs.current[cardId].el.currentTime = 0;
              videoRefs.current[cardId].isPlaying = false;
            }
          }
        });

        el.addEventListener("pause", () => {
          if (videoRefs.current[cardId]) {
            videoRefs.current[cardId].isPlaying = false;
          }
        });

        videoRefs.current[cardId].listenersAdded = true;
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex items-center gap-3 px-4 py-3 border-b border-b-[#2C2A2C]">
        <div className="p-px rounded-full hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4]">
          <button
            onClick={onGoBackClick}
            className="p-1.5 bg-[#2c2a2c] rounded-full"
          >
            <ArrowLeft />
          </button>
        </div>
        <h1 className="text-xl font-medium">{title}</h1>
      </div>

      {cardType === "video" ? (
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 ${
            isAudioPlaying
              ? `max-h-[calc(100vh-150px)]`
              : `max-h-[calc(100vh-75px)]`
          } scrollable-content h-full`}
        >
          {cards.map((card) => (
            <VideoCard
              key={card.id}
              card={card}
              videoRef={setVideoRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onCardClick={onCardClick}
            />
          ))}
        </div>
      ) : (
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 ${
            isAudioPlaying
              ? `max-h-[calc(100vh-150px)]`
              : `max-h-[calc(100vh-75px)]`
          } scrollable-content h-full`}
        >
          {cards.map((card) => (
            <VinylCard key={card.id} card={card} onCardClick={onCardClick} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CardsCollection;
