import React, { useState, useEffect, useRef } from "react";
import ProjectCard from "./ProjectCard";
import ProjectListView from "./ProjectListView"; // Import ProjectListView component
import apiService from "../../api/apiService";
import Toast from "../../components/generic_components/Toast";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  Bars,
  Rectangles4,
  ChevronDown,
  ChevronUp,
  MusicNote,
} from "@gravity-ui/icons";
import ProjectCardLoader from "./ProjectCardLoader";
import LoadingSongs from "../custom_components/LoadingSongs";
import Modal from "../modal/Modal";
import CustomInput from "../custom_components/CustomInput";
import { update } from "lodash";
import { PostHogContext, usePostHog } from "posthog-js/react";
import NoResultsFound from "../custom_components/NoResultsFound"; // Add this import

// const LIMIT = 20; // Number of projects to fetch per batch

const ProjectGrid = () => {
  const posthog = usePostHog();
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const { updateLocalState, localState } = useLocalState();
  const [isGridView, setIsGridView] = useState(localState.isGridView || false); // State to toggle between grid and list views
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading status
  const [offset, setOffset] = useState(0); // Offset state to keep track of project batches
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Date edited");
  const dropdownRef = useRef(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const LIMIT = 20; // Projects per batch
  const SCROLL_THRESHOLD = 180; // Pixels from bottom to trigger next load

  const userAgent = navigator.userAgent.toLowerCase();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (totalProjects > 0 && localState.isGridView == null) {
      setIsGridView(totalProjects < 20);
    } else if (localState.isGridView !== null)
      setIsGridView(localState.isGridView);
  }, [totalProjects]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    "Anytime",
    "Today",
    "Yesterday",
    "Previous month",
    "Previous 3 months",
    "Previous year",
  ];

  const handleOptionClick = (option) => {
    posthog.capture("Click on Date Editing Filter", { option });
    setSelectedOption(option);
    setIsOpen(false);
  };

  const bufferToDataURL = (buffer, mimeType) => {
    const binary = Array.from(new Uint8Array(buffer))
      .map((b) => String.fromCharCode(b))
      .join("");
    const base64 = btoa(binary);
    return `data:${mimeType};base64,${base64}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    totalProjects > 0 && totalProjects < 20 && setIsGridView(true);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Fetch total number of projects on component mount
  useEffect(() => {
    const fetchTotalProjects = async () => {
      try {
        // if (localState.cachedProjects?.length) {
        //   // If totalProjects exists in local state, use it
        //   setTotalProjects(localState.cachedProjects.length);
        //   return;
        // }

        const totalData = await apiService.handleProtectedRoute(
          "fetchProjectsCount",
          {}
        );
        setTotalProjects(parseInt(totalData, 10)); // Ensure total is an integer
        updateLocalState("totalProjects", totalProjects);
        setInitialLoadComplete(true); // Set this after we have the count
        // console.log("Total Projects: ", totalData); // Debugging: Check total projects
      } catch (error) {
        console.error("Error fetching total projects count:", error);
        setToastMessage("Failed to load total projects count");
        setToastType("error");
      }
    };

    fetchTotalProjects();
  }, [loading, localState.newSongGenerated]);

  // Fetch projects and their latest AI responses in batches
  const fetchProjects = async (forceReload = false) => {
    if (
      (loading || localState.cachedProjects?.length >= totalProjects) &&
      !localState.newSongGenerated &&
      !localState.projectNameSetByUser &&
      !forceReload
    )
      return; // Prevent multiple simultaneous fetches and stop if all projects are loaded

    try {
      setLoading(true);
      const projectData = await apiService.handleProtectedRoute(
        "fetchProjectsWithLastAiResponse",
        { limit: LIMIT, offset: forceReload ? 0 : offset }
      );

      // If no more projects returned, we're done
      if (projectData.length === 0 && !forceReload) {
        setLoading(false);
        return;
      }

      posthog.capture("Projects Retrieved Successfully");

      // Process the fetched data and store it in local state
      const projectsWithAiResponses = projectData.map((project) => ({
        ...project,
        imageSrc: project.cover_image_name
          ? `/project-images/${project.cover_image_name}`
          : "/project-images/Album_Art_6.webp", // Fallback image

        audioSrc:
          project.response_id !== null
            ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${project.response_id}`
            : null,
        project_description:
          project.music_description?.trim() || "No description available",
      }));

      if (forceReload) {
        // Replace cached projects on forced reload
        updateLocalState("cachedProjects", projectsWithAiResponses);
        setOffset(LIMIT); // Reset offset
      } else {
        // Append new projects to the existing list
        const existingProjectIds = new Set(
          localState.cachedProjects?.map((p) => p.project_id) || []
        );
        const newProjects = projectsWithAiResponses.filter(
          (p) => !existingProjectIds.has(p.project_id)
        );
        const updatedProjects = [
          ...(localState.cachedProjects || []),
          ...newProjects,
        ];

        updateLocalState("cachedProjects", updatedProjects);
        setOffset((prev) => prev + LIMIT); // Update offset for the next fetch
      }

      // Reset the flags
      updateLocalState("newSongGenerated", false);
      updateLocalState("projectNameSetByUser", false);
    } catch (error) {
      posthog.capture("Failed to retrieve Projects");
      console.error("Error fetching projects:", error);
      setToastMessage("Failed to load projects");
      setToastType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      SCROLL_THRESHOLD;

    // Only fetch if we haven't loaded all projects yet
    if (
      bottom &&
      !loading &&
      localState?.cachedProjects?.length < totalProjects
    ) {
      fetchProjects(); // Normal fetch on scroll
    }
  };

  const LoadMoreButton = () => (
    <div className="flex justify-center my-4">
      <span className="px-4 py-2 [background:var(--Color-Light-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] font-medium hover:text-white duration-200 rounded-lg hover:bg-[#2C2A2C] transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
        {loading ? "Loading..." : "Load More"}
      </span>
    </div>
  );

  useEffect(() => {
    // Load first batch of projects
    if (!localState.cachedProjects?.length && !loading) {
      fetchProjects();
    }
  }, [totalProjects]);
  // Run effect whenever projects or totalProjects change, or loading state changes

  useEffect(() => {
    if (localState.newSongGenerated || localState.projectNameSetByUser) {
      fetchProjects(true); // Force refresh to fetch the latest projects
      updateLocalState("newSongGenerated", false); // Reset the flag
      updateLocalState("projectNameSetByUser", false);
    }
  }, [localState.newSongGenerated, localState.projectNameSetByUser]);

  // Function to handle project deletion and update state
  const handleDeleteProject = (deletedProjectId) => {
    posthog.capture("Project Deleted Successfully");
    setToastMessage("Project deleted successfully.");
    setToastType("success");
    const updatedProjects = localState.cachedProjects.filter(
      (project) => project.project_id !== deletedProjectId
    );
    updateLocalState("cachedProjects", updatedProjects);

    // Remove liked tracks for the deleted project
    const updatedLikedTracks = localState.likedProjectTracks?.filter(
      (track) => Number(track.project_id) !== Number(deletedProjectId)
    );
    updateLocalState("likedProjectTracks", updatedLikedTracks);

    // Update the total projects count
    setTotalProjects((prevTotal) => prevTotal - 1);
  };

  const filterProjectsByDate = (projects, selectedOption) => {
    const now = new Date();

    // Helper functions to get start and end of a day
    const getStartOfDay = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const getEndOfDay = (date) =>
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
      );

    const getStartDate = () => {
      switch (selectedOption) {
        case "Today":
          return {
            startDate: getStartOfDay(now),
            endDate: getEndOfDay(now),
          };
        case "Yesterday":
          const yesterday = new Date(now);
          yesterday.setDate(yesterday.getDate() - 1);
          return {
            startDate: getStartOfDay(yesterday),
            endDate: getEndOfDay(yesterday),
          };
        case "Last 30 days":
          const thirtyDaysAgo = new Date(now);
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
          return {
            startDate: thirtyDaysAgo,
            endDate: now,
          };
        case "Last 90 days":
          const ninetyDaysAgo = new Date(now);
          ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
          return {
            startDate: ninetyDaysAgo,
            endDate: now,
          };
        case "Last year":
          const lastYear = new Date(now);
          lastYear.setFullYear(lastYear.getFullYear() - 1);
          return {
            startDate: lastYear,
            endDate: now,
          };
        default:
          return null;
      }
    };

    const projectsToSort = [...projects];

    const dateRange = getStartDate();
    if (!dateRange || selectedOption === "Anytime") {
      // Sort projects by last_updated date
      return projectsToSort.sort(
        (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
      );
    }

    const { startDate, endDate } = dateRange;

    // Filter and sort projects
    return projectsToSort
      .filter((project) => {
        const projectDate = new Date(project.last_updated);
        return projectDate >= startDate && projectDate <= endDate;
      })
      .sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  };

  const EmptyProjectsState = () => (
    <div className="flex flex-col items-center justify-center h-full">
      <Rectangles4 className="w-8 h-8 text-[#ffff] mb-4" />
      <h3 className="text-xl font-medium text-white mb-2">No Projects Found</h3>
      <div className="flex flex-row gap-1 items-center mb-4">
        <p className="text-[#C5C3C6] text-base text-center font-extralight">
          To create a new project, simply click the button below or press
        </p>
        <div className="text-xs flex items-center text-[#C5C3C6]">
          {userAgent.includes("win") ? (
            <>
              <img src="/ctrl.svg" alt="Control Key Icon" className="mr-0.5" />
              +
              <img
                src="/keyword-I.svg"
                className="px-0.5"
                alt="Keyword I Icon"
              />
            </>
          ) : (
            <>
              <img src="/cmd.svg" alt="Command Key Icon" className="mr-0.5" />+
              <img
                src="/keyword-I.svg"
                className="px-0.5"
                alt="Keyword I Icon"
              />
            </>
          )}
        </div>
      </div>
      <div className="p-[0.09rem] hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] rounded-full">
        <div className="flex items-center gap-1 h-9 bg-[#100f10] rounded-full">
          <button
            className="flex items-center relative border border-[#2C2A2C] rounded-full justify-start w-full font-normal text-sm text-[#878188] h-9 pl-3 pr-20"
            onClick={handleOpenModal}
          >
            <img src="/Generate.svg" className="mr-2 w-4 h-4" />
            Create new
            <div className="absolute right-3 text-xs flex items-center">
              {userAgent.includes("win") ? (
                <>
                  <img
                    src="/ctrl.svg"
                    alt="Control Key Icon"
                    className="mr-0.5"
                  />
                  <img src="/keyword-I.svg" alt="Keyword I Icon" />
                </>
              ) : (
                <>
                  <img
                    src="/cmd.svg"
                    alt="Command Key Icon"
                    className="mr-0.5"
                  />
                  <img src="/keyword-I.svg" alt="Keyword I Icon" />
                </>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );

  // Update the projects display section:
  const filteredProjects =
    options.includes(selectedOption) && selectedOption !== "Date edited"
      ? filterProjectsByDate(localState.cachedProjects || [], selectedOption)
      : localState.cachedProjects || [];

  const isMobile = windowWidth < 768; // Adjust this breakpoint as needed

  return (
    <div className="projects-page">
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
      <div className="hidden md:block relative mx-4 mt-4 mb-3 w-auto h-[9rem] overflow-hidden">
        {/* Pseudo-element for rounded border */}
        <div className="absolute inset-0 rounded-t-lg pointer-events-none [background:linear-gradient(0deg,rgba(0,0,0,0.30)_0%,rgba(0,0,0,0.30)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] z-10"></div>

        {/* Folder SVG in the center with lower z-index */}
        <div className="absolute -bottom-[9rem] left-[50%] -translate-x-1/2 flex items-center justify-center z-10 opacity-70">
          <img
            src="/folder.svg"
            alt="Folder Icon"
            className="w-[19rem] h-[24rem]"
          />
        </div>

        {/* right circles */}
        <div className="absolute -top-[23rem] -right-[26rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />
        <div className="absolute -bottom-[35rem] -right-[10rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />

        {/* left circles */}
        <div className="absolute -bottom-80 -left-[40rem] w-[56rem] h-[56rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />
        <div className="absolute -bottom-[42rem] -left-[10em] w-[48rem] h-[48rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />

        {/* Bottom-left title */}
        <h1 className="absolute bottom-6 left-5 text-4xl font-normal text-white z-20">
          Projects
        </h1>
      </div>
      <div className="hidden md:flex flex-row justify-between pb-3 px-4">
        <div className="relative inline-block text-left" ref={dropdownRef}>
          {/* Button to toggle dropdown */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] text-sm hover:text-white duration-200 px-3 h-9 rounded-lg flex items-center space-x-2"
          >
            <span>{selectedOption}</span>
            {isOpen ? (
              <ChevronUp className="w-4 h-4 text-[#878188]" />
            ) : (
              <ChevronDown className="w-4 h-4 text-[#878188]" />
            )}
          </button>

          {/* Dropdown options */}
          {isOpen && (
            <div className="absolute mt-2 w-40 bg-[#100F10] border border-[#2C2A2C] rounded-lg shadow-lg z-20">
              <ul className="my-1">
                {options.map((option) => (
                  <li
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    className="cursor-pointer px-2 py-2 text-sm text-[#878188] hover:text-white hover:hover:bg-zinc-900 mx-1 rounded-md"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-end bg-[#1D1B1D] rounded-full gap-2.5 px-3">
          <button
            onClick={() => {
              setIsGridView(true);
              updateLocalState("isGridView", true);
              posthog.capture(
                "Click on Changing View for List Projects - Grid View"
              );
            }}
            className={`transition duration-300 ${
              isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-grid-view-button"
          >
            <Rectangles4 />
          </button>
          <button
            onClick={() => {
              setIsGridView(false);
              updateLocalState("isGridView", false);
              posthog.capture(
                "Click on Changing View for List Projects - List View"
              );
            }}
            className={`transition duration-300 ${
              !isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-list-view-button"
          >
            <Bars />
          </button>
        </div>
      </div>
      <div className="w-full border-t border-t-[#2C2A2C]"></div>
      {/* Render Grid View or List View based on state */}
      {!loading &&
      selectedOption !== "Date edited" &&
      totalProjects > 0 &&
      filteredProjects.length === 0 ? (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <NoResultsFound
            buttonText="Clear Filters"
            onClearFilters={() => {
              setSelectedOption("Date edited"); // Reset to default option
              posthog.capture("Cleared Date Filter");
            }}
          />
        </div>
      ) : !loading && !localState.cachedProjects?.length ? (
        <EmptyProjectsState />
      ) : loading && // Show nothing during initial load
        !localState.cachedProjects?.length ? (
        !isMobile && totalProjects < 20 && isGridView ? (
          <ProjectCardLoader itemCount={3} />
        ) : (
          <LoadingSongs itemCount={3} />
        ) // Show loaders while loading
      ) : isGridView && !isMobile ? (
        <div
          className={`project-grid-container max-h-[calc(100vh-${
            isAudioPlaying ? `320px` : `255px`
          })] overflow-y-auto p-4 scrollable-content`}
          onScroll={handleScroll}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {Array.isArray(filteredProjects) &&
              filteredProjects.map((project, index) => (
                <ProjectCard
                  key={index}
                  imageSrc={project.imageSrc}
                  projectName={project.project_name}
                  projectDescription={project.project_description}
                  audioSrc={project.audioSrc}
                  songTitle={project.song_title}
                  projectId={project.project_id}
                  responseId={project.response_id}
                  onDelete={handleDeleteProject}
                  songDuration={project.music_duration}
                  lastUpdated={project.last_updated}
                />
              ))}
          </div>
          {selectedOption === "Date edited" &&
            localState.cachedProjects?.length < totalProjects && (
              <LoadMoreButton />
            )}
        </div>
      ) : (
        <div
          className={`max-h-[calc(100vh-${
            isAudioPlaying && !isMobile
              ? `320px`
              : isMobile && isAudioPlaying
              ? `225px`
              : isMobile && !isAudioPlaying
              ? `165px`
              : !isMobile && !isAudioPlaying && `255px`
          })] min-h-[300px] overflow-y-auto scrollable-content`}
          onScroll={handleScroll}
        >
          {Array.isArray(filteredProjects) &&
            filteredProjects.map((project) => (
              <ProjectListView
                key={project.project_id}
                imageSrc={project.imageSrc}
                projectName={project.project_name}
                projectDescription={project.project_description}
                audioSrc={project.audioSrc}
                songTitle={project.song_title}
                projectId={project.project_id}
                responseId={project.response_id}
                onDelete={handleDeleteProject}
                songDuration={project.music_duration}
                lastUpdated={project.last_updated}
              />
            ))}
          {selectedOption === "Date edited" &&
            localState.cachedProjects?.length < totalProjects && (
              <LoadMoreButton />
            )}
        </div>
      )}

      {isModalOpen && (
        <Modal closeModal={handleCloseModal} showCloseButton={false}>
          <CustomInput
            className="w-full md:w-[40rem]"
            requestData={{ createProject: true }}
            chatPage={false}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectGrid;
