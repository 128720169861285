export const functionMapper = {
  validateVerifyUserAccess: (values) => {
    const errors = [];

    if (!values.access_code || values.access_code.trim() === "") {
      errors.push("Access code is required and cannot be empty.");
    } else if (/\s/.test(values.access_code)) {
      errors.push("Access code cannot contain spaces.");
    } else if (values.access_code.length < 6) {
      errors.push("Access code must be at least 6 characters long.");
    }

    return errors.join(". "); // Concatenate messages with a period and a space
  },

  // New password validation function
  handlePasswordValidation: (values) => {
    const errors = [];
    // Check if password is provided

    if (
      (!values.password || values.password.trim() === "") &&
      !values.confirmPassword &&
      values.confirmPassword.trim() === ""
    ) {
      errors.push("Both fields are required.");
    } else {
      if (!values.password || values.password.trim() === "") {
        errors.push("Password is required.");
      }

      // Check if confirmPassword is provided
      if (!values.confirmPassword || values.confirmPassword.trim() === "") {
        errors.push("Confirm Password is required.");
      }
    }

    // If both fields are provided, validate their content
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        errors.push("Passwords do not match.");
      } else if (/\s/.test(values.password)) {
        errors.push("Password cannot contain spaces.");
      } else if (values.password.length < 8) {
        errors.push("Password must be at least 8 characters long.");
      } else if (!/[A-Z]/.test(values.password)) {
        errors.push("Password must contain at least one uppercase letter.");
      } else if (!/[a-z]/.test(values.password)) {
        errors.push("Password must contain at least one lowercase letter.");
      } else if (!/[0-9]/.test(values.password)) {
        errors.push("Password must contain at least one number.");
      } else if (!/[!@#$%^&*]/.test(values.password)) {
        errors.push(
          "Password must contain at least one special character (e.g., !@#$%^&*)."
        );
      }
    }

    return errors.join(". "); // Concatenate messages with a period and a space
  },

  // Profile validation function
  validateProfileForm: (values) => {
    const errors = [];

    // Check if any field is empty or contains only spaces
    if (!values.fullName || values.fullName.trim() === "") {
      errors.push("Full Name is required.");
    }
    if (!values.company || values.company.trim() === "") {
      errors.push("Company is required.");
    }
    if (!values.role || values.role.trim() === "") {
      errors.push("Role is required.");
    }
    if (!values.reason || values.reason.trim() === "") {
      errors.push("Role is required.");
    }

    // Check if the user agreed to terms
    if (!values.agreedToTerms) {
      errors.push("You must agree to the Terms of Use and Privacy Policy.");
    }

    // If any errors, return a generic message
    if (errors.length > 0) {
      return "All fields are required.";
    }

    // Additional checks for minimum length
    if (values.fullName.trim().length < 3) {
      errors.push("Full Name must be at least 3 characters long.");
    }
    if (values.company.trim().length < 2) {
      errors.push("Company name must be at least 2 characters long.");
    }
    if (values.role.trim().length < 2) {
      errors.push("Role must be at least 2 characters long.");
    }

    // Return specific errors if any, otherwise return empty string
    return errors.length > 0 ? errors.join(". ") : "";
  },

  // Sign-In validation function
  validateSignIn: (values) => {
    const errors = [];

    // Email validation
    if (!values.email || values.email.trim() === "") {
      errors.push("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.push("Please enter a valid email address.");
    }

    // Password validation
    if (!values.password || values.password.trim() === "") {
      errors.push("Password is required.");
    }

    // Return error messages, joined by a period and space
    return errors.length > 0 ? errors.join(". ") : "";
  },

  // Sign-In validation function
  validateEmail: (values) => {
    const errors = [];

    // Email validation
    if (!values.email || values.email.trim() === "") {
      errors.push("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.push("Please enter a valid email address.");
    }

    // Return error messages, joined by a period and space
    return errors.length > 0 ? errors.join(". ") : "";
  },

  validateRSVPForm: (values) => {
    const errors = [];

    // Check if all fields are filled
    const requiredFields = ["email", "fullName", "company", "role", "reason"];
    const emptyFields = requiredFields.filter(
      (field) => !values[field] || values[field].trim() === ""
    );

    // If any field is empty, return a single error message
    if (emptyFields.length > 0) {
      return "All fields are required.";
    } else if (emptyFields.length === 0) {
      // Email validation
      if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.push("Please enter a valid email address.");
      }
    }
    // Return error messages, joined by a period and space
    return errors.length > 0 ? errors.join(". ") : "";
  },

  validateAndTruncateText: (text) => {
    if (!text.trim()) {
      return { isValid: false, message: "Input cannot be empty" };
    }
    if (text.length > 70) {
      const truncatedText = text.slice(0, 67) + "...";
      return { isValid: true, text: truncatedText };
    }

    return { isValid: true, text };
  },

  validateChangePassword: (values) => {
    const errors = [];

    // Check if all fields are filled
    if (
      !values.password ||
      values.password.trim() === "" ||
      !values.newPassword ||
      values.newPassword.trim() === "" ||
      !values.confirmPassword ||
      values.confirmPassword.trim() === ""
    ) {
      errors.push("All fields are required.");
    } else {
      // If all fields are provided, validate the new password and confirmation
      if (values.newPassword !== values.confirmPassword) {
        errors.push("New Password and Confirm New Password do not match.");
      }

      // Check if new password is the same as the current password
      if (values.newPassword === values.password) {
        errors.push("New Password cannot be the same as the current password.");
      }

      // New password complexity checks
      if (values.newPassword.length < 8) {
        errors.push("New Password must be at least 8 characters long.");
      }
      if (!/[A-Z]/.test(values.newPassword)) {
        errors.push("New Password must contain at least one uppercase letter.");
      }
      if (!/[a-z]/.test(values.newPassword)) {
        errors.push("New Password must contain at least one lowercase letter.");
      }
      if (!/[0-9]/.test(values.newPassword)) {
        errors.push("New Password must contain at least one number.");
      }
      if (!/[!@#$%^&*]/.test(values.newPassword)) {
        errors.push(
          "New Password must contain at least one special character (e.g., !@#$%^&*)."
        );
      }
    }

    // Return errors joined by a period and a space
    return errors.length > 0 ? errors.join(". ") : "";
  },

  // Validation function for deleting an account
  validateDeleteAccount: (values) => {
    const errors = [];

    // Check if all fields are filled
    if (
      !values.reason ||
      (values.reason.trim() === "" &&
        (!values.confirmDelete || values.confirmDelete.trim() === ""))
    ) {
      errors.push("All fields are required.");
    }
    // Check if the reason field is filled
    else if (!values.reason || values.reason.trim() === "") {
      errors.push("Please select a reason for deleting your account.");
    }

    // Check if the confirmDelete field matches the required text
    else if (
      !values.confirmDelete ||
      values.confirmDelete.trim().toUpperCase() !== "CONFIRM DELETE"
    ) {
      errors.push("Please type 'CONFIRM DELETE' to proceed.");
    }

    // Return error messages, joined by a period and space
    return errors.length > 0 ? errors.join(". ") : "";
  },

  confirmDeleteProject: () => {},
};
