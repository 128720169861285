import React, { useState, useRef, useEffect } from "react";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  Person,
  ArrowRightFromSquare,
  ChevronDown,
  ChevronUp,
} from "@gravity-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Modal from "../modal/Modal";
import apiService from "../../api/apiService";
import Subscription from "./Subscription/Subscription";
import { usePostHog } from "posthog-js/react";

const UserNameAndAvatar = ({ userAvatar }) => {
  const posthog = usePostHog();
  const { localState, updateLocalState } = useLocalState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    // Fetch user data once when the provider mounts
    const fetchUserData = async () => {
      try {
        const response = await apiService.handleProtectedRoute(
          "fetchUserNameAndAvatar",
          {}
        );

        // Process the avatar data here
        const userData = response.user;
        let avatarBase64 = "/logo.png"; // default avatar

        if (userData.avatar?.data) {
          avatarBase64 = `data:image/jpeg;base64,${arrayBufferToBase64(
            userData.avatar.data
          )}`;
        }

        // Update the user data with the base64 avatar
        const processedUserData = {
          ...userData,
          avatarBase64: avatarBase64,
        };

        // Store the processed user data in localState
        updateLocalState("userNameAndAvatar", { user: processedUserData });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (!localState.userNameAndAvatar || localState.profileCreated) {
      fetchUserData();
      updateLocalState("profileCreated", false);
    }
  }, [localState.profileCreated]); // Empty dependency array ensures this runs once on mount

  // Move arrayBufferToBase64 function here since we use it in the provider
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (!localState.userNameAndAvatar) {
    return (
      <div
        className={`flex items-center ${
          userAvatar ? "md:mt-3" : "px-1 pr-8"
        } text-left text-sm gap-2`}
      >
        <div
          className={`${
            userAvatar ? "w-6 h-6 md:w-8 md:h-8" : "w-8 h-8"
          } rounded-full bg-[#2C2A2C] animate-pulse`}
        />
        {!userAvatar && (
          <div className="h-4 w-24 bg-[#2C2A2C] rounded animate-pulse" />
        )}
      </div>
    );
  }

  const userData = localState.userNameAndAvatar.user;
  const avatarSrc = userData.avatarBase64;
  const fullname = userData.fullname || "User";

  const DropdownIcon = isDropdownOpen ? ChevronUp : ChevronDown;

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="relative">
      <div ref={dropdownRef}>
        <button
          className={`flex items-center ${
            userAvatar ? "md:mt-3" : "px-1"
          } text-left text-sm text-white gap-2`}
          onClick={() => {
            posthog.capture("Click User Name / Avatar button");
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <img
            src={avatarSrc}
            alt={fullname}
            className={userAvatar ? "w-6 h-6 md:w-8 md:h-8" : undefined}
            style={{
              borderRadius: "100%",
              width: userAvatar ? undefined : "2rem",
              height: userAvatar ? undefined : "2rem",
              objectFit: "cover",
            }}
          />
          {!userAvatar && (
            <span className="truncate max-w-[100px]">{fullname}</span>
          )}
        </button>
        {isDropdownOpen && (
          <div
            className={`absolute p-1 ${
              userAvatar
                ? "md:bottom-8 md:w-36 w-40 px-1 md:-left-2 right-0"
                : "bottom-9 left-7 w-36 mb-1"
            } font-normal text-sm text-[#878188] bg-[#100f10] rounded-xl border border-solid border-[#2c2a2c] shadow-lg z-10`}
          >
            <div
              className="px-2 py-2 cursor-pointer rounded-lg flex items-center gap-2 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
              onClick={() => {
                navigate("/settings");
                setIsDropdownOpen(false);
                posthog.capture("Click My Account Navigation");
              }}
            >
              <Person className="w-4 h-4" />
              My Account
            </div>
            <div
              className="px-2 py-2 cursor-pointer rounded-lg flex items-center gap-2 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
              onClick={() => {
                setShowModal(true);
                setIsDropdownOpen(false);
                posthog.capture("Click Sign Out in user dropdown");
              }}
            >
              <ArrowRightFromSquare className="w-4 h-4" />
              Sign out
            </div>
          </div>
        )}

        {showModal && (
          <Modal closeModal={closeModal}>
            <div className="text-white w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
              <h2 className="text-lg text-left px-3 py-3">Sign out</h2>
              <div className="w-full border-t border-t-[#2d2a2d]" />
              <p className="text-base font-extralight text-left pt-3 pb-5 px-3">
                Are you sure you want to sign out?
              </p>
              <div className="flex justify-end space-x-3 mx-3">
                <button
                  onClick={closeModal}
                  className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-sm text-white rounded-md"
                  id="sign-out-modal-cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    logout();
                    closeModal();
                    navigate("/");
                  }}
                  className="px-3 h-9 mb-3 mr-3 hover:bg-[#E0DFE1] bg-white text-[#100f10] text-sm font-normal rounded-md"
                  id="sign-out-modal-confirm-button"
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
      {/* <div className="absolute w-8 h-7 px-2 top-[10%] ml-[75%] text-white transition duration-200">
        <button
          variant="outline"
          size="sm"
          className="h-7 ml-3 bg-transparent bg-[#686369] px-3 text-xs font-normal rounded-md border border-[rgba(255,255,255,0.1)] text-white"
        >
          {"Basic"}
        </button>
      </div> */}
    </div>
  );
};

export default UserNameAndAvatar;
