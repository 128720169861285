import React, { useEffect, useState } from "react";
import { Bell } from "@gravity-ui/icons";
import Notifications from "./ResponsiveNotification";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SERVER_ENDPOINT);

const WubbleNotification = () => {
  const [openModal, setOpenModal] = useState(false);
  const userId = getCookie("user_id");
  const [notificationCount, setNotificationCount] = useState(0);

  // Add click handler for document
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside notification area
      const notificationBox = document.querySelector(".notification-container");
      if (
        notificationBox &&
        !notificationBox.contains(event.target) &&
        !event.target.closest(".notification-trigger")
      ) {
        setOpenModal(false);
        setNotificationCount(0);
      }
    };

    // Add event listener when modal is open
    if (openModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {
    getNotifications();
    // Connect socket on component mount
    socket.connect();

    // Listen for messages
    socket.on(`${userId}-notification`, (data) => {
      getNotifications();
      console.log("Notification received", data);
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const getNotifications = async () => {
    const data = await apiService.handleProtectedRoute(
      "fetchNewNotifications",
      {}
    );
    setNotificationCount(data.notificationCount);
  };

  useEffect(() => {}, [notificationCount]);

  return (
    <div className="">
      <div className="flex gap-2">
        <button
          onClick={() => setOpenModal(true)}
          className="notification-trigger relative cursor-pointer flex p-2 w-9 h-9 items-center justify-center text-[#878188] hover:text-white hover:bg-white/5 rounded-full"
        >
          <Bell className="w-4 h-4  object-contain" />
          {notificationCount > 0 && (
            <div
              className="absolute -top-0.5 -right-0.5 w-4 h-4 bg-gradient-to-r from-[#FC10F2] to-[#3078E4] rounded-full flex items-center justify-center text-[10px] text-white font-bold"
              style={{
                background: "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
              }}
            >
              {notificationCount}
            </div>
          )}
        </button>
      </div>

      {/* Notifications Modal */}
      {openModal && (
        <div
          className="absolute inset-0 bg-opacity-10 flex justify-center items-center z-50"
          onClick={() => {
            setNotificationCount(0);
            setOpenModal(false);
          }} // Close modal when clicking outside
        >
          <div
            className="notification-container relative left-[52px] -bottom-5 -translate-y-1/2 text-white rounded-lg shadow-lg w-full max-w-md"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <Notifications onClose={() => setOpenModal(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default WubbleNotification;
