const CreateFirstTrack = () => {
  return (
    <div className="main-container flex flex-col w-full gap-5 items-start flex-nowrap relative mx-auto my-0">
      <div className="flex flex-col gap-2 items-center w-full flex-nowrap relative">
        <div className="flex flex-col gap-2 items-start w-full flex-nowrap relative">
          <span className="text-3xl font-medium text-white relative text-left">
            Create your first track
          </span>
          <span className="text-base sm:text-lg font-light text-[#c5c2c6] relative text-left">
            Here are some suggested prompts, select one to see how Wubble works
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center w-full rounded-lg relative">
        <div className="flex flex-col sm:flex-row gap-2 justify-center items-start w-full relative">
          <div className="flex h-10 gap-2 w-full items-center flex-grow flex-nowrap rounded-lg border border-[rgba(255,255,255,0.1)] hover:[background:var(--Color-Opacity-Primary-10,rgba(255,255,255,0.05))] cursor-pointer duration-200 px-0.5 relative">
            <div className="flex justify-center items-center bg-[#3c2343] rounded-md relative">
              <div className="w-8 h-8 bg-[url(../aj.svg)] bg-cover bg-no-repeat relative" />
            </div>
            <span className="text-sm sm:text-base font-light text-white relative text-left">
              A Light And Cheerly EDM Track
            </span>
          </div>
          <div className="flex h-10 gap-2 items-center w-full flex-grow flex-nowrap rounded-lg border border-[rgba(255,255,255,0.1)] hover:[background:var(--Color-Opacity-Primary-10,rgba(255,255,255,0.05))] cursor-pointer duration-200 px-0.5 relative">
            <div className="flex justify-center items-center bg-[#3c2343] rounded-md relative mx-0.5">
              <div className="w-8 h-8 bg-[url(../picture.svg)] bg-cover bg-no-repeat relative" />
            </div>
            <span className="text-sm sm:text-base font-light text-white relative text-left">
              Music for a Coffee Commercial
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 justify-center items-start w-full relative">
          <div className="flex h-10 gap-2 w-full items-center flex-grow flex-nowrap rounded-lg border border-[rgba(255,255,255,0.1)] hover:[background:var(--Color-Opacity-Primary-10,rgba(255,255,255,0.05))] cursor-pointer duration-200 px-0.5 relative">
            <div className="flex justify-center items-center bg-[#3c2343] rounded-md relative">
              <div className="w-8 h-8 bg-[url(../music.svg)] bg-cover bg-no-repeat relative" />
            </div>
            <span className="text-sm sm:text-base font-light text-white relative text-left">
              Add a Solo over Instrumental
            </span>
          </div>
          <div className="flex h-10 gap-2 w-full items-center flex-grow flex-nowrap rounded-lg border border-[rgba(255,255,255,0.1)] hover:[background:var(--Color-Opacity-Primary-10,rgba(255,255,255,0.05))] cursor-pointer duration-200 px-0.5 relative">
            <div className="flex justify-center items-center bg-[#3c2343] rounded-md relative mx-0.5">
              <div className="w-8 h-8 bg-[url(../film.svg)] bg-cover bg-no-repeat relative" />
            </div>
            <span className="text-sm sm:text-base font-light text-white relative text-left">
              Music for a Gameplay on Minecraft
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFirstTrack;
