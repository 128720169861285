import { get } from "lodash";
import { useEffect } from "react";

const CircularProgress = ({ value, maxValue }) => {
    const radius = 8; // Radius of the circle
    const circumference = 2 * Math.PI * radius;

    // Calculate progress percentage and cap it at 100%
    const progress = Math.min((value / maxValue) * 100, 100);
    // Compute stroke offset based on capped progress
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    
    return (
        <div className="w-6 h-6">
            <svg className="w-8 h-8 transform -rotate-90">
                <circle
                    className="text-gray-700"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="21"
                    cy="13"
                />
                <circle
                    className={getChartColor(progress)}
                    strokeWidth="2"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="21"
                    cy="13"
                />
            </svg>
        </div>
    );
};
const getChartColor = (minutes) => {
    if (minutes > 0 && minutes < 30) return "text-rose-400" // red for both parts of the chart
    if (minutes > 30 && minutes < 60) return "text-orange-400" // amber
    else return "text-green-400" // green
}

export default CircularProgress;