import React from "react";
import {
  ThumbsUp,
  ThumbsDown,
  ThumbsUpFill,
  ThumbsDownFill,
  Xmark,
} from "@gravity-ui/icons";

const FeedbackPrompt = ({ onLike, onDislike, onDismiss, likeStatus }) => {
  return (
    <div className="flex w-full max-w-md items-center gap-3 rounded-lg border border-white/10 pl-3 pr-1 h-11 mb-0">
      <p className="flex-1 text-sm font-extralight text-white">
        Is this the vibe you were looking for?
      </p>
      <div className="flex items-center gap-1">
        <button
          onClick={onLike}
          className="h-8 w-8 rounded-lg hover:bg-white/10 hover:text-white text-[#878188] flex items-center justify-center"
        >
          {likeStatus === "like" ? (
            <ThumbsUpFill className="h-4 w-4" />
          ) : (
            <ThumbsUp className="h-4 w-4" />
          )}
        </button>
        <button
          onClick={onDislike}
          className="h-8 w-8 rounded-lg hover:bg-white/10 hover:text-white text-[#878188] flex items-center justify-center"
        >
          {likeStatus === "dislike" ? (
            <ThumbsDownFill className="h-4 w-4" />
          ) : (
            <ThumbsDown className="h-4 w-4" />
          )}
        </button>
        <div className="h-11 w-px ml-1 bg-[#2C2A2C]" />
        <button
          onClick={onDismiss}
          className="h-8 w-8 rounded-lg hover:bg-white/10 hover:text-white text-[#878188] flex items-center justify-center"
        >
          <Xmark className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default FeedbackPrompt;
