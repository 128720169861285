import React from "react";
import BaseCard from "./BaseCard";
import correct from "../.././../assets/images/correctmark.svg";

const BasicCard = ({ isAnnual }) => (
  <BaseCard
    tier="Basic"
    monthlyPrice="$10"
    annualPrice="$110"
    period="Monthly"
    showSavings={true}
    features={[
      isAnnual ? "360 minutes of royalty-free music" : "30 minutes of royalty-free music",
      "Unlimited downloads",
      "Access to text, image, video & audio to music capabilities",
      "For social media usage",
      "Music editing & extension",
      "Song templates",
    ]}
    additionalInfo={
      isAnnual
        ? "$110 for each additional member"
        : "$10 for each additional member"}
    isAnnual={isAnnual}
    cardStyle={{
      background: "#1D1B1D",
      border: "1px solid var(--Color-Light-Opacity-White-10, #FFFFFF1A)",
    }}
    path={correct}
  />
);

export default BasicCard;
