import React, { useEffect } from "react";
import { useLocalState } from "../../contexts/LocalStateProvider";
import apiService from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { BsNutFill } from "react-icons/bs";
import { usePostHog } from "posthog-js/react";

const TeamNameAndAvatar = ({ teamAvatar }) => {
  const posthog = usePostHog();
  const { localState, updateLocalState } = useLocalState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamData = async () => {
      if (localState.teamNameAndAvatar?.team) return;

      try {
        const response = await apiService.handleProtectedRoute(
          "fetchTeamNameAndAvatar",
          {}
        );

        const teamData = response.team;
        let teamavatarBase64 = "Organization.svg";

        if (!response.success) return;

        if (teamData?.teamavatar?.data) {
          teamavatarBase64 = `data:image/jpeg;base64,${arrayBufferToBase64(
            teamData.teamavatar.data
          )}`;
        }

        const processedTeamData = {
          ...teamData,
          teamavatarBase64,
        };

        updateLocalState("teamNameAndAvatar", { team: processedTeamData });
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    if (!localState.teamNameAndAvatar) {
      fetchTeamData();
    }
  }, []);

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  if (!localState.teamNameAndAvatar && !localState.isTeamMember) {
    return null;
  }

  if (!localState.teamNameAndAvatar && localState.isTeamMember) {
    if (teamAvatar) {
      return (
        <div className="flex md:mt-3">
          <div className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-[#2C2A2C] animate-pulse" />
        </div>
      );
    }

    return (
      <div className="px-3 py-0.5 w-full relative flex items-center justify-between">
        <div className="flex py-1 w-full items-center self-stretch gap-2">
          <div className="w-[2.1rem] h-[2.1rem] rounded-full bg-[#2C2A2C] animate-pulse" />
          <div className="h-4 w-24 bg-[#2C2A2C] rounded animate-pulse" />
        </div>
      </div>
    );
  }

  const team = localState?.teamNameAndAvatar?.team;
  const avatarSrc = team?.teamavatarBase64 || "Organization.svg";
  const teamName = team?.teamname || "Organization";
  const isAvatarEmpty = avatarSrc === "Organization.svg";

  if (teamAvatar) {
    return (
      <button
        onClick={() => {
          updateLocalState("organizationClicked", true);
          navigate("/settings");
          posthog.capture(
            "Click Organization Settings Navigation (Team Name / Avatar button)"
          );
        }}
        className="flex md:mt-3"
      >
        <img
          src={avatarSrc}
          alt={teamName}
          className="w-10 h-10 md:w-8 md:h-8 md:mb-2"
          style={{
            borderRadius: "100%",
            objectFit: "cover",
            background: "#3C393C",
            padding: isAvatarEmpty ? "0.5rem" : "0",
          }}
        />
      </button>
    );
  }

  return (
    <div className="w-full relative items-center inline-block">
      <div className="flex py-1 w-full items-center self-stretch rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] md:bg-transparent [background:var(--Color-Light-Opacity-White-5,rgba(255,255,255,0.05))] transition duration-300">
        <button
          onClick={() => {
            updateLocalState("organizationClicked", true);
            navigate("/settings");
          }}
          className="flex items-center px-1 text-left w-full self-stretch text-sm text-white gap-2"
          id="team-name-and-avatar-button"
        >
          <img
            src={avatarSrc}
            alt={teamName}
            style={{
              borderRadius: "100%",
              width: "2.1rem",
              height: "2.1rem",
              objectFit: "cover",
              background: "#3C393C",
              padding: isAvatarEmpty ? "0.5rem" : "0",
            }}
          />
          <span className="truncate max-w-80">{teamName}</span>
        </button>
      </div>
    </div>
  );
};

export default TeamNameAndAvatar;
