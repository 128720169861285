import React, { useState } from "react";
import lamejs from "@breezystack/lamejs";
import apiService from "../../api/apiService";
import { Clock, Xmark } from "@gravity-ui/icons";
import Toast from "../generic_components/Toast";
import { usePostHog } from "posthog-js/react";

const AudioProcessor = ({ onClose, currentResponse }) => {
  const [fileType, setFileType] = useState("wav");
  const [isProcessing, setIsProcessing] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const posthog = usePostHog();

  const fetchAndProcessAudio = async () => {
    posthog.capture("Click to Download Audio");
    setIsProcessing(true);
    try {
      // Fetch audio data from backend
      const response = await apiService.sendRequest(
        "fetchCompleteAIResponseAudio",
        {
          ai_response_id: currentResponse.ai_response_id,
        }
      );

      if (!response.success) {
        posthog.capture("Failed to Download audio file");
        response?.message
          ? setToastMessage(response?.message)
          : setToastMessage("Failed to Download audio file");
        setIsProcessing(false);
        return;
      }

      const arrayBuffer = new Uint8Array(response.audioFile.data).buffer;

      // Decode audio data using Web Audio API
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Assume mono audio
      const channelData = audioBuffer.getChannelData(0);

      // Convert Float32Array [-1,1] to Int16Array [-32768,32767]
      const samples = floatTo16BitPCM(channelData);

      // Export the audio
      const blob =
        fileType === "wav"
          ? new Blob([new Uint8Array(response.audioFile.data)], {
              type: "audio/wav",
            })
          : await encodeMP3(samples, audioBuffer.sampleRate);

      // Trigger download
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${currentResponse.audio.name}.${fileType}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);

      posthog.capture("Audio file Download successful");
    } catch (error) {
      posthog.capture("Failed to Download audio file");
      console.error("Processing failed:", error);
    }
    setIsProcessing(false);
  };

  // Encode to MP3 using lamejs
  const encodeMP3 = async (samples, sampleRate) => {
    const mp3encoder = new lamejs.Mp3Encoder(1, sampleRate, 128); // Mono channel
    const sampleBlockSize = 1152;
    const mp3Data = [];

    let sampleIndex = 0;
    while (sampleIndex < samples.length) {
      const sampleChunk = samples.subarray(
        sampleIndex,
        sampleIndex + sampleBlockSize
      );
      const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
      sampleIndex += sampleBlockSize;
    }

    const mp3buf = mp3encoder.flush();
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }

    return new Blob(mp3Data, { type: "audio/mp3" });
  };

  // Helper function to convert Float32Array to Int16Array
  const floatTo16BitPCM = (input) => {
    const output = new Int16Array(input.length);
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return output;
  };

  return (
    <div className="fixed inset-0 flex items-center z-20 justify-center bg-[rgba(0,0,0,0.7)]">
      <div className="w-full max-w-[520px] flex flex-col bg-[#1c1b1c] rounded-lg border border-[#4d4a4e] relative">
        <div className="flex items-center justify-between p-4 border-b border-[#2c2a2c]">
          <h2 className="text-xl font-medium text-white">Download Track</h2>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-[rgba(255,255,255,0.05)]"
          >
            <Xmark className="w-4 h-4 text-[#878188]" />
          </button>
        </div>

        <div className="p-4 flex flex-col gap-4">
          {/* Track Preview */}
          <div className="flex gap-3 p-2 items-center rounded-xl border border-[rgba(255,255,255,0.2)]">
            <img
              src={currentResponse.avatar}
              alt={currentResponse.audio.name}
              className="w-20 h-20 object-cover rounded"
            />
            <div className="flex flex-col flex-1 min-w-0">
              <h3 className="text-lg font-medium text-white truncate">
                {currentResponse.audio.name}
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-sm text-[#a5a0a6] truncate">
                  {currentResponse.musicDescription}
                </span>
                <div className="flex items-center gap-1 text-[#a5a0a6]">
                  <Clock className="w-3.5 h-3.5" />
                  <span className="text-sm">
                    {currentResponse.audio.duration}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex flex-col gap-4">
          {/* File Type */}
          <div className="flex items-center gap-8">
            <span className="w-22 text-sm text-white">File Type</span>
            <div className="bg-[rgba(255,255,255,0.1)] rounded-lg p-0.5">
              <button
                onClick={() => setFileType("wav")}
                className={`px-3 py-1.5 text-xs rounded-md transition-colors ${
                  fileType === "wav"
                    ? "bg-[rgba(255,255,255,0.1)] text-white"
                    : "text-[#878188]"
                }`}
              >
                WAV
              </button>
              <button
                onClick={() => setFileType("mp3")}
                className={`px-3 py-1.5 text-xs rounded-md transition-colors ${
                  fileType === "mp3"
                    ? "bg-[rgba(255,255,255,0.1)] text-white"
                    : "text-[#878188]"
                }`}
              >
                MP3
              </button>
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end mt-2">
            <button
              onClick={fetchAndProcessAudio}
              disabled={isProcessing}
              className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg hover:bg-gray-100 transition-colors"
            >
              <span className="text-sm font-medium text-[#0f0e0f]">
                {isProcessing ? "Processing..." : "Download"}
              </span>
            </button>
          </div>
        </div>
      </div>
      {toastMessage && (
        <Toast
          type="error"
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default AudioProcessor;
