import React, { useEffect, useState } from "react";
import { CreditCard, Persons } from "@gravity-ui/icons";
import { Building } from "lucide-react";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import timeAgo from "../../utils/getFormattedDate";
import notificationImg from "../../assets/images/notification.png";
const ResponsiveNotification = () => {
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, []);
  const getNotifications = async () => {
    const data = await apiService.handleProtectedRoute(
      "fetchNotifications",
      {}
    );
    setNotifications(data.notifications);
  };

  const markAllAsRead = async () => {
    setNotifications(
      notifications.map((notif) => ({ ...notif, is_read: true }))
    );
    await apiService.handleProtectedRoute("markAsReadNotifications", {});
    getNotifications();
  };

  return (
    <div className="sm:w-[380px] h-[60vh] mt-8 w-full backdrop-brightness-200 bg-[#FFFFFF] backdrop-blur-[50px] bg-opacity-10 text-white rounded-lg  border border-gray-700 mx-auto overflow-hidden">
      {/* Header */}
      <div className="flex gap-2 p-4 md:p-3 md:pl-4 border-b border-white/10 justify-between items-center">
        <h2 className="text-lg md:text-lg font-normal">
          Notifications ({notifications.filter((n) => !n?.is_read).length} new)
        </h2>
        <button
          onClick={markAllAsRead}
          className="text-sm text-gray-400 hover:text-white hover:bg-white/5 px-2 h-8 rounded-md"
        >
          Mark all as read
        </button>
      </div>

      {/* Notification Content */}
      <div
        className={`flex-col w-full ${
          notifications.length > 0 ? "overflow-y-auto h-full" : ""
        }`}
      >
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <div
              key={notification?.id}
              className="flex sm:flex-row gap-2 p-3 md:p-3 border-b border-white/10 min-h-[66px] w-full hover:bg-white/5"
            >
              {/* Notification Icon */}
              <div className="w-8 h-8 flex items-center justify-center md:w-10 md:h-10 shrink-0 bg-white/10 rounded-full">
                <Building className="w-4 h-4 text-white" />
              </div>

              {/* Notification Content */}
              <div className="flex-1 flex flex-col sm:flex-row gap-4 relative">
                {/* Notification Text */}
                <div className="flex-1 w-full break-words overflow-hidden">
                  <p className="text-xs md:text-sm font-extralight text-white leading-tight">
                    {notification?.message}
                  </p>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="w-[18px] h-[18px] flex items-center justify-center bg-gradient-to-br from-[#6ef7ec] to-[#7c0ca2] rounded-full">
                      <CreditCard className="w-2.5 h-2.5 text-white" />
                    </div>
                    <span className="text-[10px] md:text-xs text-gray-400">
                      {timeAgo(notification?.created_at)}
                    </span>
                  </div>
                </div>

                {/* Actions */}
                {/* {notification?.credit_request_id && (
                  <div className="flex gap-3 items-center justify-start">
                    <button
                      className="px-2 h-8 rounded-md text-sm bg-opacity-10 text-gray-400"
                      style={{
                        background: "#878188",
                        color: "white",
                      }}
                    >
                      Deny
                    </button>

                    <button className="px-2 h-8 rounded-md text-sm text-gray-700 bg-white">
                      Accept
                    </button>
                  </div>
                )} */}

                {/* Read Indicator */}
                {!notification?.is_read && !notification?.credit_request_id && (
                  <div className="w-2 h-2 bg-purple-500 rounded-full absolute right-0"></div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full mt-[40%]">
            <img
              src={notificationImg}
              alt="Notification Icon"
              className="w-[39px] h-[42px] object-contain "
            />
            <p className="text-xl font-extralight text-center py-4 text-gray-400">
              No notifications yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponsiveNotification;
