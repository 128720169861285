import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  setSubscriptionModal,
  setSubscriptionTypeModal,
  setRedirectAfterSelectedPlan,
} from "../../store/reducers/subscriptionSlice";
import { setUserData } from "../../store/reducers/userSlice";
import Modal from "../modal/Modal";
import CircularProgress from "./CircularProgress";
import Subscription from "./Subscription/Subscription";

const CreditsCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localState, updateLocalState } = useLocalState();
  const { openSubscriptionModal } = useSelector((state) => state.subscription);
  const [openPlanModal, setPlanModal] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchUserData = async () => {
      try {
        const response = await apiService.handleProtectedRoute(
          localState.isTeamMember ? "fetchTeamUserProfile" : "fetchUserProfile",
          {}
        );

        if (isMounted) {
          // Update state only if the component is still mounted
          updateLocalState("userProfileData", response);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, [localState.isTeamMember]); // Empty dependency array ensures this runs only once

  const handleCreditRequest = async (data) => {
    try {
      const response = await apiService.handleProtectedRoute(
        "createCreditRequest",
        {}
      );

      dispatch(setUserData(response));
    } catch (error) {
      console.error("Error sending credit request:", error);
    }
  };

  // Inside CreditsCard component, add this new useEffect
  useEffect(() => {
    const remainingCredits = localState.userProfileData?.remaining_credits;

    if (remainingCredits <= 5) {
      updateLocalState("lowCreditsWarning", {
        type: "warning",
        message: `Only ${remainingCredits} minutes left! Upgrade now to keep jamming uninterrupted! 🎵✨`,
        action: {
          label: "Upgrade",
          onClick: () => {
            updateLocalState("lowCreditsWarning", null);

            if (localState.isAdmin && localState.isTeamMember) {
              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
              dispatch(setSubscriptionTypeModal("upgrade"));
            } else if (!localState.isTeamMember) {
              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
            }
          },
        },
      });
    }
    if (remainingCredits == 0) {
      updateLocalState("lowCreditsWarning", {
        type: "error",
        message: `Oops! You’ve hit zero minutes. Upgrade to keep the tunes alive! 🎹⏳`,
        action: {
          label: "Upgrade",
          onClick: () => {
            if (localState.isAdmin && localState.isTeamMember) {
              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
              dispatch(setSubscriptionTypeModal("upgrade"));
            } else if (!localState.isTeamMember) {
              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
            }
          },
        },
      });
    } else if (remainingCredits > 5) {
      updateLocalState("lowCreditsWarning", null);
    }
  }, [localState.userProfileData?.remaining_credits]);

  return (
    <div className="w-72">
      <div className={"w-full"}>
        <div className="flex w-full flex-col gap-2 rounded-lg bg-[#2C2A2C] p-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="relative h-5 w-5 items-center justify-center">
                <CircularProgress
                  value={localState.userProfileData?.remaining_credits ?? 0}
                  maxValue={localState.userProfileData?.total_credits ?? 0}
                />
              </div>
              <span className="text-sm font-extralight text-white">
                Minutes
              </span>
            </div>
            {((localState.isAdmin && localState.isTeamMember) ||
              !localState.isTeamMember) && (
              <button
                variant="outline"
                size="sm"
                className="h-7 bg-transparent px-2 text-xs font-normal rounded-md border border-[rgba(255,255,255,0.1)] text-[#878188] hover:bg-white/10 hover:text-white"
                onClick={() => {
                  if (localState.isAdmin && localState.isTeamMember) {
                    dispatch(setSubscriptionModal(true));
                    dispatch(setSubscriptionTypeModal("upgrade"));
                  } else if (!localState.isAdmin && localState.isTeamMember) {
                    handleCreditRequest();
                  } else {
                    dispatch(setRedirectAfterSelectedPlan("/create-team"));
                    dispatch(setSubscriptionModal(true));
                  }
                }}
              >
                {/* {localState.isAdmin && localState.isTeamMember
                ? "Upgrade"
                : !localState.isAdmin && localState.isTeamMember
                ? "Request"
                : "Upgrade"} */}
                Upgrade
              </button>
            )}
          </div>
          <div className="flex items-center justify-between text-xs">
            <span className="font-normal text-[#C5C3C6]">Remaining</span>
            <div className="flex items-center gap-1 text-white">
              <span className="font-normal">
                {localState.userProfileData?.remaining_credits ?? 0}
              </span>
              <span className="font-normal">/</span>
              <span className="font-normal">
                {localState.userProfileData?.total_credits ?? 0} mins
              </span>
            </div>
          </div>
        </div>
      </div>
      {openPlanModal && (
        <Modal closeModal={() => setPlanModal(false)}>
          <Subscription
            openPlanModal={openPlanModal}
            setOpenPlanModal={setPlanModal}
          />
        </Modal>
      )}
    </div>
    // <div className="flex flex-col rounded-lg  p-4 w-80 bg-[#2C2A2C]">
    //   {/* Top Section */}
    //   <div className="flex justify-between gap-8 items-center">
    //     {/* Credits Section with Icon */}
    //     <div className="flex gap-2 items-center">
    //       <CircularProgress
    //         value={localState.userProfileData?.remaining_credits ?? 0}
    //         maxChars={localState.userProfileData?.total_credits ?? 0}
    //         type="sidebar"
    //       />
    //       <h3 className="text-sm font-semibold text-white">Credits</h3>
    //     </div>

    //     {/* Request Button */}
    //     <button
    //       className="text-white px-3 py-1 text-sm
    //        rounded-md border border-[rgba(255,255,255,0.1)]"
    //       onClick={() => navigate("/subscription")}
    //     >
    //       {localState.isAdmin && localState.isTeamMember ? "Upgrade" : localState.isTeamMember ? "Request" : "Upgrade"}
    //     </button>
    //   </div>

    //   {/* Bottom Section */}
    //   <div className="flex justify-between items-center mt-4">
    //     <span className="text-gray-400 text-sm">Remaining</span>
    //     <span className="text-white font-semibold text-sm">
    //       {userData?.remaining_credits ?? 0} / {userData?.total_credits ?? 0} mins
    //     </span>
    //   </div>
    // </div>
  );
};

export default CreditsCard;
