import React, { useState, useRef, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  MusicNote,
  Picture,
  Filmstrip,
  File,
  ArrowShapeUpFromLine,
} from "@gravity-ui/icons";

const iconComponents = {
  MusicNote,
  Picture,
  Filmstrip,
  File,
};

const Dropdown = ({
  buttonLabel,
  options,
  onOptionSelect,
  className,
  customContent,
  customInput,
  inputDisplay,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (onOptionSelect) {
      onOptionSelect(option);
    }
    toggleDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {customInput ? (
        <div
          id="dropdown-custom-input-container"
          className={`rounded-full ${
            inputDisplay ? `w-8` : `pr-2.5`
          } flex flex-row h-7 transition duration-300 py-0 px-2 box-border gap-1 z-[0] hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] hover:text-white`}
        >
          <button
            id="dropdown-custom-input-button"
            onClick={toggleDropdown}
            className="flex flex-row items-center gap-1 bg-transparent text-[#878188] font-normal rounded-l-md"
          >
            <ArrowShapeUpFromLine className="w-4 relative h-4" />
            {buttonLabel}
          </button>
        </div>
      ) : (
        <div className="rounded-full flex flex-row py-0 box-border gap-1 z-[0]">
          <button
            id="dropdown-button"
            onClick={toggleDropdown}
            className={`flex flex-row items-center px-3 h-9 gap-2 bg-transparent text-[#878188] ${buttonLabel.includes("Remaining") ? '' : 'hover:bg-zinc-900'}  hover:text-white hover:bg-white/5 font-normal rounded-full`}
          >
            {buttonLabel}
            {!isOpen ? (
              <ChevronDown className="w-4 relative h-4" />
            ) : (
              <ChevronUp className="" />
            )}
          </button>
        </div>
      )}

      {isOpen && (
        <div
          id="dropdown-options-container"
          className={`absolute ${
            inputDisplay ? `top-1` : `top-9`
          } text-left font-medium text-sm text-[#878188] bg-[#100f10] rounded-lg p-1 border border-solid border-[#2c2a2c] z-10 ${className}`}
        >
          {customContent ||
            options.map((option) => {
              const IconComponent = iconComponents[option.icon];
              return (
                <div
                  id={option?.id}
                  key={option.label}
                  onClick={() => handleOptionClick(option.label)}
                  className="flex items-center py-4 px-2 cursor-pointer h-7 rounded-md hover:bg-zinc-900 hover:text-white gap-2"
                >
                  {IconComponent && <IconComponent className="w-3 h-3" />}
                  {option.label}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
