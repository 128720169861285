import React from "react";
import { Filmstrip, ArrowRight } from "@gravity-ui/icons";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { useNavigate } from "react-router-dom";

const IntroMusicAnimation = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6 w-full relative z-30">
      <div className="flex flex-col gap-2 w-full">
        <span className="text-3xl font-medium text-white">
          Music you can use commercially
        </span>
        <span className="text-base font-light text-[#c5c2c6]">
          Here’s what makes us your go-to music solution.
        </span>
      </div>
      <div className="flex gap-2 justify-end w-full md:flex-row flex-col">
        <div className="flex md:flex-row gap-2 flex-col md:max-h-full md:overflow-visible scrollable-content max-h-[calc(100vh-400px)] w-full">
          <div className="flex flex-col w-full items-start grow rounded-md border border-[rgba(255,255,255,0.1)] relative z-40">
            <div className="flex flex-col w-full gap-4 p-3 relative bottom-fade">
              <div className="flex flex-col w-full items-end">
                <div className="flex flex-col gap-1 bg-[#1c1b1c] rounded-lg p-2">
                  <div className="flex items-center gap-2 border border-[rgba(255,255,255,0.1)] rounded-lg p-1">
                    <div className="flex items-center justify-center w-10 h-10 bg-[#3b383c] rounded-md">
                      <Filmstrip className="w-4 h-4" />
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <span className="text-sm font-medium text-white">
                        Travelling Vlog
                      </span>
                      <span className="text-xs text-[#c5c2c6]">Video/mp4</span>
                    </div>
                  </div>
                  <span className="text-sm font-light text-white">
                    Create a soundtrack for this video
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex gap-2 items-start w-full">
                  <div className="p-1.5 bg-[#2c2a2c] rounded-full">
                    <img
                      src="/logo.png"
                      className="w-5 h-5 object-cover rounded-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2 bg-[rgba(186,24,236,0.1)] rounded-lg p-2">
                    <span className="text-sm text-white">
                      Here’s your soundtrack for a video on travelling
                    </span>
                    <div className="flex gap-2 items-center border border-[rgba(255,255,255,0.1)] rounded-lg p-1">
                      <div className="bg-cover rounded-full">
                        <img
                          src="/project-images/Album_Art_1.webp"
                          className="w-10 h-9 object-cover rounded-md"
                        />
                      </div>
                      <div className="flex flex-col w-full">
                        <span className="text-sm font-medium text-white">
                          Driving Ambitions
                        </span>
                        <div className="flex flex-row gap-2 justify-between w-full">
                          <span className="text-xs text-[#878188]">
                            Uplifting, Ambient, Happy
                          </span>
                          <span className="text-xs text-[#878188]">0:30</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-3 w-full gap-1 text-left">
              <span className="text-md font-medium text-white">
                Create & edit music with ease
              </span>
              <span className="flex text-sm font-light text-[#c5c2c6]">
                Craft the perfect track for any project in minutes. From
                background scores to dynamic soundtracks, our intuitive
                chat-based interface lets you tweak every element, from genre
                and mood to instruments and tempo, with zero hassle.
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 grow w-full">
            <div className="flex flex-col gap-2 items-start grow rounded-md border border-[rgba(255,255,255,0.1)] relative z-50">
              <div className="flex flex-col p-3 w-full">
                {/* Container for the images */}
                <div class="fade-container relative w-full overflow-hidden">
                  <div class="flex gap-4 items-center animate-scroll-left">
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/tdesign_film.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/nimbus_marketing.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/Vector.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/gg_shopping-bag.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/mingcute_game-2-line.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/jam_dj.svg" />
                    </div>

                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/tdesign_film.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/nimbus_marketing.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/Vector.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/gg_shopping-bag.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/mingcute_game-2-line.svg" />
                    </div>
                    <div class="flex flex-1 items-center justify-center h-auto aspect-square [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] rounded-lg bg-cover">
                      <img src="/jam_dj.svg" />
                    </div>
                  </div>
                </div>
                {/* Text container */}
                <div className="flex flex-col py-3 gap-1">
                  <span className="text-md font-medium text-white">
                    Use it anywhere, anytime, forever.
                  </span>
                  <span className="text-sm font-light text-[#c5c2c6] w-10/12">
                    Use your custom music without worrying about licensing or
                    legal barriers.
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full h-full p-3 rounded-md border border-[rgba(255,255,255,0.1)]">
              <div className="flex relative h-10 items-center gap-1 mb-3 self-stretch [background:linear-gradient(0deg,rgba(0,0,0,0.75)_0%,rgba(0,0,0,0.75)_100%),linear-gradient(135deg,#FC10F2_0%,#3078E4_100%)] px-1.5 py-1 rounded-[48px] overflow-hidden">
                {/* Waveform Image with mask that will reveal as the object moves */}
                <img
                  src="/Audio wav.svg" /* Replace with the path to your waveform image */
                  className="absolute top-0 left-1 h-full w-full object-cover mask-waveform"
                  alt="Waveform"
                />

                {/* Moving image */}
                <div className="flex justify-center items-center h-10 w-10 p-2 [background:var(--Gradient-Two-Tone-Tone-2,linear-gradient(135deg,#FC10F2_0%,#3078E4_100%))] rounded-full absolute top-0 left-0 animate-move-to-fro">
                  <img
                    className="h-5 w-5"
                    src="actiongenerate.svg"
                    alt="Generate"
                  />
                </div>
              </div>

              <span className="text-md font-medium mb-1 text-white">
                Get your custom track in seconds
              </span>
              <span className="text-sm font-light text-[#c5c2c6]">
                We generate fully personalized, high-quality music in just 15
                seconds,
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroMusicAnimation;
