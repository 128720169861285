import React, { useState, useEffect } from "react";
import EditableField from "./EditableField";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { Rocket, CircleExclamation } from "@gravity-ui/icons";

const ProfileSettings = () => {
  const { localState } = useLocalState();
  const [userData, setUserData] = React.useState(localState.userProfileData);

  useEffect(() => {
    if (localState.userProfileData) {
      setUserData(localState.userProfileData);
    }
  }, [localState.userProfileData]);

  return (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] scrollable-content-team-settings overflow-y-auto rounded-lg relative w-full">
        <div
          id="account-settings-container"
          className="flex flex-col items-center justify-top w-full"
        >
          <div className="relative max-w-3xl items-center w-full h-full">
            <div className="px-4 py-4 md:max-h-[calc(100vh-90px)] max-h-[calc(100vh-72px)]">
              <div className="space-y-2 pb-10">
                <h2 className="font-normal text-base text-white ml-2 mb-2">
                  General
                </h2>
                <div className="py-1 px-3 text-base text-white bg-[#1d1b1d] rounded-lg">
                  <div className="relative font-light flex justify-between items-center border-b border-solid border-[#2c2a2c] py-5">
                    <span>Avatar</span>
                    <EditableField
                      initialValue={userData?.user_avatar}
                      name="avatar"
                      inputClassName="hidden"
                      saveButtonClassName="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                      cancelButtonClassName="ml-2 px-4 py-2 bg-gray-600 text-white rounded-md"
                      iconClassName="absolute bottom-5 right-0 ml-2 p-1 rounded-full text-white hover:text-[#878188] transition duration-200 bg-[#2C2A2C] cursor-pointer"
                      icon="PencilToLine"
                      type="file"
                      requestData={{
                        taskName: "updateAvatar",
                        payload: {
                          user_id: userData?.user_id,
                        },
                      }}
                      id="avatar-input"
                    />
                  </div>
                  <div className="flex font-light justify-between items-center border-b border-solid border-[#2c2a2c] py-5">
                    <span>Full name</span>
                    <EditableField
                      initialValue={userData?.full_name}
                      name="fullName"
                      inputClassName="flex-1 w-40 px-3 h-9 font-extralight text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white mr-2"
                      saveButtonClassName="h-8 text-sm font-normal bg-white hover:bg-[#E0DFE1] px-2 text-black rounded-md"
                      cancelButtonClassName="mr-1 text-sm font-normal px-2 h-8 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-md"
                      iconClassName="ml-2 p-0.5 rounded-full text-[#878188] hover:text-white duration-200"
                      icon="PencilToSquare"
                      requestData={{
                        taskName: "updateFullName",
                        payload: {
                          user_id: userData?.user_id,
                        },
                      }}
                      id="fullName-input"
                    />
                  </div>
                  <div className="flex font-light justify-between items-center border-b border-solid border-[#2c2a2c] py-5">
                    <span>Company</span>
                    <EditableField
                      initialValue={userData?.company}
                      name="company"
                      inputClassName="flex-1 w-40 px-3 h-9 font-extralight text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white mr-2"
                      saveButtonClassName="h-8 text-sm font-normal bg-white hover:bg-[#E0DFE1] px-2 text-black rounded-md"
                      cancelButtonClassName="mr-1 text-sm font-normal px-2 h-8 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-md"
                      iconClassName="ml-2 p-0.5 rounded-full text-[#878188] hover:text-white duration-200"
                      icon="PencilToSquare"
                      requestData={{
                        taskName: "updateCompany",
                        payload: {
                          user_id: userData?.user_id,
                        },
                      }}
                      id="company-input"
                    />
                  </div>
                  <div className="flex font-light justify-between items-center py-5">
                    <span>Role</span>
                    <EditableField
                      initialValue={userData?.role}
                      name="role"
                      inputClassName="flex-1 w-40 px-3 h-9 font-extralight text-sm rounded-full border border-solid border-[#878188] bg-transparent text-white mr-2"
                      saveButtonClassName="h-8 text-sm font-normal bg-white hover:bg-[#E0DFE1] px-2 text-black rounded-md"
                      cancelButtonClassName="mr-1 text-sm font-normal px-2 h-8 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] rounded-md"
                      iconClassName="ml-2 p-0.5 rounded-full text-[#878188] hover:text-white duration-200"
                      icon="PencilToSquare"
                      requestData={{
                        taskName: "updateRole",
                        payload: {
                          user_id: userData?.user_id,
                        },
                      }}
                      id="role-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
