export const randomPrompts = [
  "Energetic and bold orchestral track featuring brass stabs, dynamic strings, and powerful percussion, perfect for action-packed promos and trailers.",
  "Upbeat and motivational track with a bright piano melody, rhythmic claps, warm synth pads, and a driving bassline, ideal for inspirational ads.",
  "Groovy funk-inspired track with a funky electric guitar riff, crisp drumbeats, and a vibrant bassline, perfect for lively ad campaigns.",
  "Playful and cheerful track featuring a bouncy marimba melody, rhythmic snaps, ukulele strums, and light percussion, perfect for fun and engaging ads.",
  "Light and atmospheric track with a serene flute melody, soft harp arpeggios, warm strings, and subtle percussion, perfect for tranquil and calming ads.",
  "Lush and emotional orchestral track with a heartfelt violin melody, deep piano chords, and dynamic strings, perfect for sentimental promos.",
  "Festive and vibrant track featuring a rhythmic steel drum melody, cheerful acoustic guitar strumming, and lively percussion, perfect for celebratory ads.",
  "Light and uplifting acoustic track featuring a warm piano melody, soft acoustic guitar, and gentle percussion, ideal for creative DIY videos.",
  "Whimsical and upbeat track with a marimba lead, rhythmic snaps, and bouncy bassline, perfect for engaging arts and crafts content.",
  "Calming and serene track featuring a flowing flute melody, soft harp arpeggios, and subtle string harmonies, ideal for soothing craft sessions.",
  "Gentle and reflective track featuring a soft piano melody, warm synth pads, and delicate percussion, perfect for introspective crafting moments.",
  "Bright and colorful track with a vibrant ukulele melody, rhythmic tambourine, and playful marimba accents, ideal for kid-friendly arts and crafts projects.",
  "Whimsical and magical track featuring a soft music box melody, light chimes, and gentle string textures, perfect for dreamy and creative crafts.",
  "Fun and bouncy track with a catchy whistle melody, playful percussion, and upbeat acoustic guitar strums, ideal for engaging craft tutorials.",
  "Dynamic and professional track with a bold synth melody, rhythmic bassline, and crisp drum patterns, perfect for news broadcasting intros.",
  "Bright and engaging track featuring a warm piano melody, subtle strings, and light percussion, ideal for morning talk shows and podcasts.",
  "Energetic and polished track with a rhythmic electric guitar riff, groovy bass, and modern drum beats, perfect for radio show intros.",
  "Sophisticated and calm track with a flowing violin melody, gentle piano chords, and soft percussion, ideal for in-depth interviews and features.",
  "Uplifting and modern track featuring a catchy synth lead, pulsing basslines, and dynamic drum grooves, perfect for tech-oriented broadcasts.",
  "Smooth and jazzy track with a soulful saxophone melody, light keyboard harmonies, and steady drum patterns, perfect for late-night radio shows.",
  "Confident and rhythmic track featuring a bold trumpet lead, layered orchestral strings, and dynamic timpani hits, perfect for breaking news segments.",
  "Clean and neutral track with a soft marimba melody, shimmering pads, and gentle beats, ideal for weather updates and calm broadcasting themes.",
  "Upbeat and lively track with a bright flute melody, rhythmic claps, and bouncy basslines, perfect for entertainment news and feature stories.",
  "Epic and powerful orchestral track featuring bold brass stabs, soaring strings, and thunderous percussion, perfect for action-packed cinematic scenes.",
  "Suspenseful and intense track featuring a haunting violin melody, deep bass pulses, and dynamic drum patterns, perfect for thriller and mystery settings.",
  "Uplifting and triumphant track with a majestic horn melody, cascading strings, and powerful timpani rolls, ideal for heroic and adventurous themes.",
  "Whimsical and magical track featuring a playful harp melody, light glockenspiel tones, and lush orchestral layers, ideal for fantasy and fairy-tale settings.",
  "Energetic and futuristic track with a driving synth melody, pulsing basslines, and dynamic drum rhythms, perfect for sci-fi and tech-inspired cinematic moments.",
  "Elegant and timeless classical piece with a flowing piano melody, accompanied by soft string harmonies and subtle woodwinds, ideal for serene and reflective moments.",
  "Rich and vibrant orchestral track featuring soaring violins, warm cellos, and bold brass stabs, perfect for majestic and grand settings.",
  "Gentle and soothing track with a delicate harp melody, accompanied by soft flutes and shimmering strings, creating a serene and peaceful atmosphere.",
  "Dynamic and energetic classical composition with sharp staccato strings, rhythmic timpani, and triumphant horns, ideal for lively and dramatic themes.",
  "Lush and romantic piece featuring a heartfelt violin solo, supported by warm piano chords and subtle orchestral textures, perfect for love and emotional storytelling.",
  "Bright and uplifting track with a playful piano melody, rhythmic pizzicato strings, and cheerful woodwind flourishes, ideal for light and whimsical moments.",
  "Majestic and powerful orchestral track with dramatic brass swells, cascading strings, and rolling timpani, perfect for epic and heroic themes.",
  "Upbeat and cheerful track featuring a playful ukulele melody, rhythmic claps, and soft marimba tones, perfect for lighthearted cooking tutorials.",
  "Bright and lively acoustic track with a warm piano melody, soft percussion, and gentle acoustic guitar strumming, ideal for family cooking moments.",
  "Whimsical and fun track with a bouncy marimba melody, rhythmic snaps, and cheerful glockenspiel accents, ideal for playful and creative cooking videos.",
  "Smooth and jazzy track featuring a mellow clarinet melody, soft keyboard harmonies, and gentle drum rhythms, ideal for elegant and stylish food demonstrations.",
  "Light and uplifting track with a catchy whistle melody, playful ukulele strums, and soft hand percussion, creating a friendly and inviting tone for cooking videos.",
  "Whimsical and fun track featuring a cheerful marimba melody, soft pizzicato strings, and gentle percussion, ideal for slapstick and visual gags.",
  "Dynamic and lively track with a mischievous clarinet lead, bouncy piano chords, and soft cymbal crashes, creating a classic comedic atmosphere.",
  "Bright and silly track featuring a lively banjo riff, plucky strings, and subtle drum rhythms, perfect for quirky and awkward comedy scenarios.",
  "Uplifting and motivational track with a bright piano melody, rhythmic claps, and warm synth pads, perfect for corporate presentations and business promotions.",
  "Dynamic and modern track featuring a driving bassline, crisp electronic beats, and shimmering synth textures, ideal for tech and innovation-themed corporate content.",
  "Bright and inspiring track with a gentle acoustic guitar riff, soft piano chords, and subtle percussion, perfect for professional yet approachable corporate videos.",
  "Elegant and sophisticated track featuring a flowing piano melody, warm orchestral strings, and light cymbal accents, ideal for high-end corporate branding.",
  "Energetic and forward-thinking track with a pulsing synth lead, steady basslines, and vibrant drum patterns, perfect for showcasing innovation and growth.",
  "Confident and uplifting track featuring a bold electric guitar riff, dynamic bass grooves, and modern drum rhythms, ideal for impactful corporate campaigns.",
  "Optimistic and contemporary track featuring a catchy whistle melody, rhythmic acoustic guitar strums, and warm synth layers, perfect for team-building and morale-boosting content.",
  "Minimalist and modern track with a clean piano lead, subtle electronic textures, and light drum patterns, ideal for corporate overviews and technology showcases.",
  "Ethereal and atmospheric track with a soft piano melody, subtle string harmonies, and ambient synth pads, perfect for nature documentaries.",
  "Reflective and emotional track featuring a heartfelt violin melody, gentle piano chords, and warm orchestral textures, ideal for historical storytelling.",
  "Soothing and contemplative track with a flowing flute melody, delicate harp arpeggios, and soft choral pads, ideal for exploring serene landscapes.",
  "Dramatic and powerful track with bold orchestral strings, deep brass accents, and rolling timpani, ideal for epic storytelling or war documentaries.",
  "Minimalist and thoughtful track with a gentle piano motif, soft ambient textures, and subtle percussion, perfect for introspective or artistic documentaries.",
  "Emotional and reflective track with a heartfelt piano melody, lush string harmonies, and subtle ambient textures, perfect for intimate and personal drama scenes.",
  "Melancholic and soulful track with a gentle cello solo, warm orchestral strings, and soft piano chords, perfect for sorrowful and reflective drama.",
  "Uplifting and triumphant track with a soaring horn melody, cascading strings, and dynamic drum patterns, ideal for moments of redemption or victory in drama.",
  "Hopeful and inspiring track with a bright piano theme, subtle choral pads, and soft percussion, perfect for moments of resolution and growth in drama.",
  "Bold and cinematic track featuring powerful orchestral brass, driving timpani, and dramatic string swells, perfect for high-stakes and climactic drama moments.",
  "Chic and stylish track with a groovy bassline, smooth electric piano chords, and crisp hi-hats, perfect for high-fashion runway shows.",
  "Elegant and modern track featuring a flowing piano melody, shimmering synth textures, and soft percussion, ideal for luxury beauty product ads.",
  "Sophisticated and upbeat track with a rhythmic guitar riff, dynamic drum patterns, and warm basslines, perfect for trendy fashion promotions.",
  "Sleek and contemporary track featuring a pulsing synth lead, vibrant bass grooves, and subtle electronic beats, ideal for urban and streetwear fashion content.",
  "Lush and atmospheric track featuring a serene flute melody, soft ambient pads, and light percussion, ideal for elegant skincare and wellness themes.",
  "Playful and vibrant track with a bouncy marimba melody, rhythmic claps, and cheerful ukulele strumming, perfect for youthful and colorful beauty campaigns.",
  "Energetic and intense track with driving synth leads, pulsing basslines, and dynamic drum beats, perfect for high-stakes action games.",
  "Mysterious and atmospheric track with deep bass pulses, eerie synth pads, and subtle rhythmic textures, perfect for stealth or puzzle games.",
  "Upbeat and playful track featuring a catchy chiptune melody, bouncy basslines, and retro-inspired drum patterns, ideal for casual and arcade-style games.",
  "Futuristic and high-energy track featuring sharp synth arpeggios, fast-paced drum rhythms, and layered electronic textures, ideal for sci-fi games.",
  "Uplifting and cozy track with a gentle acoustic guitar melody, soft chimes, and subtle orchestral strings, ideal for family-focused holiday content.",
  "Playful and joyful track with a bouncy marimba melody, rhythmic tambourine, and cheerful glockenspiel tones, ideal for lighthearted seasonal content.",
  "Festive and lively track with a catchy accordion melody, rhythmic claps, and playful pizzicato strings, perfect for holiday markets and festive events.",
  "Bright and energetic track featuring a rhythmic electric guitar riff, dynamic drum patterns, and warm synth layers, ideal for modern holiday campaigns.",
  "Romantic and heartwarming track with a delicate violin melody, soft piano chords, and lush orchestral harmonies, perfect for holiday romance themes.",
  "Dark and suspenseful track featuring haunting string melodies, deep bass drones, and subtle percussive rhythms, perfect for building tension in horror scenes.",
  "Tense and brooding track featuring sharp staccato strings, low brass accents, and deep drum impacts, perfect for high-stakes chases and intense thriller moments.",
  "Panic-inducing and fast-paced track with rapid staccato strings, driving percussion, and harsh brass stabs, perfect for action-packed thriller moments.",
  "Dynamic and hopeful track featuring a gentle acoustic guitar riff, warm synth pads, and steady percussion, ideal for personal growth and self-improvement themes.",
  "Energetic and triumphant track with a bold brass melody, cascading strings, and driving drum patterns, perfect for moments of victory and perseverance.",
  "Soft and emotional track featuring a heartfelt piano motif, subtle choral pads, and warm orchestral textures, ideal for inspiring life-changing narratives.",
  "Gentle and reflective track featuring a flowing piano melody, soft acoustic guitar, and light percussion, creating a calming yet inspiring atmosphere.",
  "Optimistic and modern track with a pulsing synth lead, dynamic basslines, and crisp drum beats, ideal for forward-thinking and innovative motivational content.",
  "Gentle and calming track with soft piano and flowing strings, perfect for nature documentaries and peaceful outdoor scenes.",
  "Bright and uplifting track with a cheerful acoustic guitar melody and light percussion, perfect for sunny outdoor adventures.",
  "Soothing and reflective music with a soft harp melody and gentle water-like synth textures, ideal for forest or river scenes.",
  "Serene and meditative music with soft choral pads and shimmering ambient tones, ideal for calming yoga or relaxation videos set in nature.",
  "Elegant and calming track with a soft piano melody and warm string harmonies, perfect for photography portfolios and creative slideshows.",
  "Soothing and reflective music with a flowing violin melody and ambient pads, ideal for black-and-white photography and emotional storytelling.",
  "Cheerful and uplifting track with a catchy piano theme and light hand percussion, perfect for family or travel photo compilations.",
  "Serene and meditative music with delicate harp arpeggios and soft choral pads, ideal for fine art photography and exhibition slideshows.",
  "Warm and inviting track with a mellow acoustic guitar riff and subtle bass, perfect for wedding photography presentations.",
  "Playful and lively track with a bright ukulele melody and rhythmic claps, perfect for casual or lifestyle photography slideshows.",
  "Bright and inviting music featuring rhythmic acoustic guitar and subtle percussion, ideal for conversational or lifestyle podcasts.",
  "Calm and reflective music with gentle harp arpeggios and ambient tones, ideal for storytelling or mindfulness podcasts.",
  "Playful and lighthearted track with a catchy ukulele melody and soft claps, ideal for family-friendly or casual podcasts.",
  "Sophisticated and jazzy track with a soulful saxophone melody, smooth piano harmonies, and subtle drum rhythms, perfect for late-night or cultural podcasts.",
  "Upbeat and cheerful track with a vibrant marimba melody, rhythmic snaps, and warm basslines, perfect for creative or design-focused podcasts.",
  "Gentle and reflective track with a soft piano melody, warm strings, and subtle ambient pads, perfect for heartfelt storytelling moments.",
  "Mysterious and suspenseful track with haunting violin phrases, low bass pulses, and soft drum textures, ideal for thriller or mystery storytelling.",
  "Bright and playful track featuring a cheerful ukulele melody, rhythmic claps, and vibrant marimba tones, perfect for lighthearted and whimsical stories.",
  "Calm and soothing music with delicate harp arpeggios, subtle choral pads, and warm orchestral textures, ideal for introspective or meditative tales.",
  "Energetic and bold track with driving drum beats, pulsing basslines, and dynamic electric guitar riffs, perfect for sports highlights and action montages.",
  "High-intensity music featuring sharp synth leads, rhythmic claps, and powerful drum patterns, ideal for competitive sports and fitness challenges.",
  "Dynamic and inspiring track with a soaring brass melody, bold orchestral strings, and steady percussion, perfect for heroic and triumphant sports moments.",
  "Fast-paced and edgy music with a heavy rock guitar riff, deep bass grooves, and sharp drum kicks, ideal for adrenaline-fueled action sequences.",
  "Uplifting and motivational track featuring a bright piano melody, rhythmic claps, and pulsing synth textures, perfect for team-focused sports content.",
  "Minimalist and sophisticated music with clean piano motifs, atmospheric synth layers, and light percussion, ideal for corporate technology videos.",
  "Optimistic and inspiring track featuring dynamic synth leads, rhythmic claps, and warm basslines, perfect for team-focused tech presentations.",
  "Ethereal and modern music with flowing synth arpeggios, subtle chimes, and deep bass textures, perfect for futuristic and visionary tech content.",
  "High-energy track with a sharp electronic beat, driving basslines, and bright synth melodies, ideal for app demonstrations and tech commercials.",
  "Dynamic and inspiring track featuring a rhythmic piano motif, soft orchestral strings, and steady percussion, perfect for road trip montages.",
  "Warm and inviting track featuring gentle acoustic guitar strums, soft piano chords, and subtle ambient textures, perfect for coastal and tropical travel scenes.",
  "Serene and reflective track with soft harp arpeggios, flowing flute melodies, and ambient pads, perfect for tranquil mountain or forest travel videos.",
  "A sleek and modern track with a bold synth lead melody. The clip opens with pulsing synth arpeggios and crisp electronic beats, transitioning into dynamic basslines and shimmering pads, perfect for concise tech tutorials.",
  "An elegant and calming track with a flute melody. The piece starts with soft flute tones and ambient harp arpeggios, adding gentle choral pads for a serene and focused tutorial background.",
  "Bright and lively track featuring a cheerful ukulele melody, rhythmic claps, and vibrant marimba accents, perfect for upbeat and engaging travel vlogs.",
  "Smooth and modern track with a mellow electric piano melody, soft basslines, and subtle drum grooves, ideal for lifestyle or wellness vlogs.",
  "Uplifting and dynamic track with a rhythmic acoustic guitar riff, steady percussion, and warm piano harmonies, perfect for outdoor adventure vlogs.",
  "Relaxing and serene track featuring a flowing flute melody, soft ambient pads, and light percussion, perfect for calm and reflective vlog moments.",
  "Funky and upbeat track with a vibrant electric guitar riff, dynamic drum beats, and deep bass grooves, perfect for energetic and fun-filled travel vlogs.",
  "Warm and inviting track featuring soft acoustic guitar, gentle piano chords, and rhythmic hand claps, perfect for family or cozy home vlogs.",
  "A tender and romantic track featuring a heartfelt piano melody, soft string harmonies, and delicate harp arpeggios, perfect for wedding ceremonies and emotional montages.",
  "An elegant and uplifting piece with a flowing violin melody, warm orchestral strings, and subtle choral pads, ideal for romantic and heartfelt moments.",
  "A dreamy and serene track with a gentle flute melody, soft ambient pads, and shimmering harp textures, perfect for intimate wedding vows and proposals.",
  "An ethereal and magical track with a soft harp melody, subtle chimes, and flowing orchestral layers, perfect for dreamy wedding settings and fairy-tale moments.",
  "A joyful and celebratory track with a lively acoustic guitar melody, rhythmic hand claps, and cheerful violin accents, perfect for wedding receptions and outdoor celebrations.",
  "A classic and timeless piece with a beautiful piano and violin duet, warm string harmonies, and gentle cymbal rolls, perfect for traditional wedding ceremonies and love declarations.",
  "A rhythmic and groovy piece featuring a bold percussion-driven beat, subtle synth textures, and pulsing bass, perfect for dance and aerobics workouts.",
  "A tranquil and restorative track with a flowing acoustic guitar melody, light percussion, and shimmering ambient tones, perfect for pilates and wellness routines.",
  "A confident and energizing track with a steady drum and bass rhythm, sharp synth stabs, and bold electronic textures, ideal for powerlifting and endurance workouts.",
  "Brooding instrumental with intense, sustained strings and subtle, unsettling synth textures, conveying a sense of anger without overwhelming the listener.",
  "Tense and edgy background music with sharp, distorted synth stabs and a deep, growling bass, creating an intense but unobtrusive atmosphere.",
  "Dark, intense tune featuring dissonant chords and a steady, pulsing synth line, ideal for a tense café or retail environment.",
  "Moody and aggressive background track with low, rumbling bass and sharp, minimal synth motifs, creating a controlled but tense atmosphere.",
  "Angry yet subdued background music with low, distorted synths and dark, atmospheric pads, perfect for conveying tension in an elevator setting.",
  "Gentle and cheerful instrumental with soft acoustic guitar and warm synth pads, perfect for a welcoming retail environment.",
  "Light and uplifting background music featuring bright piano chords and soft, airy melodies, ideal for a café setting",
  "Bright and calming instrumental with soft bell tones and smooth, light harmonies, suitable for elevator background music.",
  "Upbeat yet unobtrusive instrumental with cheerful guitar plucks and subtle synths, perfect for enhancing marketing videos.",
  "Smooth, positive background track with soft, twinkling synths and warm harmonies, creating a relaxed, bright mood for games.",
  "Light and breezy instrumental with gentle guitar and uplifting synth lines, perfect for a cheerful lobby atmosphere.",
  "Happy and mellow tune with soft acoustic elements and bright, unobtrusive melodies, designed for a relaxed café setting.",
  "Gentle and calming instrumental with soft piano melodies and warm ambient pads, perfect for a peaceful café setting.",
  "Tranquil background music featuring mellow guitar tones and soothing synth layers, ideal for creating a relaxing retail atmosphere.",
  "Soft, serene instrumental with delicate strings and gentle harmonies, designed for a calming lobby experience.",
  "Mellow and peaceful background music with gentle guitar plucks and warm, enveloping harmonies, suitable for a relaxed retail space.",
  "Serene and ambient tune featuring soft, sustained synth tones and a slow, soothing melody, perfect for a calming game soundtrack.",
  "Relaxing and tranquil track featuring soft piano and warm, ambient layers, perfect for creating a serene, calming mood in an elevator setting.",
  "Light and breezy instrumental with soft acoustic guitar and gentle synth pads, creating a carefree and relaxed vibe, perfect for retail spaces.",
  "Easygoing and mellow track with warm ukulele strums and soft, airy melodies, ideal for a sunny café atmosphere.",
  "Chill, carefree background music featuring gentle guitar plucks and soft synth textures, perfect for a casual, relaxed setting in a lobby.",
  "Upbeat yet mellow tune with soft acoustic elements and light, playful synths, creating a cheerful atmosphere for marketing videos.",
  "Warm and laid-back track with subtle guitar riffs and smooth, breezy melodies, perfect for a calm retail environment.",
  "Soft, carefree background music with gentle bell tones and airy synths, creating a relaxed, uplifting ambiance for a café setting.",
  "Chill and light instrumental featuring soft guitar and mellow synth lines, perfect for creating a carefree, easygoing mood in games.",
  "Sunny and relaxed tune with gentle strumming and soft, warm harmonies, ideal for a casual, carefree lobby experience.",
  "Easygoing, mellow instrumental with smooth ukulele and subtle, bright synths, designed for a relaxed, carefree retail soundscape.",
  "An action-packed and suspenseful tune featuring bold brass accents, driving bass grooves, and sharp snare hits, creating a dynamic and chasing vibe.",
  "A high-energy and dramatic tune featuring cascading harp motifs, bold brass stabs, and driving percussion, creating a powerful and intense chasing atmosphere.",
  "A tense and dramatic track with rapid cello phrases, bold trumpet stabs, and rhythmic tom rolls, creating a sense of urgency and excitement.",
  "An energetic and suspenseful instrumental with rhythmic snare rolls, sharp brass stabs, and dynamic string harmonics, perfect for an adrenaline-fueled chase.",
  "A tranquil and easygoing instrumental with slow acoustic guitar arpeggios, subtle flute accents, and soft ambient textures, evoking a calming and chill atmosphere.",
  "A dreamy and tranquil tune featuring slow piano progressions, soft choral layers, and warm string harmonies, ideal for creating a peaceful and chill background.",
  "A smooth and reflective piece with a jazzy saxophone melody, warm keyboard textures, and rhythmic hi-hats, creating a stylish and chill mood.",
  "A calming and soothing instrumental with flowing synth pads, gentle flute melodies, and light bass grooves, perfect for a laid-back and chill vibe.",
  "A breezy and elegant tune featuring a soft vibraphone melody, gentle electric piano chords, and smooth ambient layers, perfect for creating a serene and chill setting.",
  "A mellow and relaxing track featuring soft acoustic guitar riffs, smooth electric piano chords, and subtle drum patterns, creating a chill and serene atmosphere.",
  "A mellow and comforting tune with a gentle piano motif, soft string swells, and shimmering harp textures, creating a cozy and inviting background.",
  "A calming and reflective instrumental featuring slow acoustic guitar arpeggios, tender violin harmonies, and light choral layers, evoking a sense of peace and warmth.",
  "A bright and warm track with rhythmic ukulele chords, gentle flute accents, and soft marimba tones, perfect for creating a cozy and relaxing atmosphere.",
  "A tender and comforting instrumental with a mellow flute melody, delicate harp arpeggios, and soft choral pads, perfect for creating a serene and cozy mood.",
  "An epic and tense instrumental with sharp violin stabs, pulsating basslines, and rhythmic snare rolls, perfect for dramatic storytelling and high-stakes moments.",
  "A dynamic and intense piece featuring rhythmic string ostinatos, bold brass stabs, and deep bass drones, ideal for building tension and drama.",
  "A powerful and evocative track featuring soaring orchestral brass, rhythmic snare patterns, and dramatic cymbal swells, creating a majestic and dramatic atmosphere.",
  "An emotional and majestic instrumental featuring flowing cello lines, shimmering harp accents, and a dynamic choral backdrop, ideal for creating a grand and dramatic mood.",
  "A dark and compelling piece with repetitive piano motifs, low string harmonies, and subtle percussive accents, perfect for evoking tension and drama.",
  "A flowing and atmospheric instrumental with warm synth pads, delicate flute phrases, and smooth string layers, perfect for evoking a sense of wonder and imagination.",
  "A graceful and enchanting piece with soft music box melodies, gentle harp arpeggios, and shimmering synth pads, perfect for a tranquil and whimsical mood.",
  "A peaceful and imaginative instrumental with slow acoustic guitar strums, warm ambient textures, and light piano harmonies, evoking a sense of dreamy introspection.",
  "An enchanting and soothing piece with cascading harp arpeggios, soft ambient tones, and faint chime accents, creating a whimsical and dreamy background.",
  "An ethereal and reflective tune featuring flowing flute motifs, delicate piano arpeggios, and smooth orchestral strings, creating a serene and dreamy atmosphere.",
  "A whimsical and soft instrumental with a flowing marimba motif, gentle synth textures, and warm cello undertones, creating an inviting and dreamy tone.",
  "An atmospheric and serene track featuring delicate vibraphone tones, flowing string layers, and subtle harp accents, creating a calming and dreamy backdrop.",
  "A serene and ethereal track featuring soft piano melodies, shimmering harp textures, and gentle choral harmonies, creating a tranquil and dreamy atmosphere.",
  "A dynamic and whimsical tune with flowing violin pizzicatos, rhythmic snaps, and gentle accordion textures, creating an eccentric and upbeat atmosphere.",
  "A quirky and dynamic track featuring plucky pizzicato strings, bouncing marimba tones, and playful woodwind melodies, creating an unconventional and eccentric atmosphere.",
  "A radiant and sophisticated track with cascading harp motifs, subtle string harmonics, and light flute accents, perfect for creating an elegant and calming vibe.",
  "A serene and graceful instrumental with tender violin swells, soft piano chords, and light ambient textures, creating an elegant and peaceful mood.",
  "A smooth and polished track featuring a melodic piano progression, subtle flute accents, and gentle cello harmonies, perfect for creating a sense of sophistication and calm.",
  "An elegant and uplifting tune featuring smooth piano chords, warm cello undertones, and light harp accents, ideal for creating a luxurious and serene background.",
  "A timeless and radiant piece featuring delicate harp arpeggios, smooth string harmonies, and shimmering piano melodies, ideal for upscale and stylish moments.",
  "A tender and timeless piece with flowing violin melodies, soft piano arpeggios, and warm ambient pads, creating a sense of refinement and elegance.",
  "A graceful and refined track featuring a soft piano melody, delicate string harmonies, and gentle harp arpeggios, creating a serene and elegant atmosphere.",
  "A dynamic and upbeat piece featuring rhythmic piano chords, bold saxophone accents, and crisp hi-hats, creating a stylish and energetic backdrop.",
  "A vibrant and rhythmic tune with flowing synth arpeggios, groovy basslines, and steady drum beats, ideal for creating a fast-paced and exciting mood.",
  "A thrilling and powerful track featuring sharp snare rolls, bold trombone harmonies, and soaring string melodies, creating a majestic and epic atmosphere.",
  "A radiant and heroic tune featuring bold trumpet fanfares, steady percussion beats, and shimmering ambient pads, creating an uplifting and epic mood.",
  "A cinematic and awe-inspiring instrumental with cascading harp arpeggios, dynamic orchestral strings, and resonant brass, perfect for creating a sense of epic adventure.",
  "A powerful and cinematic track featuring soaring orchestral strings, bold brass stabs, and thunderous percussion, creating a dramatic and epic atmosphere.",
  "A sweeping and emotional piece featuring soaring flute lines, warm string harmonies, and dramatic cymbal crashes, perfect for creating a majestic and epic background.",
  "An emotional and grand piece with a flowing piano melody, triumphant horn stabs, and thunderous drum beats, creating a dramatic and epic tone.",
  "An intense and majestic instrumental with deep bass drums, triumphant horn melodies, and cascading piano arpeggios, perfect for creating a heroic and epic mood.",
  "A triumphant and exhilarating track with layered orchestral brass, rhythmic string ostinatos, and powerful drum patterns, creating a bold and epic tone.",
  "A powerful and radiant instrumental with bold trumpet fanfares, soaring string harmonies, and energetic drum rolls, creating a celebratory and euphoric atmosphere.",
  "A dreamy and high-energy instrumental featuring pulsing synth bass, bright flute accents, and rhythmic snare rolls, evoking a sense of weightless euphoria.",
  "An electrifying and cheerful piece with shimmering synth arpeggios, vibrant guitar riffs, and pulsing beats, ideal for creating a lively and euphoric backdrop.",
  "A vibrant and uplifting track featuring soaring synth melodies, pulsing basslines, and energetic drum patterns, creating a powerful and euphoric atmosphere.",
  "A radiant and lively tune featuring a flowing saxophone melody, rhythmic guitar riffs, and energetic drum grooves, creating a jubilant and euphoric vibe.",
  "An energetic and inspiring piece with vibrant acoustic guitar strums, dynamic piano motifs, and steady drum beats, creating an uplifting and euphoric tone.",
  "A dynamic and celebratory instrumental with bright piano chords, shimmering string harmonies, and rhythmic claps, perfect for creating a sense of joy and excitement.",
  "A joyful and spirited track featuring cascading vibraphone melodies, warm synth layers, and driving basslines, perfect for an upbeat and euphoric mood.",
  "A minimal and suspenseful track with plucked strings, deep bass pulses, and faint chime accents, evoking a sense of creeping fear and unease.",
  "A brooding and atmospheric instrumental with sharp violin screeches, low ambient drones, and rhythmic drum taps, perfect for creating a tense and fearful backdrop.",
  "A dreamy and minimal instrumental featuring soft synth pads, light chime accents, and smooth violin harmonies, perfect for a floating and introspective setting.",
  "A serene and atmospheric track featuring soft ambient pads, flowing harp arpeggios, and delicate string harmonics, creating a dreamy and floating mood.",
  "An elegant and reflective track with flowing piano arpeggios, subtle vibraphone tones, and gentle orchestral strings, creating a serene and weightless backdrop.",
  "A graceful and tranquil piece featuring a flowing harp melody, shimmering string harmonies, and smooth ambient textures, ideal for creating a dreamy and weightless mood.",
  "A calm and uplifting piece with a flowing acoustic guitar motif, warm ambient pads, and delicate piano harmonies, creating an atmosphere of serene suspension.",
  "A quirky and upbeat track featuring plucky pizzicato strings, bouncing marimba tones, and rhythmic hand claps, creating a playful and humorous atmosphere.",
  "A whimsical and carefree track featuring a soft music box melody, light vibraphone textures, and gentle harp accents, creating a fun and childlike comedic tone.",
  "A chic and stylish tune with a rhythmic electric piano motif, flowing saxophone lines, and smooth bass grooves, creating a glamorous and luxurious mood.",
  "A sophisticated and luxurious track featuring smooth piano melodies, elegant string harmonies, and soft ambient pads, creating a refined and glamorous atmosphere.",
  "An opulent and inviting tune with soft piano arpeggios, warm cello harmonies, and delicate flute phrases, creating a luxurious and glamorous backdrop.",
  "A graceful and polished track featuring soft orchestral strings, delicate harp layers, and light choral harmonies, ideal for creating an elegant and high-end atmosphere.",
  "A lively and rhythmic instrumental featuring a bright vibraphone melody, groovy bass tones, and tight percussion, ideal for casual and energetic settings.",
  "A vibrant and rhythmic track featuring funky electric guitar riffs, groovy basslines, and crisp drum patterns, creating an energetic and stylish groove.",
  "A smooth and upbeat instrumental with a flowing saxophone melody, warm keyboard harmonies, and steady bass grooves, perfect for a laid-back and groovy vibe.",
  "An intense and driving track featuring fast-paced guitar riffs, deep bass grooves, and sharp cymbal crashes, creating a raw and emotional grunge backdrop.",
  "A raw and powerful instrumental featuring heavy electric guitar leads, steady bass rhythms, and sharp drum beats, perfect for capturing a moody and alternative vibe.",
  "An uplifting and energetic piece featuring a light acoustic guitar motif, playful flute accents, and smooth ambient textures, creating a happy and inviting mood.",
  "A bright and cheerful track featuring an upbeat ukulele melody, rhythmic claps, and soft marimba tones, creating a fun and joyful atmosphere.",
  "A fun and bubbly tune with rhythmic marimba patterns, bright ukulele chords, and soft hand percussion, perfect for a joyful and casual atmosphere.",
  "A playful and lighthearted instrumental featuring a bouncy piano melody, rhythmic snaps, and gentle strings, ideal for cheerful and carefree settings.",
  "A carefree and sunny track featuring a soft accordion melody, rhythmic claps, and flowing acoustic guitar tones, ideal for lighthearted and joyful moments.",
  "A bold and intense track featuring deep bass pulses, sharp brass stabs, and heavy drum rhythms, creating a dramatic and weighty atmosphere.",
  "A tense and heavy piece with repetitive piano motifs, subtle bass drones, and sharp percussion hits, ideal for creating a dramatic and imposing atmosphere.",
  "An impactful and dramatic piece featuring layered string ostinatos, steady drum grooves, and low brass harmonies, perfect for creating a sense of intensity and weight.",
  "An optimistic and tranquil track with a flowing piano motif, gentle harp textures, and soft orchestral swells, ideal for creating a serene and positive background.",
  "An encouraging and soothing tune featuring fingerpicked acoustic guitar, warm orchestral strings, and soft harp arpeggios, ideal for creating a calm and hopeful setting.",
  "A tender and heartfelt piece with a delicate violin lead, subtle piano harmonies, and warm ambient pads, perfect for moments of quiet hope and reflection.",
  "A reflective and hopeful instrumental with slow piano arpeggios, warm orchestral strings, and subtle flute melodies, ideal for inspiring and motivational content.",
  "A light and melodic instrumental featuring a bright flute melody, soft marimba tones, and warm string layers, creating an atmosphere of calm hopefulness.",
  "A chilled and easygoing track with fingerpicked acoustic guitar, warm string harmonies, and gentle ambient textures, creating a laid-back and casual vibe.",
  "A light and breezy piece with a flowing ukulele motif, gentle chime accents, and warm bass undertones, creating a casual and laid-back background.",
  "A warm and soothing instrumental with a smooth electric piano riff, soft basslines, and light cymbal taps, ideal for cozy and relaxed environments.",
  "A breezy and calming piece with a flowing flute melody, gentle marimba tones, and soft percussion, perfect for creating a laid-back and serene mood.",
  "A mellow and atmospheric instrumental with soft flute harmonies, smooth synth pads, and gentle hand drums, perfect for creating a laid-back and stress-free vibe.",
  "A tender and romantic track featuring a soft piano melody, warm orchestral strings, and gentle harp arpeggios, creating a heartfelt and intimate atmosphere.",
  "A heartfelt and graceful track featuring flowing piano arpeggios, tender violin swells, and soft orchestral strings, perfect for creating a loving and serene mood.",
  "A soothing and romantic instrumental with a slow acoustic guitar melody, subtle choral harmonies, and warm string layers, ideal for creating an intimate and loving atmosphere.",
  "A gentle and emotional piece with a soft flute melody, warm harp textures, and delicate string harmonies, creating a calm and romantic setting.",
  "An energetic and inspiring instrumental featuring steady bass drum beats, crisp snare rhythms, and soaring brass harmonies, perfect for a triumphant marching atmosphere.",
  "A confident and bold instrumental with sharp snare patterns, triumphant brass tones, and deep bass drum pulses, ideal for inspiring and energetic moments.",
  "A triumphant and powerful instrumental featuring rhythmic snare rolls, bold trumpet fanfares, and dramatic orchestral layers, ideal for bold and commanding moments.",
  "A cinematic and rhythmic track featuring steady percussion, vibrant trombone riffs, and layered string textures, creating a commanding and motivational marching tone.",
  "A majestic and determined piece with rhythmic timpani rolls, soaring trumpet melodies, and rich orchestral layers, evoking a strong and unified marching mood.",
  "A driving and uplifting piece with steady drumline rhythms, bold horn sections, and warm choral pads, perfect for creating a unified and determined atmosphere.",
  "A dynamic and uplifting piece with a rhythmic drumline, bold trumpet fanfares, and layered orchestral strings, ideal for creating a commanding and unified mood.",
  "A bittersweet and reflective piece with a soft cello lead, gentle piano chords, and warm string harmonies, perfect for creating a somber and heartfelt atmosphere.",
  "A gentle and mournful instrumental featuring a slow violin lead, delicate harp arpeggios, and smooth orchestral strings, ideal for reflective and emotional moments.",
  "A tender and introspective track featuring a soft piano melody, gentle cello harmonies, and light ambient pads, creating a melancholic and reflective atmosphere.",
  "A moving and tender instrumental with gentle piano arpeggios, soft violin swells, and faint chime textures, perfect for creating an air of longing and introspection.",
  "haunting and reflective tune with flowing cello lines, deep bass undertones, and shimmering harp arpeggios, perfect for creating a sense of quiet mystery.",
  "A dark and curious piece featuring plucked violin pizzicatos, soft marimba tones, and distant ambient drones, ideal for evoking intrigue and uncertainty.",
  "A reflective and intriguing piece featuring delicate piano arpeggios, warm string harmonies, and subtle chime accents, ideal for creating a mysterious mood in lobbies or storytelling",
  "A dynamic and shadowy piece featuring soft harp plucks, faint flute melodies, and warm choral harmonies, creating a deeply mysterious and thoughtful atmosphere.",
  "A peaceful and uplifting piece with a flowing piano motif, soft violin accents, and shimmering ambient layers, perfect for relaxation and meditation.",
  "A gentle and soothing tune featuring flowing flute lines, delicate harp tones, and light choral harmonies, creating a serene and tranquil atmosphere for lobbies or cafés.",
  "A serene and calming track featuring soft piano chords, gentle string harmonies, and subtle ambient pads, perfect for creating a tranquil and soothing background.",
  "A light and graceful instrumental featuring a soft marimba melody, shimmering harp textures, and warm choral pads, perfect for peaceful and unobtrusive settings",
  "A delicate and calming piece with a soft harp melody, gentle flute accents, and smooth ambient tones, creating a serene and restful background for quiet environments",
  "A mellow and comforting instrumental featuring flowing piano arpeggios, tender cello harmonies, and light harp textures, perfect for peaceful and introspective moments.",
  "A bright and breezy tune featuring a playful flute melody, soft marimba accents, and gentle cymbal taps, ideal for cheerful and whimsical content.",
  "A whimsical and upbeat tune with plucky pizzicato strings, soft xylophone riffs, and gentle hand percussion, creating a playful and charming atmosphere.",
  "A dynamic and joyful track with a bouncing vibraphone motif, rhythmic ukulele strums, and subtle tambourine shakes, creating a fun and uplifting background.",
  "A joyful and bright piece with a gentle xylophone lead, rhythmic tambourine beats, and warm acoustic guitar layers, perfect for lighthearted and engaging moments.",
  "A bold and inspiring track with a flowing acoustic guitar motif, warm orchestral layers, and driving percussion, perfect for creating a determined and resolute mood.",
  "A commanding and uplifting instrumental with driving electric guitar riffs, pounding basslines, and crisp drum patterns, ideal for strong and inspiring content.",
  "A cinematic and epic piece featuring deep bass pulses, dynamic orchestral layers, and bold timpani rolls, creating a powerful and majestic atmosphere for dramatic scenes",
  "A dynamic and energizing track with a bold electric piano motif, crisp electronic textures, and rhythmic bass, perfect for creating a commanding and modern atmosphere",
  "An empowering and dynamic tune with vibrant synth leads, pulsing electronic basslines, and sharp drum hits, creating a powerful and futuristic vibe for bold settings",
  "An epic and powerful instrumental featuring bold trumpet fanfares, soaring strings, and dramatic cymbal crashes, creating a sense of grandeur and determination.",
  "A playful and upbeat track featuring a bouncy marimba melody, rhythmic snaps, and light ukulele strums, creating a fun and whimsical vibe for casual settings",
  "A fun and breezy instrumental with a flowing flute melody, subtle marimba tones, and rhythmic claps, perfect for creating a quirky and charming atmosphere.",
  "A bouncy and eccentric track with a lively vibraphone melody, subtle bass plucks, and soft cymbal taps, creating a quirky and engaging background for creative spaces.",
  "A quirky and cheerful tune with plucky pizzicato strings, soft basslines, and lively clarinet phrases, perfect for adding a lighthearted touch to creative content.",
  "A playful and melodic piece featuring soft marimba riffs, rhythmic finger snaps, and light strings, perfect for whimsical and offbeat moments in storytelling.",
  "A dynamic and whimsical piece with a bouncy xylophone motif, soft acoustic guitar accents, and gentle percussion, perfect for playful and quirky environments.",
  "Soft and peaceful music with a tender flute melody, warm string layers, and light harp textures, perfect for relaxation and meditative moments.",
  "A light and breezy tune with gentle marimba tones, rhythmic hand percussion, and soft ambient pads, ideal for creating a tranquil and inviting atmosphere",
  "A warm and dreamy track featuring a tender acoustic guitar motif, light choral pads, and soft piano arpeggios, perfect for relaxed and peaceful environments.",
  "A smooth and tranquil instrumental with soft electric piano chords, subtle ambient drones, and faint percussion, creating a serene and unobtrusive background for wellness content",
  "A mellow and inviting piece with a delicate piano motif, gentle orchestral layers, and warm bass undertones, ideal for spa and wellness environments.",
  "An unsettling instrumental with soft marimba melodies, pulsing synth pads, and distant percussive echoes, creating a jittery and suspenseful atmosphere.",
  "A dynamic and nervous piece featuring layered pizzicato strings, soft chime accents, and a driving ambient rhythm, perfect for moments of quiet agitation.",
  "Edgy and atmospheric track with a repetitive electric piano motif, deep bass textures, and faint percussion rolls, creating a restless and introspective vibe.",
  "Eerie and atmospheric track featuring subtle harp arpeggios and deep bass pulses, perfect for creating a sinister and foreboding mood in dark environments",
  "Ominous instrumental with a repetitive piano motif and soft ambient whispers, creating an unsettling and mysterious atmosphere for suspenseful storytelling.",
  "Gentle and melodic instrumental featuring a soothing piano lead and warm string layers, ideal for creating a comforting and emotional background in vlogs",
  "Mellow and soothing instrumental with gentle harp melodies and subtle flute accents, perfect for creating a relaxing and sentimental vibe for storytelling.",
  "Delicate and soulful melody with flowing flute lines and soft harp arpeggios, creating a serene and heartfelt atmosphere for quiet reflections.",
  "Graceful and uplifting track featuring delicate piano arpeggios and soft choral textures, providing an emotional and introspective background for heartfelt narratives.",
  "A gripping and intense track with a bold synth bassline, sharp drum patterns, and eerie ambient tones, perfect for modern and serious narratives",
  "A somber and elegant track with a flowing violin melody, soft piano chords, and warm cello undertones, perfect for serious and emotional storytelling",
  "A sultry and smooth track featuring a soulful saxophone melody, warm electric piano chords, and soft jazz drum patterns, perfect for intimate and romantic moments",
  "A seductive and cinematic track featuring a slow piano melody, lush orchestral strings, and gentle cymbal rolls, perfect for dramatic and romantic scenes",
  "A sultry and minimalistic piece with a delicate vibraphone motif, subtle bass pulses, and warm synth pads, creating a soft and seductive tone",
  "A mellow and inviting piece with a tender electric guitar melody, smooth drum grooves, and warm ambient textures, ideal for cozy and romantic evenings",
  "A classy and timeless track with a gentle harp arpeggio, soft ambient pads, and shimmering string harmonies, ideal for serene and refined moments",
  "A light and elegant piece featuring a delicate piano motif, subtle string harmonies, and soft cymbal rolls, perfect for understated and graceful moments",
  "A laid-back and jazzy track with a flowing clarinet melody, muted drum rhythms, and smooth electric piano textures, ideal for sophisticated yet relaxed vibes",
  "A warm and inviting piece featuring a soft vibraphone lead, steady basslines, and light percussion, perfect for creating a smooth and mellow atmosphere.",
  "A chic and groovy track with a rhythmic bass guitar riff, warm organ chords, and crisp snare beats, ideal for stylish and modern settings.",
  "A quirky and sneaky piece featuring a playful clarinet melody, plucked strings, and soft rhythmic shakers, perfect for stealth scenes with a humorous twist",
  "A playful and suspenseful track featuring a quirky bassoon melody, muted strings, and rhythmic snaps, perfect for mischievous and humorous sneaking scenes",
  "A quiet and tense track featuring a subtle piano motif, soft string pizzicatos, and low ambient drones, perfect for stealth and sneaking moments",
  "A minimal and stealthy track with a repetitive harp arpeggio, muted basslines, and light cymbal splashes, creating a quiet yet tense atmosphere.",
  "A brooding and suspenseful track with a low synth bassline, soft percussion, and sparse string stabs, ideal for covert operations and sneaky moments.",
  "An eerie and dynamic piece with a haunting flute melody, soft marimba accents, and layered ambient drones, ideal for tense and sneaky scenarios.",
  "A light and stealthy track featuring pizzicato violins, deep bass pulses, and subtle electronic textures, perfect for quiet and secretive moments",
  "An elegant and calming track with a soft harp melody, subtle choral harmonies, and flowing ambient textures, ideal for serene and contemplative content.",
  "A peaceful and airy track featuring a bright marimba melody, gentle hand percussion, and warm pads, perfect for creating a calm and uplifting atmosphere.",
  "A gentle and serene track featuring a flowing piano melody, soft string harmonies, and subtle ambient pads, perfect for peaceful and reflective moments.",
  "A warm and inviting piece with a soothing acoustic guitar melody, light percussion, and soft bass undertones, ideal for cozy and calming settings.",
  "A dreamy and comforting piece with a slow violin motif, soft piano chords, and light ambient textures, ideal for heartfelt and intimate moments.",
  "A tranquil and dreamy piece with a flowing piano motif, soft ambient tones, and delicate string harmonies, perfect for peaceful intros and outros.",
  "A quiet and unsettling piece with a subtle clarinet melody, deep bass drones, and sparse percussion hits, creating a tense and mysterious atmosphere",
  "A brooding and cinematic track featuring a slow brass progression, soft timpani rolls, and shimmering synth layers, ideal for moments of dread and anticipation",
  "A foreboding and intense piece featuring a bold cello melody, low orchestral swells, and sharp staccato strings, ideal for escalating suspense in thriller moments",
  "A haunting and mysterious track featuring a slow piano melody, deep bass pulses, and soft ambient textures, perfect for building suspense in investigative scenes",
  "An eerie and atmospheric piece with pulsing synth basslines, subtle percussion, and haunting ambient pads, ideal for moments of quiet unease",
  "A dynamic and unsettling track featuring a creeping piano motif, low orchestral strings, and distant drum rolls, perfect for suspenseful and mysterious settings",
  "A minimal and suspenseful track with a repetitive harp arpeggio, soft ambient drones, and distant chime accents, creating a sense of quiet tension",
  "A brooding and suspenseful piece featuring a slow piano melody, layered with low string harmonies and soft percussive textures, perfect for dramatic reveals",
  "A bright and energetic track with a catchy acoustic guitar melody, rhythmic claps, and cheerful marimba accents, perfect for celebratory and inspiring moments",
  "A heartfelt and uplifting track featuring a gentle violin melody, flowing piano chords, and subtle string harmonies, perfect for emotional and joyous moments",
  "An inspiring and modern piece featuring a flowing synth lead melody, pulsing basslines, and dynamic drum patterns, ideal for forward-thinking and innovative themes",
  "An uplifting and motivational piece featuring a bold piano melody, soaring strings, and steady percussion, ideal for heartwarming and triumphant scenes",
  "A serene and uplifting track featuring a warm flute melody, soft ambient pads, and gentle harp arpeggios, ideal for calm yet inspiring moments",
  "A joyful and celebratory track with a rhythmic steel drum melody, bright acoustic guitar strums, and lively percussion, perfect for tropical and happy vibes",
  "A vibrant and cheerful track with a bouncy ukulele melody, rhythmic snaps, and playful tambourine, perfect for lighthearted and optimistic content",
  "An energetic and groovy piece with a funky electric guitar riff, vibrant basslines, and crisp drumbeats, perfect for upbeat and exciting content",
  "A mysterious and eerie piece with a creeping piano motif, deep bass pulses, and ambient whispers, creating an unsettling and weirdly captivating mood.",
  "A whimsical and curious track with a swirling harp arpeggio, plucky pizzicato strings, and unpredictable woodwind flourishes, ideal for offbeat and eccentric stories.",
  "A tender and reflective piece showcasing soft acoustic guitar leads, warm violin harmonies, and gentle ambient textures, evoking a heartfelt and calm mood",
  "A nostalgic and elegant instrumental with soft acoustic guitar chords, delicate flute accents, and gentle string harmonies, evoking a timeless and romantic tone.",
  "A rich and earthy instrumental showcasing soft acoustic guitar plucking, subtle harmonica accents, and steady basslines.",
  "A lively and rhythmic instrumental with bright acoustic guitar strums, rhythmic clapping, and subtle basslines.",
  "A serene and melodic track centered on flowing acoustic piano melodies, complemented by acoustic bass and shimmering pads.",
  "A calming and atmospheric track with slow acoustic guitar arpeggios, warm ambient pads, and light cello undertones.",
  "A warm and inviting track featuring gentle acoustic guitar fingerpicking, soft piano chords, and light hand percussion.",
  "Soft ambient soundscape with gentle, evolving synth tones and delicate, airy melodies, perfect for a tranquil setting",
  "Deep ambient music with rich, atmospheric layers and a slow, evolving drone, creating a soothing, immersive experience",
  "Light and airy ambient instrumental featuring soft, shimmering synths and a warm, enveloping sound, ideal for relaxation",
  "Relaxing ambient track with gentle pads and slowly evolving harmonies, designed to create a peaceful, unobtrusive background",
  "Melodic guitar ballad featuring expressive acoustic guitar and warm harmonies",
  "Romantic ballad with a soft piano intro, delicate strings, and gentle melody",
  "Dreamy acoustic ballad with warm guitar plucks and soft ambient background",
  "Soulful ballad featuring expressive strings, soft keys, and gentle atmosphere",
  "Mellow acoustic ballad with warm guitar tones, subtle bass, and soft mood",
  "Orchestral ballad with gentle piano, sweeping strings, and emotional feel",
  "Soft rock ballad with smooth guitar solo, light percussion, warm melody",
  "Lo-fi bedroom pop instrumental with dreamy guitar and a soft, warm ambiance",
  "Dreamy bedroom pop melody featuring soft guitar strums and subtle electronic elements",
  "Smooth bedroom pop instrumental with lo-fi beats and soft, airy vocals in the background",
  "Dreamy synth-driven bedroom pop instrumental with a warm, enveloping sound",
  "Chill bedroom pop instrumental with a lo-fi beat, mellow guitar riffs, and ambient textures",
  "Lo-fi bedroom pop instrumental with dreamy guitar and soft, no percussion",
  "Dreamy bedroom pop melody featuring soft guitar strums and ambient textures, with light percussion",
  "Dreamy bedroom pop melody featuring soft guitar strums and ambient textures, with light percussion",
  "Dreamy bedroom pop melody featuring soft guitar strums and ambient textures, with light percussion",
  "Smooth bedroom pop instrumental with lo-fi beats and soft kicks, keeping the percussion light",
  "A reflective and mellow instrumental with soft lo-fi beats, gentle piano chords, and warm vinyl crackle textures, evoking a nostalgic and introspective tone.",
  "A dynamic and upbeat piece featuring punchy drum kicks, rhythmic claps, and subtle ambient pads, perfect for an energetic and contemporary background.",
  "A rich and vibrant tune with layered trap-inspired beats, rhythmic bass grooves, and subtle electronic pads, creating a dynamic and versatile background.",
  "Gentle and mellow blues track featuring light, fingerpicked guitar and a soft, steady rhythm, ideal for a cozy café atmosphere.",
  "Soft and soulful blues instrumental with smooth guitar riffs and gentle, flowing melodies, suitable for a calm lobby setting.",
  "Relaxed blues background track featuring clean guitar leads and soft piano harmonies, creating a soothing and unobtrusive elevator soundtrack.",
  "Laid-back and soulful blues track featuring clean electric guitar tones and subtle, warm piano, creating a relaxed and inviting café atmosphere.",
  "Soft, clean blues tune with a warm, flowing guitar solo and subtle piano chords, creating a serene and calming mood for lobbies.",
  "Laid-back chill track featuring mellow piano chords and airy synth textures, perfect for a calming café atmosphere.",
  "Warm and soothing chill music with soft, flowing melodies and a gentle, steady rhythm, ideal for a relaxed retail environment.",
  "Mellow and polished chill instrumental featuring soft guitar plucks and smooth, warm synth layers, perfect for unobtrusive background music in marketing videos.",
  "Relaxing chill track with gentle piano riffs and a soft, airy ambiance, ideal for creating a peaceful mood in retail soundscapes.",
  "Soft and dreamy chill music featuring warm synth pads and subtle, melodic guitar lines, suitable for a serene café setting.",
  "Smooth and calming chill instrumental with flowing synth chords and gentle harmonies, creating a soothing atmosphere for games.",
  "Mellow and inviting chill track featuring gentle, dreamy synths and smooth, polished melodies, ideal for a calming elevator soundtrack.",
  "Playful and cheerful instrumental with light xylophone melodies and soft ukulele strums, creating a friendly and fun atmosphere for kids.",
  "Upbeat and whimsical tune featuring gentle piano riffs and bright, bouncy synths, perfect for a lively retail environment in a toy store.",
  "Light and happy children’s track with warm guitar plucks and playful bell sounds, ideal for creating a cheerful café atmosphere.",
  "Gentle and upbeat background music with soft flute melodies and light percussion, creating a playful and welcoming vibe for kids' areas.",
  "Bright and fun instrumental featuring a gentle ukulele and soft, twinkling bells, perfect for creating a friendly, unobtrusive background in marketing videos.",
  "Cheerful and easygoing background music with gentle guitar strums and light marimba melodies, creating a playful and warm atmosphere for cafés.",
  "Fun and upbeat children’s instrumental with soft, happy melodies and a light, bouncy rhythm, perfect for creating a joyful vibe in games.",
  "Light and joyful children’s track featuring soft, airy synths and playful xylophone riffs, creating a friendly and engaging background for lobbies.",
  "Soft and cheerful instrumental with gentle glockenspiel melodies and light, warm harmonies, ideal for a calm and playful elevator soundtrack.",
  "A tranquil and atmospheric piece centered on delicate harp arpeggios, shimmering string harmonics, and subtle flute accents, creating a magical and ethereal backdrop",
  "A heartfelt and emotional tune featuring tender cello solos, soft piano chords, and gentle string swells, evoking a nostalgic and touching cinematic soundscape",
  "An uplifting and triumphant track with soaring violin melodies, rhythmic snare rolls, and vibrant horn sections, ideal for an inspiring and heroic cinematic setting",
  "A serene and reflective cinematic piece with gentle piano arpeggios, warm ambient pads, and soft choral harmonies, perfect for an emotional and introspective tone",
  "A vibrant and celebratory orchestral track featuring cascading woodwind lines, rhythmic percussion, and triumphant brass fanfares, perfect for a joyful and uplifting cinematic setting",
  "A reflective and heartfelt instrumental showcasing tender cello solos, soft piano chords, and warm viola layers, evoking a calm and emotional classical mood",
  "A majestic and uplifting orchestral track centered on soaring violin phrases, cascading harp arpeggios, and bold horn sections, creating a heroic and inspiring atmosphere",
  "A bold and rhythmic classical track showcasing sharp string staccatos, steady bass pizzicatos, and dynamic timpani rolls, creating an intense and dramatic soundscape",
  "A serene and elegant classical piece featuring flowing piano arpeggios, delicate string harmonies, and subtle woodwind phrases, creating a timeless and introspective atmosphere",
  "A grand and cinematic orchestral tune featuring powerful violin crescendos, dramatic brass accents, and deep bass harmonies, ideal for a bold and expansive classical setting",
  "A nostalgic and timeless instrumental with flowing oboe melodies, rich string textures, and delicate piano arpeggios, evoking a heartfelt and romantic tone",
  "A smooth and elegant instrumental with flowing synth pads, light acoustic guitar picking, and steady basslines, perfect for a calm and inspiring corporate setting",
  "A smooth and atmospheric instrumental with flowing piano progressions, subtle electronic textures, and light percussion, evoking a serene and creative corporate environment",
  "A bright and motivational tune featuring layered acoustic guitars, dynamic drum builds, and shimmering bell-like synths, perfect for an inspiring and forward-thinking tone",
  "An inspiring and upbeat track with cascading piano arpeggios, steady drum grooves, and shimmering ambient pads, evoking a sense of progress and success",
  "A bright and uplifting corporate track featuring rhythmic piano chords, soft guitar harmonics, and subtle drum patterns, creating a professional and motivational atmosphere",
  "Smooth country tune featuring soft guitar picking and light fiddle harmonies, providing a warm and inviting feel for marketing videos",
  "Laid-back country track featuring light banjo plucks, smooth guitar riffs, and a mellow, friendly vibe, perfect for a retail environment",
  "Relaxed country instrumental with gentle acoustic guitar leads and light mandolin melodies, creating a soothing, unobtrusive backdrop for lobbies.",
  "Mellow and easygoing country track with warm acoustic guitar and soft harmonica, creating a calming and pleasant atmosphere for an elevator setting",
  "Soft and melodic country music with warm steel guitar tones and gentle harmonica lines, ideal for a calm and cozy café atmosphere",
  "Warm and soothing country music with soft, fingerpicked guitar and gentle fiddle lines, creating a friendly and welcoming mood for games",
  "Light and breezy country tune featuring soft acoustic guitar strums and gentle pedal steel melodies, ideal for a calm background in cafés and lobbies",
  "Relaxing country instrumental with warm, mellow guitar tones and subtle banjo accents, creating a peaceful, unobtrusive backdrop for marketing content",
  "Chill and sophisticated disco track with light, funky guitar riffs, a soft bass groove, and smooth, melodic synth layers, creating a polished atmosphere for marketing content",
  "Light and polished disco instrumental featuring a gentle bassline, soft electric piano, and mellow synth leads, perfect for a relaxed lobby ambiance.",
  "Chill and sophisticated disco track with light, funky guitar riffs, a soft bass groove, and smooth, melodic synth layers, creating a polished atmosphere for marketing content",
  "Soft and ambient disco instrumental with a laid-back bass groove, smooth synth harmonies, and gentle electric piano, ideal for a serene background in games.",
  "Elegant and mellow disco instrumental featuring warm, shimmering synths, subtle guitar accents, and a relaxed bassline, perfect for a calm lobby or elevator setting.",
  "A relaxing and melodic instrumental with soft ambient beats, flowing synth harmonies, and warm sub-bass layers, creating a serene and peaceful soundscape",
  "An uplifting and vibrant track with bright synth melodies, rhythmic bass pulses, and sparkling chime accents, ideal for a cheerful and energetic background",
  "A bold and cinematic electronic piece featuring dramatic bass swells, sharp rhythmic patterns, and layered synth textures, evoking a suspenseful and epic tone",
  "A dynamic and energetic electronic track featuring pulsating basslines, rhythmic hi-hats, and shimmering synth arpeggios, creating a sleek and modern atmosphere",
  "A smooth and atmospheric tune with flowing ambient pads, soft sub-bass pulses, and delicate rhythmic patterns, perfect for a tranquil and reflective background",
  "A mysterious and enchanting track with shimmering zither tones, subtle frame drum rhythms, and layered ambient pads, perfect for a mystical and ethereal backdrop",
  "A reflective and mystical piece showcasing delicate celesta tones, subtle violin layers, and warm ambient pads, evoking a calm and magical mood",
  "An ethereal and magical track featuring cascading harp arpeggios, soft choral harmonies, and shimmering ambient textures, creating a serene and enchanting atmosphere",
  "A peaceful and elegant piece centered on flowing guzheng arpeggios, soft choral layers, and gentle string harmonies, evoking a serene and timeless tone",
  "A joyful and magical tune showcasing bright piccolo phrases, cascading harp lines, and rhythmic hand percussion, creating a lively and celebratory fantasy mood",
  "An adventurous and uplifting instrumental featuring bold horn melodies, rhythmic snare rolls, and flowing harp progressions, evoking a sense of heroism and wonder",
  "A dynamic and organic track featuring bold banjo melodies, subtle upright bass grooves, and rhythmic hand percussion, perfect for a lively and rustic folk background",
  "A nostalgic and soulful track with flowing fiddle solos, soft acoustic guitar chords, and gentle vocal hums, creating a timeless and emotional tone",
  "A bright and festive piece showcasing rhythmic accordion riffs, lively tambourine shakes, and steady basslines, ideal for a celebratory and uplifting folk mood",
  "A warm and heartfelt track featuring gentle acoustic guitar fingerpicking, soft fiddle harmonies, and light hand percussion, creating a cozy and nostalgic atmosphere",
  "A playful and upbeat track with rhythmic ukulele strumming, bright marimba accents, and light clap patterns, creating a fun and dynamic setting",
  "A smooth and jazzy funk track featuring mellow saxophone solos, rhythmic guitar plucks, and steady bass grooves, creating a stylish and relaxed tone",
  "A smooth and vibrant instrumental with funky clavinet patterns, deep bass grooves, and crisp hi-hat beats, perfect for a stylish and dynamic background",
  "A dynamic and upbeat piece centered on cascading brass phrases, sharp snare hits, and funky organ chords, ideal for a bold and celebratory mood",
  "A cool and rhythmic piece with flowing bass riffs, bright brass harmonies, and crisp hi-hat grooves, creating an energetic and vibrant funk soundscape",
  "A playful and rhythmic tune showcasing bright horn melodies, bouncy basslines, and steady drum patterns, evoking a cheerful and engaging funk vibe",
  "An old-school-inspired track with groovy boom-bap beats, jazzy horn samples, and smooth basslines, creating a nostalgic and stylish atmosphere",
  "A dark and cinematic track showcasing haunting basslines, sharp snare hits, and atmospheric synth textures, creating a mysterious and powerful hip-hop backdrop",
  "A high-energy instrumental with punchy kicks, sharp claps, and layered synth stabs, ideal for an intense and bold hip-hop groove",
  "A bold and dynamic hip-hop track featuring deep 808 bass, crisp snare hits, and rhythmic hi-hat rolls, creating a sleek and modern vibe",
  "A reflective and melodic track showcasing soft trap-inspired drum patterns, gentle electric piano riffs, and deep basslines, evoking a calm and introspective tone",
  "A vibrant and celebratory track with bold trumpet fanfares, rhythmic snare rolls, and dynamic string phrases, perfect for a grand and joyous holiday theme.",
  "A cozy and reflective piece with soft acoustic guitar picking, warm ambient pads, and subtle chime accents, perfect for a calm and intimate holiday mood",
  "A nostalgic and elegant track with rich orchestral strings, warm brass harmonies, and delicate piano chords, creating a timeless and heartfelt holiday tone",
  "A playful and whimsical holiday piece with bouncing marimba patterns, bright glockenspiel tones, and rhythmic claps, ideal for a fun and dynamic festive setting.",
  "A lively and joyful tune showcasing cascading harp arpeggios, playful flute phrases, and rhythmic tambourine beats, evoking a magical holiday spirit.",
  "A reflective and melodic tune featuring soft choir harmonies, warm acoustic guitar tones, and gentle sleigh bell rhythms, creating a heartfelt and intimate holiday backdrop.",
  "A cheerful and festive track featuring rhythmic sleigh bells, bright piano melodies, and gentle string harmonies, creating a warm and celebratory atmosphere",
  "A smooth and jazzy holiday tune with mellow saxophone leads, soft piano chords, and rhythmic brush drums, creating a stylish and relaxing festive vibe.",
  "A dreamy and uplifting instrumental featuring lush synth pads, clean electric guitar riffs, and dynamic drum patterns, ideal for a stylish and contemporary indie atmosphere",
  "A nostalgic and cinematic track showcasing shimmering electric guitar arpeggios, flowing string harmonies, and subtle bass grooves, perfect for a reflective and emotional tone",
  "A playful and rhythmic track featuring cascading ukulele patterns, bright marimba accents, and soft tambourine beats, ideal for a fun and creative indie mood",
  "A vibrant and melodic piece with rhythmic acoustic guitar leads, soft clapping accents, and steady drum grooves, creating an inviting and upbeat indie soundscape",
  "A vibrant and upbeat tune with rhythmic acoustic guitar strumming, dynamic drum beats, and melodic basslines, perfect for a cheerful and engaging indie vibe",
  "A mellow and introspective indie track featuring soft electric guitar riffs, warm ambient pads, and gentle drum patterns, creating a serene and heartfelt atmosphere",
  "A reflective and emotional instrumental showcasing flowing piano melodies, subtle string layers, and soft reverb-drenched electric guitar tones, evoking a nostalgic and dreamy tone",
  "A smooth and relaxing jazz track featuring mellow saxophone melodies, gentle piano chords, and soft brush drum patterns, creating a cozy and sophisticated atmosphere.",
  "A reflective and melodic tune showcasing flowing piano solos, warm double bass plucks, and subtle cymbal rolls, evoking a calm and introspective tone.",
  "A dramatic and cinematic piece with soaring trumpet solos, deep basslines, and layered string textures, creating a powerful and expansive jazz backdrop.",
  "A serene and atmospheric track with soft flute improvisations, ambient piano chords, and subtle basslines, perfect for a reflective and tranquil jazz mood.",
  "A rich and soulful tune featuring mellow trombone melodies, gentle vibraphone tones, and steady drum grooves, evoking a heartfelt and intimate jazz atmosphere.",
  "A dynamic and modern jazz tune featuring intricate guitar riffs, rhythmic drum patterns, and flowing bass grooves, evoking a stylish and contemporary tone.",
  "A festive and lively tune featuring cascading marimba patterns, rhythmic tambourine shakes, and steady acoustic guitar riffs, evoking a celebratory and inviting mood.",
  "A bold and dynamic tune centered on sharp brass stabs, cascading timbale rolls, and vibrant acoustic guitar strumming, evoking a festive and energetic tone.",
  "A nostalgic and soulful Latin instrumental with flowing violin melodies, soft guitar arpeggios, and gentle handclaps, creating a heartfelt and emotional atmosphere",
  "A playful and upbeat track with syncopated clave patterns, lively piano riffs, and rhythmic drum accents, ideal for a fun and engaging Latin soundscape.",
  "A vibrant and celebratory instrumental showcasing rhythmic bongo patterns, bright trumpet melodies, and steady bass grooves, evoking a joyful and dynamic vibe.",
  "A lively and rhythmic Latin track featuring bright acoustic guitar strumming, dynamic conga beats, and vibrant maracas, creating a festive and energetic atmosphere.",
  "A serene and warm track with soft piano progressions, gentle ambient pads, and light shaker rhythms, creating a calming and introspective lo-fi backdrop",
  "A reflective and moody piece centered on soft acoustic guitar strumming, gentle vocal hums, and subtle ambient textures, ideal for a heartfelt and calming lo-fi tone",
  "A smooth and minimal instrumental featuring delicate piano arpeggios, soft bass pulses, and shimmering synth layers, perfect for a modern and introspective lo-fi sound",
  "A mellow and reflective lo-fi track featuring warm piano chords, soft vinyl crackle textures, and slow drum loops, creating a nostalgic and introspective atmosphere",
  "A nostalgic and soothing track with flowing Rhodes piano lines, rhythmic drum patterns, and warm ambient noise, creating a timeless and intimate lo-fi atmosphere",
  "A dreamy and atmospheric tune showcasing gentle guitar plucks, layered ambient pads, and soft snare brushes, evoking a serene and tranquil lo-fi mood",
  "Smooth lounge instrumental with soft jazz piano, warm basslines, and subtle percussion, creating a relaxed and sophisticated atmosphere for cafés.",
  "Polished lounge music with warm electric piano chords, subtle synth textures, and soft, unobtrusive percussion, ideal for creating a serene atmosphere in lobbies.",
  "Light and sophisticated lounge instrumental with warm guitar tones, soft jazz-inspired chords, and a mellow groove, perfect for marketing videos.",
  "Mellow lounge track featuring gentle guitar riffs, soft ambient pads, and a light, steady rhythm, perfect for a calm and inviting retail space.",
  "Warm and polished lounge track featuring gentle piano melodies, subtle electronic pads, and a soft rhythm, creating a serene and inviting café ambiance.",
  "Mellow and calming lounge instrumental featuring soft electric piano, warm ambient textures, and a light, unobtrusive beat, creating a serene soundscape for lobbies.",
  "Sophisticated lounge music with smooth saxophone lines, subtle synth pads, and a warm bass groove, creating an elegant and relaxed atmosphere for games.",
  "Soft and polished lounge instrumental featuring gentle piano chords, warm guitar riffs, and a light, steady rhythm, perfect for creating a calm and inviting background in elevators.",
  "Bright and cheerful pop track featuring catchy synth lines, subtle bass grooves, and a light, steady rhythm, perfect for an upbeat retail environment",
  "Mellow and polished pop music with soft guitar strums, gentle electronic textures, and a warm, uplifting melody, ideal for a serene café atmosphere",
  "Light and catchy pop track featuring gentle guitar riffs, warm basslines, and soft ambient pads, perfect for a relaxed and cheerful café setting",
  "Relaxed and modern pop instrumental with soft piano chords, warm synth layers, and a light, rhythmic beat, creating a calm and inviting ambiance for lobbies",
  "Polished pop music with a smooth, melodic hook, subtle electronic textures, and a steady rhythm, ideal for creating an upbeat and engaging background in marketing videos",
  "Warm and vibrant pop track featuring bright piano melodies, soft synth pads, and a subtle, steady rhythm, perfect for a cheerful and engaging marketing video backdrop",
  "Light and catchy pop music with soft guitar riffs, warm basslines, and a polished, modern sound, creating a lively and inviting ambiance for games",
  "Relaxed and modern pop instrumental featuring bright synth melodies, subtle bass grooves, and a soft rhythmic beat, ideal for creating a cheerful and polished background in retail spaces",
  "Mellow and vibrant reggae instrumental with smooth basslines, subtle organ stabs, and a steady rhythmic groove, ideal for marketing videos",
  "Relaxed reggae instrumental with warm basslines, soft offbeat guitar strums, and light rhythmic grooves, creating a laid-back and inviting atmosphere for cafés",
  "Smooth and mellow reggae track featuring gentle keys, steady basslines, and subtle percussion, perfect for a calm and cheerful retail environment",
  "Warm and uplifting reggae music with soft guitar skanks, steady drum patterns, and a light, tropical feel, ideal for creating a welcoming ambiance in lobbies",
  "Easygoing and polished reggae track with gentle offbeat guitar chords, steady drum patterns, and soft keyboard accents, perfect for creating a tropical and inviting mood in games",
  "Uplifting and relaxed reggae instrumental featuring smooth basslines, light percussion, and soft guitar skanks, creating a warm and cheerful ambiance for lobbies",
  "Smooth and vibrant retro music with lush synth textures, soft keyboard melodies, and a steady beat, creating a relaxed and nostalgic mood for cafés.",
  "Mellow and reflective retro track with soft electric piano chords, warm synth pads, and a light rhythmic groove, ideal for creating a serene atmosphere in cafés.",
  "Polished retro instrumental featuring soft synth arpeggios, steady drum patterns, and warm pads, creating a calm and nostalgic ambiance for lobbies and elevators.",
  "Upbeat retro instrumental featuring warm analog synths, funky basslines, and a steady, simple rhythm, creating a nostalgic and inviting background for retail spaces.",
  "Funky and cheerful retro track with groovy basslines, vintage electric piano riffs, and subtle, rhythmic synth accents, perfect for a lively and welcoming retail environment.",
  "Bright and lively retro music with vintage synth melodies, smooth basslines, and subtle percussion, evoking a cheerful and nostalgic feel for marketing videos.",
  "Upbeat retro instrumental featuring light disco-inspired basslines, warm synth pads, and a simple, inviting rhythm, ideal for creating a cheerful background in games.",
  "Warm and inviting retro instrumental featuring vintage synth leads, groovy basslines, and a steady rhythmic groove, creating a nostalgic atmosphere for elevators and marketing videos.",
  "A retro-inspired rock tune featuring crunchy guitar riffs, steady snare beats, and warm organ layers, perfect for a nostalgic and dynamic atmosphere.",
  "A vibrant and upbeat rock instrumental with sharp guitar chords, steady bass grooves, and rhythmic snare patterns, perfect for a lively and energetic setting.",
  "A playful and dynamic rock track with rhythmic guitar plucks, crisp hi-hats, and groovy bass riffs, perfect for creating a lively and upbeat mood.",
  "A dramatic and intense rock tune featuring soaring electric guitar solos, pulsing basslines, and dynamic cymbal crashes, ideal for creating a powerful and impactful background.",
  "A polished and melodic rock instrumental featuring clean guitar strumming, rhythmic tambourine beats, and warm bass tones, perfect for an upbeat and inviting mood.",
  "A gritty and raw rock track with heavy guitar distortion, steady drum rolls, and deep bass undertones, evoking a rebellious and edgy vibe.",
  "A high-energy rock track featuring distorted electric guitar riffs, driving basslines, and powerful drum beats, creating a bold and dynamic atmosphere.",
  "A smooth and rhythmic rock track with mellow electric guitar leads, soft drum grooves, and deep bass undertones, creating a laid-back yet energetic vibe.",
  "An anthemic rock instrumental featuring soaring guitar harmonies, bold basslines, and steady drumbeats, ideal for creating a powerful and motivational background.",
  "A chilled and groovy instrumental with a jazzy electric guitar motif, deep basslines, and light cymbal accents, ideal for a laid-back and soulful mood",
  "A relaxed and atmospheric R&B piece with a flowing saxophone melody, smooth bass grooves, and soft drum rolls, creating an elegant and soothing background",
  "A tender and melodic R&B instrumental with soft guitar riffs, gentle vocal harmonies, and a slow, steady beat, perfect for creating a romantic and stylish vibe",
  "A bright and rhythmic R&B tune featuring smooth synth textures, vibrant vocal pads, and crisp snare beats, creating a dynamic and upbeat atmosphere",
  "A polished and uplifting R&B track with vibrant brass accents, rhythmic keyboard chords, and a groovy drum beat, creating a stylish and energetic mood",
  "A dreamy and emotional R&B track featuring a warm piano lead, lush string layers, and rhythmic percussion, perfect for creating a heartfelt and reflective tone",
  "A warm and introspective piece with a soft acoustic guitar melody, mellow basslines, and subtle drum loops, perfect for an intimate and soulful vibe",
  "A groovy and vibrant R&B tune featuring sharp brass stabs, rhythmic hi-hats, and warm synth pads, ideal for an upbeat and dynamic mood",
  "A lush and immersive shoegaze track featuring layered, reverb-drenched electric guitar riffs, dreamy synth pads, and subtle basslines, creating a hazy and introspective atmosphere.",
  "A hazy and dynamic instrumental with swirling guitar harmonics, pulsing bass grooves, and airy ambient textures, evoking a sense of introspection and nostalgia",
  "A nostalgic and reflective shoegaze tune featuring warm electric guitar chords, shimmering synth textures, and a steady drum beat, ideal for creating an emotional and immersive vibe.",
  "A dreamy and expansive track featuring layered guitars with tremolo effects, soft choral pads, and steady percussion, creating a serene and immersive background.",
  "A melancholic and immersive shoegaze piece with a flowing synth lead, shimmering guitar arpeggios, and soft drum loops, perfect for a textured and reflective mood.",
  "A tranquil and atmospheric shoegaze tune with flowing electric piano chords, warm basslines, and shimmering guitar melodies, perfect for an ethereal and introspective setting.",
  "A serene and emotional track featuring reverb-soaked electric guitars, lush string layers, and ambient textures, creating an evocative and dreamy shoegaze background.",
  "A textured and atmospheric shoegaze piece with cascading guitar melodies, ethereal ambient layers, and soft drum patterns, perfect for creating a dreamy and melancholic mood.",
  "A soothing and elegant soul piece with soft acoustic guitar picking, flowing piano chords, and subtle choral pads, creating a calm and inviting atmosphere",
  "A mellow and timeless instrumental with gentle vocal harmonies, jazzy electric piano progressions, and light tambourine accents, perfect for creating a soulful and nostalgic setting",
  "A smooth and introspective soul tune with slow electric guitar riffs, gentle vocal layers, and rich keyboard textures, creating a serene and emotional mood",
  "A groovy and polished soul track featuring rhythmic guitar strums, smooth keyboard riffs, and steady drum beats, ideal for a stylish and soulful mood",
  "A smooth and emotional soul track featuring warm electric piano chords, gentle basslines, and a soft drum groove, creating a tender and heartfelt atmosphere",
  "A classic and uplifting soul instrumental with vibrant brass accents, flowing organ harmonies, and rhythmic handclaps, perfect for an energetic and soulful vibe",
  "A high-energy and rhythmic swing tune with cascading vibraphone tones, vibrant brass harmonies, and crisp drum grooves, creating a lively and engaging backdrop",
  "A playful and jazzy swing tune with flowing clarinet melodies, vibrant trumpet harmonies, and rhythmic piano comping, perfect for a dynamic and stylish mood",
  "A vibrant and cheerful swing instrumental with sharp trumpet stabs, rhythmic piano riffs, and a steady walking bassline, creating a playful and vintage mood",
  "A lively and upbeat swing track featuring rhythmic brass stabs, walking basslines, and crisp drum patterns with a steady hi-hat groove, creating an energetic and vintage atmosphere",
  "A bright and sophisticated swing piece with flowing violin phrases, rhythmic upright bass, and gentle cymbal splashes, ideal for creating a classy and playful mood",
  "A spirited and rhythmic swing track with cascading piano arpeggios, sharp trombone accents, and dynamic drum fills, evoking a cheerful and vibrant vibe",
  "A dynamic and energetic techno tune with sharp percussion loops, resonant bass drops, and shimmering synth pads, perfect for a high-energy and immersive vibe",
  "A high-energy and driving techno piece with rhythmic kick drums, shimmering electronic textures, and layered arpeggiated synths, creating a fast-paced and immersive atmosphere",
  "A driving techno track featuring deep pulsing basslines, rhythmic hi-hats, and layered synth arpeggios, creating a futuristic and hypnotic atmosphere",
  "An uplifting and spirited instrumental with rhythmic bongos, bright kalimba tones, and warm acoustic guitar motifs, perfect for creating a light and inspiring world atmosphere",
  "A tranquil and soothing world tune with a delicate oud melody, gentle tambourine patterns, and soft string harmonies, evoking a sense of peace and cultural depth",
  "A dynamic and uplifting world piece with flowing pan flute melodies, rhythmic hand percussion, and soft acoustic guitar accents, perfect for an inspiring and global vibe",
  "An enchanting and reflective world instrumental with soft erhu melodies, flowing bamboo flute accents, and subtle ambient pads, creating a serene and introspective mood",
  "A lively and celebratory track featuring rhythmic steel drum patterns, bright marimba tones, and groovy basslines, perfect for creating a joyful and tropical atmosphere",
  "A radiant and hopeful instrumental with cascading harp motifs, tender flute lines, and deep ambient textures, ideal for a serene and inspiring worship experience",
  "A heartfelt and tranquil tune with slow piano progressions, soaring vocal harmonies, and light percussion, evoking a sense of spiritual connection and peace",
  "A graceful and reflective instrumental with shimmering harp tones, delicate piano arpeggios, and light ambient pads, perfect for a worshipful and serene mood",
  "A majestic and reverent worship piece featuring layered orchestral strings, flowing harp arpeggios, and subtle choir accents, creating a sacred and uplifting tone.",
  "An emotional and reflective track with warm acoustic guitar strums, gentle piano melodies, and soft choral pads, perfect for creating an intimate and worshipful setting.",
  "A serene and inspiring instrumental with gentle acoustic guitar picking, soft ambient pads, and a tender violin melody, perfect for a worshipful and reflective mood.",
  "A peaceful and uplifting worship track featuring flowing piano chords, warm string harmonies, and subtle choral layers, creating a reverent and spiritual atmosphere.",
  "A tranquil and heartfelt worship tune with gentle acoustic guitar leads, soft cello harmonies, and warm choral layers, creating an intimate and spiritual atmosphere",
  "An uplifting and melodic track featuring rhythmic piano progressions, flowing violin phrases, and subtle percussion, evoking a sense of joy and spiritual unity.",
  "A smooth and jazzy tune with light acoustic drum brushes, soft ride cymbal tones, and a steady bass drum groove, creating an elegant and relaxed mood",
  "A light and playful track with soft acoustic drum taps, gentle hi-hat splashes, and steady kick patterns, creating a fun and breezy atmosphere",
  "A mellow and reflective piece featuring soft acoustic drum brushes, subtle hi-hat textures, and steady bass drum pulses, creating a calm and introspective mood",
  "A gentle and melodic instrumental featuring flowing acoustic guitar arpeggios, with its delicate overtones and textured strings taking the spotlight, ideal for a reflective background",
  "A lively and rhythmic piece emphasizing bright acoustic guitar strumming, complemented by its warm tones and natural reverb, perfect for an uplifting and dynamic mood",
  "A breezy track showcasing rhythmic acoustic guitar strums, with crisp and clear chords creating a light and joyful setting for relaxed moments",
  "A serene and heartfelt track centered on intricate acoustic guitar fingerpicking, with subtle harmonics and soft resonance, creating an introspective and calming atmosphere",
  "A soothing and intimate tune focused on slow and resonant acoustic guitar picking, with its soft tones evoking a thoughtful and tranquil ambiance",
  "A peaceful and melodic piece highlighting tender acoustic guitar melodies, with its nuanced tones and fingerpicking providing a soothing and elegant mood",
  "A rustic and charming piece showcasing cascading banjo arpeggios, with its bright and textured sound taking center stage for a natural and inviting mood.",
  "A mellow and reflective piece highlighting gentle banjo strumming, with its warm resonance creating a calm and introspective mood.",
  "A smooth and easygoing instrumental focused on soft banjo fingerpicking, with its intricate patterns creating a relaxed and soothing ambiance",
  "A serene and heartfelt tune with delicate banjo picking, where its soft harmonics and resonant tones evoke a tranquil and nostalgic setting.",
  "A lively and upbeat track centered on rhythmic banjo picking, with its crisp plucking and resonant tones creating a bright and cheerful atmosphere.",
  "A smooth and jazzy track featuring a flowing bassline, with its subtle harmonics and intricate patterns creating an elegant and laid-back vibe.",
  "A mellow and reflective tune with soft, melodic bass plucks, emphasizing its warm and rounded tones for a calm and introspective mood.",
  "A modern and atmospheric piece with a flowing bassline, highlighting its clean tones and rhythmic foundation to create a balanced and immersive mood.",
  "A vibrant and rhythmic tune with funky slap bass riffs, showcasing its dynamic textures and lively groove for an engaging and spirited setting.",
  "A serene and soothing piece with slow, fingerpicked bass notes, emphasizing its gentle harmonics and steady rhythm for a tranquil and balanced background.",
  "A reflective and melodic instrumental featuring warm, sustained bass notes, creating a smooth and heartfelt atmosphere with a focus on tonal depth.",
  "A reflective and soothing piece centered on gentle bell arpeggios, with its clear and resonant tones creating a calm and introspective setting",
  "A calming and introspective piece featuring soft, flowing bell arpeggios, with warm ambient pads creating a serene and reflective tone",
  "A tranquil and elegant piece with slow bell chimes, layered with subtle string harmonies, creating a serene and meditative atmosphere.",
  "A whimsical and airy instrumental featuring delicate bell motifs, accompanied by light harp accents and ambient textures, ideal for a dreamy and uplifting tone.",
  "A joyful and engaging instrumental with rhythmic bell patterns, complemented by soft ukulele strums and gentle drum rolls, evoking a lighthearted and playful setting.",
  "A festive and uplifting tune centered on bright bell melodies, supported by soft acoustic textures and rhythmic tambourine beats, perfect for a celebratory mood.",
  "A festive and celebratory track centered on bright trumpet fanfares, rhythmic trombone grooves, and light cymbal splashes, ideal for a joyful and uplifting mood.",
  "A regal and majestic tune centered on soaring horn melodies, complemented by subtle timpani rolls and warm string textures, perfect for an inspiring and grand mood.",
  "A triumphant and uplifting track with bold trumpet leads, flowing horn harmonies, and rhythmic percussion, creating a grand and celebratory tone.",
  "A dark and moody piece featuring deep tuba tones, subtle horn harmonies, and ambient textures, evoking a shadowy and intense background.",
  "A cinematic and powerful instrumental with dramatic brass swells, sharp trumpet accents, and steady bass pulses, perfect for creating a suspenseful and heroic atmosphere.",
  "A bold and energetic track featuring a dynamic brass section with sharp trumpet stabs and smooth trombone harmonies, creating a vibrant and commanding atmosphere.",
  "A playful and lively track with rhythmic claps driving the groove, accented by soft snaps and light acoustic guitar strums, creating an upbeat and inviting vibe.",
  "A minimal and modern track centered on crisp claps, supported by subtle vocal hums and ambient pads, creating a clean and rhythmic backdrop.",
  "A reflective and gentle tune with slow, deliberate claps, subtle acoustic textures, and soft ambient pads, creating a calming and introspective atmosphere.",
  "A minimal and focused piece showcasing crisp hand claps in a steady rhythm, supported by subtle ambient textures, evoking a simple yet dynamic background.",
  "A reflective and atmospheric tune featuring slow drum beats, soft rim clicks, and shimmering cymbal rolls, evoking a tranquil and introspective mood",
  "A smooth and relaxed track showcasing soft drum brushes, gentle kick pulses, and light cymbal swells, perfect for a laid-back and introspective setting",
  "A modern and dynamic track with layered drum patterns, subtle electronic textures, and sharp cymbal splashes, perfect for creating a bold and contemporary tone",
  "A minimal and focused track highlighting steady drum beats with soft rimshots, subtle tom fills, and rhythmic hand percussion, creating a simple yet dynamic backdrop",
  "A nostalgic and retro-inspired track featuring crunchy electric guitar chords, steady bass grooves, and light tambourine beats, evoking a vintage and dynamic feel.",
  "An emotional and powerful track showcasing soaring electric guitar solos, complemented by warm ambient pads and steady drum beats, creating a dramatic and inspiring tone",
  "A lively and energetic instrumental with rhythmic electric guitar riffs, driving basslines, and sharp cymbal crashes, perfect for creating a bold and upbeat background",
  "A modern and polished track featuring clean electric guitar rhythms, subtle electronic textures, and dynamic percussion, perfect for a stylish and contemporary tone",
  "A mellow and reflective tune with slow electric guitar arpeggios, soft ambient layers, and light snare rolls, ideal for a calm and thoughtful setting",
  "A bold and dynamic track centered on distorted electric guitar riffs, layered with rhythmic basslines and steady drum grooves, creating a powerful and energetic atmosphere",
  "A powerful and edgy tune with fast-paced electric guitar riffs, thunderous drum fills, and deep bass pulses, ideal for a high-energy and intense atmosphere",
  "A smooth and melodic instrumental featuring clean electric guitar leads, soft reverb textures, and gentle bass undertones, perfect for a serene and introspective mood",
  "A serene and atmospheric piece with soft electric guitar fingerpicking, shimmering synth pads, and gentle drum patterns, creating a tranquil and immersive mood",
  "A minimal and focused piece showcasing repetitive electric drum loops with soft rim clicks, layered with subtle ambient textures, creating a hypnotic and dynamic mood",
  "A smooth and laid-back piece with soft electric drum patterns, gentle clap accents, and flowing synth textures, perfect for a chill and modern atmosphere",
  "A bold and dynamic track centered on crisp electric drum beats, pulsing basslines, and sharp hi-hat rolls, creating a driving and energetic atmosphere",
  "An atmospheric and moody track with deep electric drum thumps, rhythmic hi-hat patterns, and soft ambient drones, evoking a futuristic and introspective tone",
  "A lively and upbeat tune featuring syncopated electric drum beats, bright snare accents, and pulsing sub-bass, creating a dynamic and vibrant setting",
  "A futuristic and immersive piece with layered electric drum loops, pulsing basslines, and shimmering ambient effects, creating a modern and captivating background",
  "A serene and reflective piece with soft sitar plucking, flowing tabla rhythms, and gentle ambient pads, evoking the meditative essence of South Asian traditions",
  "A tranquil and atmospheric track centered on a haunting duduk melody, complemented by soft frame drum beats and ambient synth layers, creating a mysterious and evocative tone.",
  "A peaceful and elegant instrumental with flowing guzheng arpeggios, subtle dizi flute accents, and ambient string layers, capturing the delicate beauty of Chinese music",
  "A rich and earthy track with deep taiko drum rhythms, subtle koto plucks, and flowing shakuhachi flute lines, perfect for creating an intense and dramatic East Asian mood.",
  "A vibrant and uplifting piece with bright electric piano arpeggios, rhythmic synth layers, and soft basslines, perfect for a dynamic and modern background",
  "A nostalgic and retro-inspired piece featuring rhythmic synth keys, pulsing basslines, and crisp drum patterns, evoking a vintage and dynamic atmosphere",
  "A mellow and jazzy tune with flowing Rhodes piano melodies, subtle cymbal splashes, and a steady bass groove, creating an elegant and stylish vibe",
  "A serene and elegant track with soft piano arpeggios, subtle vibraphone accents, and shimmering pads, creating a calm and reflective setting",
  "A calming and atmospheric piece with delicate celesta tones, soft harp layers, and warm ambient textures, creating a dreamy and magical soundscape",
  "A smooth and reflective track featuring gentle piano chords, warm ambient textures, and subtle string harmonies, creating a serene and introspective atmosphere",
  "A dramatic and emotional track centered on cascading grand piano arpeggios, complemented by light ambient pads and soft choral accents, evoking a heartfelt and cinematic mood",
  "A tranquil and heartfelt tune with slow Wurlitzer electric piano progressions, gentle violin harmonies, and subtle ambient drones, creating a peaceful and emotive background",
  "A calm and elegant tune featuring soft ukulele arpeggios, gentle harp layers, and warm ambient textures, ideal for a relaxed and reflective mood",
  "A warm and inviting tune with delicate mandolin picking, gentle acoustic guitar harmonies, and light percussion, perfect for a serene and calming background",
  "A lively and energetic track with cascading mandolin riffs, rhythmic ukulele strums, and soft drum beats, perfect for creating a dynamic and organic atmosphere",
  "A vibrant and breezy instrumental featuring lively ukulele patterns, rhythmic snaps, and soft marimba accents, evoking a sunny and joyful vibe",
  "A soothing and nostalgic instrumental featuring flowing ukulele melodies, warm basslines, and gentle string textures, creating a serene and heartfelt tone",
  "A tranquil and heartwarming instrumental featuring delicate ukulele picking, soft mandolin melodies, and light ambient pads, perfect for a cozy and intimate background",
  "A serene and reflective orchestral piece with delicate string harmonies, soft woodwind accents, and gentle harp arpeggios, evoking a tranquil and heartfelt mood.",
  "A vibrant and uplifting orchestral tune with cascading piano arpeggios, flowing flute lines, and warm cello harmonies, ideal for creating a cheerful and elegant background.",
  "A lively and playful orchestral track with rhythmic pizzicato strings, bright flute melodies, and light percussion, perfect for a whimsical and dynamic setting.",
  "An emotional and sweeping piece featuring rich orchestral strings, subtle horn layers, and tender oboe melodies, evoking a sense of longing and introspection.",
  "A heroic and triumphant orchestral piece featuring bold trumpet leads, layered violin harmonies, and rhythmic snare rolls, evoking a sense of victory and grandeur.",
  "A majestic orchestral track featuring soaring violin melodies, powerful brass swells, and rhythmic timpani rolls, creating a grand and inspiring atmosphere.",
  "A dramatic and powerful instrumental showcasing bold brass fanfares, dynamic string ostinatos, and thunderous percussion, perfect for an intense and cinematic setting",
  "An ethereal and expansive tune with cascading pad harmonics, light ambient textures, and slow, evolving layers, perfect for a dreamy and atmospheric setting",
  "A moody and immersive track showcasing dark, evolving pads, resonant bass drones, and subtle rhythmic pulses, ideal for creating a mysterious and cinematic tone.",
  "A futuristic and sleek piece featuring shimmering synth pads, pulsing basslines, and subtle percussive accents, creating a clean and modern background.",
  "A reflective and soothing instrumental with flowing pad textures, soft choral layers, and gentle melodic movements, evoking a calm and meditative atmosphere.",
  "A tranquil and reflective track with warm, resonant pad tones, soft ambient noise, and delicate harmonic overtones, ideal for a serene and introspective vibe.",
  "A vibrant and uplifting piece featuring bright synth pads, subtle arpeggiated layers, and warm bass undertones, perfect for a dynamic and modern background",
  "A nostalgic and warm track with flowing piano melodies, soft choral pads, and delicate harp accents, evoking a heartfelt and intimate mood.",
  "A peaceful and atmospheric instrumental with soft piano progressions, shimmering vibraphone accents, and gentle ambient pads, creating a soothing and reflective setting.",
  "A serene and heartfelt track centered on gentle piano arpeggios, complemented by soft ambient textures, creating a calm and introspective atmosphere",
  "A vibrant and uplifting piece featuring bright and rhythmic piano chords, layered with subtle string harmonies, perfect for a joyful and energetic mood.",
  "A modern and sleek piece centered on minimalist piano patterns, subtle electronic textures, and pulsing ambient layers, ideal for a stylish and contemporary tone.",
  "A dramatic and cinematic track showcasing powerful piano progressions, accented by bold string swells and gentle percussion, ideal for a grand and inspiring setting",
  "A reflective and emotional tune with cascading piano melodies, soft reverb, and subtle bass undertones, evoking a tranquil and meditative tone.",
  "A reflective and emotional piece with a warm saxophone solo, soft ambient pads, and delicate guitar picking, evoking a calm and introspective tone",
  "A smooth and elegant tune centered on a mellow saxophone melody, warm Rhodes piano chords, and subtle cymbal splashes, creating a laid-back and sophisticated vibe",
  "A vibrant and jazzy instrumental featuring bold saxophone riffs, rhythmic drum patterns, and subtle double bass plucks, perfect for a lively and stylish mood",
  "A smooth and soulful track centered on a flowing saxophone melody, supported by gentle piano chords and soft basslines, creating a relaxed and intimate atmosphere",
  "A playful and rhythmic track with syncopated saxophone phrases, funky electric guitar strums, and steady drum grooves, creating a vibrant and engaging atmosphere",
  "A nostalgic and cinematic tune featuring a heartfelt saxophone lead, subtle string harmonies, and slow piano arpeggios, perfect for a dramatic and evocative setting",
  "A mysterious and atmospheric piece with slow ney flute phrases, subtle frame drum rhythms, and shimmering ambient textures, perfect for a shadowy and expansive backdrop",
  "A nostalgic and elegant track showcasing a warm oboe melody, supported by flowing piano chords and soft violin harmonies, evoking a heartfelt and intimate tone",
  "A dynamic and cinematic track centered on bold horn melodies, deep timpani rolls, and flowing string swells, evoking a sense of grandeur and intensity",
  "A serene and meditative track centered on a flowing shakuhachi melody, supported by soft ambient pads and subtle string harmonies, creating a tranquil and introspective atmosphere",
  "A lively and festive track featuring rhythmic zurna phrases, sharp tambourine beats, and deep bass grooves, perfect for a celebratory and cultural setting",
  "A bright and uplifting instrumental featuring vibrant piccolo melodies, rhythmic claps, and soft marimba accents, creating a playful and energetic mood",
  "A rich and cinematic piece with bold zither tones, warm orchestral strings, and deep timpani rolls, perfect for an expansive and powerful setting",
  "A soothing and atmospheric instrumental featuring soft charango picking, gentle hand percussion, and warm ambient layers, creating a calm and introspective background",
  "A nostalgic and elegant piece centered on flowing harp-like kantele arpeggios, layered with soft choral pads and light bell accents, evoking a serene and reflective mood",
  "A peaceful and melodic tune with slow yangqin runs, soft flute harmonies, and subtle hand drum rhythms, creating a tranquil and ethereal atmosphere",
  "An energetic and dynamic track featuring rhythmic violin pizzicatos, sharp double bass plucks, and subtle cymbal rolls, creating a playful and lively soundscape",
  "A tranquil and reflective track centered on gentle guzheng arpeggios, supported by soft ambient pads and subtle flute harmonies, creating a serene and meditative atmosphere",
  "A nostalgic and retro-inspired piece with rhythmic analog synth patterns, pulsing basslines, and light drum machine beats, perfect for a vintage and dynamic setting",
  "A smooth and ambient tune with warm synth pads, flowing arpeggios, and subtle reverb, perfect for a serene and introspective mood",
  "A dramatic and cinematic track centered on powerful synth swells, rhythmic basslines, and shimmering ambient layers, evoking an intense and expansive tone.",
  "A minimal and elegant piece with subtle synth melodies, soft ambient drones, and slow rhythmic patterns, perfect for a calm and meditative setting",
  "A nostalgic and soulful tune with heartfelt trumpet solos, warm Rhodes piano layers, and soft bass undertones, creating a timeless and emotional atmosphere",
  "A festive and triumphant track showcasing bold trumpet fanfares, rhythmic snare rolls, and dynamic horn harmonies, evoking a sense of celebration and grandeur.",
  "A dramatic and cinematic piece showcasing soaring trumpet melodies, accented by rich string harmonies and deep timpani rolls, evoking an epic and inspiring tone.",
  "A serene and reflective track with soft, flowing trumpet phrases, subtle ambient textures, and gentle acoustic guitar picking, ideal for a calm and introspective mood",
  "A dramatic and cinematic track with resonant upright bass swells, subtle string harmonies, and deep bass drum rolls, evoking an intense and emotional soundscape",
  "A reflective and melodic piece with warm upright bass arpeggios, gentle guitar harmonics, and soft ambient textures, perfect for a calm and introspective setting.",
  "A smooth and jazzy track centered on a walking upright bassline, supported by soft brush drum patterns and light piano chords, creating a relaxed and stylish atmosphere.",
  "A minimalist and atmospheric tune with soft upright bass patterns, delicate ambient pads, and subtle reverb effects, ideal for a serene and reflective tone.",
  "A serene and heartfelt track centered on flowing violin melodies, complemented by soft piano chords and gentle ambient pads, creating a tranquil and introspective atmosphere",
  "A smooth and atmospheric tune featuring flowing violin improvisations, warm ambient textures, and subtle piano layers, perfect for a serene and meditative tone.",
  "A bold and cinematic piece featuring soaring violin phrases, dynamic string harmonies, and deep timpani rolls, evoking an epic and dramatic tone.",
  "A nostalgic and elegant piece with rich violin harmonies, soft harp arpeggios, and gentle string swells, evoking a timeless and romantic mood.",
  "A lively and playful track showcasing rhythmic violin pizzicatos, bright flute accents, and light percussion, creating a vibrant and engaging setting.",
  "A tranquil and reflective tune with slow violin legato phrases, delicate harp tones, and shimmering choral layers, creating a peaceful and introspective atmosphere.",
  "A rich and expansive track with bold woodwind harmonies, resonant bass clarinet lines, and shimmering choral textures, evoking a grand and cinematic soundscape",
  "A playful and whimsical track featuring cascading piccolo runs, light xylophone tones, and soft clapping accents, ideal for a fun and energetic setting",
  "A serene and reflective track centered on a flowing flute melody, supported by soft harp arpeggios and gentle ambient pads, creating a tranquil and introspective atmosphere.",
  "A smooth and elegant instrumental with flowing alto saxophone leads, warm ambient layers, and gentle piano harmonies, creating a sophisticated and stylish vibe.",
  "A nostalgic and heartfelt instrumental with a tender oboe solo, subtle piano chords, and warm string harmonies, evoking a timeless and emotional tone",
  "A bold and cinematic tune showcasing dramatic bassoon lines, deep string textures, and rhythmic drum rolls, creating an intense and powerful backdrop.",
];
