import React from "react";
import BaseCard from "./BaseCard";
import correct from "../.././../assets/images/purplecorrect.png";

const BusinessCard = ({ isAnnual }) => (
  <BaseCard
    tier="Business"
    monthlyPrice="$30"
    annualPrice="$330"
    period="Monthly"
    showSavings={true}
    features={[
      isAnnual
        ? "1440 minutes of royalty-free music"
        : "120 minutes of royalty-free music",
      "Unlimited downloads",
      "Access to text, image, video & audio to music capabilities",
      "For commercial usage",
      "Music editing & extension",
      "Song templates",
    ]}
    additionalInfo={
      isAnnual
        ? "$330 for each additional member"
        : "$30 for each additional member"}
    
    isAnnual={isAnnual}
    bgStyle="linear-gradient(135deg, rgba(252, 16, 242, 0.1) 0%, rgba(48, 120, 228, 0.1) 100%)" // Custom background for BusinessCard
    cardStyle={{
      border: "1px solid var(--Color-Light-Opacity-White-10, #FFFFFF1A)",
    }}
    path={correct}
  />
);

export default BusinessCard;
