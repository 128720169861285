import {
  CircleExclamation,
  Copy,
  Envelope,
  Link,
  PencilToLine,
  PersonXmark,
  Persons,
  Plus,
  Xmark,
} from "@gravity-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useAuth } from "../../contexts/AuthContext";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import Dropdown from "../generic_components/Dropdown";
import Toast from "../generic_components/Toast";
import Modal from "../modal/Modal";
import TeamPurchaseSummary from "./TeamPurchaseSummary";

// Replace with your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TeamMembers = () => {
  const posthog = usePostHog();
  const [teamMembers, setTeamMembers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamLogo, setTeamLogo] = useState("");
  const [teamStripeSubscriptionId, setTeamStripeSubscriptionId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editMode, setEditMode] = useState(false); // Toggle for edit mode
  const [showActions, setShowActions] = useState(null); // To handle action visibility for each member
  const { isLoggedIn } = useAuth();
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showMakeAdminModal, setShowMakeAdminModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { localState, updateLocalState } = useLocalState();
  const [toastType, setToastType] = useState("info");
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [publicInviteLink, setPublicInviteLink] = useState("");
  const [isLinkEnabled, setIsLinkEnabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openTeamPurchaseModal, setOpenTeamPurchaseModal] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState({
    adminEmail: "",
    invitedEmails: [],
  });

  useEffect(() => {
    if (isLoggedIn) {
      fetchTeamData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (localState.userRole) {
        setUserRole(localState.userRole);
      } else if (teamId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/checkUserRole?team_id=${teamId}`,
            {
              headers: {
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          const data = await response.json();
          setUserRole(data.role);
          updateLocalState("userRole", data.role);
          updateLocalState("isAdmin", userRole === "Admin");
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    };

    fetchUserRole();
  }, [teamId]);

  const handleMakeAdmin = (member) => {
    posthog.capture("Click on Make Admin button");
    setSelectedMember(member);
    setShowMakeAdminModal(true);
  };

  const confirmMakeAdmin = async () => {
    posthog.capture("Click on Confirm Make Admin button");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/makeUserAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({
            user_id: selectedMember.user_id,
            team_id: teamId,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        // Update the local state to reflect the change
        const updatedMembers = teamMembers.map((member) =>
          member.user_id === selectedMember.user_id
            ? { ...member, role: "Admin" }
            : member
        );
        setTeamMembers(updatedMembers);
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          teamMembers: updatedMembers,
        });
        setToastMessage("Admin privileges granted successfully");
        setToastType("success");
      }
    } catch (error) {
      console.error("Error making user admin:", error);
      setToastMessage("Failed to grant admin privileges");
      setToastType("error");
    }
    setShowMakeAdminModal(false);
  };

  const handleRemoveAdmin = (member) => {
    posthog.capture("Click on Remove Admin button");
    setSelectedMember(member);
    setShowMakeAdminModal(true);
  };

  const confirmRemoveAdmin = async () => {
    posthog.capture("Click on Confirm Remove Admin button");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/removeUserAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({
            user_id: selectedMember.user_id,
            team_id: teamId,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        const updatedMembers = teamMembers.map((member) =>
          member.user_id === selectedMember.user_id
            ? { ...member, role: "Member" }
            : member
        );
        setTeamMembers(updatedMembers);
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          teamMembers: updatedMembers,
        });
        setToastMessage(
          `Admin privileges removed from ${selectedMember.email}`
        );
        setToastType("success");
      } else {
        console.error("Error removing user as admin:", result.error);
        setToastMessage("Error removing user as admin:", result.error);
        setToastType("error");
      }
    } catch (error) {
      console.error("Error removing user as admin:", error);
      setToastMessage("Error removing user as admin:", error);
      setToastType("error");
    }
    setShowMakeAdminModal(false);
  };

  const fetchTeamData = async () => {
    try {
      if (localState.adminSettings) {
        const { teamMembers, invitedUsers, teamDetails, publicInvitationLink } =
          localState.adminSettings;
        setTeamMembers(teamMembers);
        setInvitedUsers(invitedUsers);
        setTeamName(teamDetails.name);
        setTeamDescription(teamDetails.description);
        setTeamId(teamDetails.team_id);
        setTeamStripeSubscriptionId(teamDetails.stripe_subscription_id);
        setTeamLogo(teamDetails.logo);
        setPublicInviteLink(publicInvitationLink || ""); // Set the public invite link
      } else {
        const response = await apiService.handleProtectedRoute(
          "fetchAdminSettings",
          {}
        );

        if (response.success) {
          const uniqueInvitedUsers = Array.from(
            new Set(response.invitedUsers.map((user) => user.email))
          ).map((email) =>
            response.invitedUsers.find((user) => user.email === email)
          );

          const adminSettingsData = {
            ...response,
            publicInvitationLink:
              response.inviteToken && response.publicInvitationLink
                ? response.publicInvitationLink
                : "",
            invitedUsers: uniqueInvitedUsers,
          };
          updateLocalState("adminSettings", adminSettingsData);

          setTeamMembers(response.teamMembers);
          setInvitedUsers(uniqueInvitedUsers);
          setTeamName(response.teamDetails.name);
          setTeamStripeSubscriptionId(
            response.teamDetails.stripe_subscription_id
          );
          setTeamDescription(response.teamDetails.description);
          setTeamId(response.teamDetails.team_id);
          setTeamLogo(response.teamDetails.logo);
          const inviteLink =
            response.inviteToken && response.publicInvitationLink
              ? response.publicInvitationLink
              : "";
          setPublicInviteLink(inviteLink);
        } else {
          setToastMessage(response.message || "Failed to fetch team data.");
          setToastType("error");
        }
      }
    } catch (error) {
      console.error("Error fetching team data:", error.message);
      setToastMessage("Error fetching team data");
      setToastType("error");
    }
  };

  const handleRemoveMember = (member) => {
    posthog.capture("Click on Remove member icon button");
    setSelectedMember(member);
    setShowRemoveModal(true);
  };

  const confirmRemoveMember = async () => {
    posthog.capture("Click on Confirm Remove Member button");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/removeUserFromTeam`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({
            team_id: teamId,
            user_id: selectedMember.user_id,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setTeamMembers((prevMembers) => {
          const updatedMembers = prevMembers.filter(
            (member) => member.user_id !== selectedMember.user_id
          );
          updateLocalState("adminSettings", {
            ...localState.adminSettings,
            teamMembers: updatedMembers,
          });
          return updatedMembers;
        });
        setToastMessage(`Removed ${selectedMember.email} from team`);
        setToastType("success");
      } else {
        console.error("Error removing user from team:", result?.error);
        setToastMessage("Error removing user from team:", result?.error);
        setToastType("error");
      }
    } catch (error) {
      console.error("Error removing user from team:", error);
      setToastMessage("Error removing user from team:", error);
      setToastType("error");
    }
    setShowRemoveModal(false);
  };

  const handleDeleteOrganization = () => {
    posthog.capture("Click on Delete Team button");
    setShowDeleteModal(true);
  };

  const confirmDeleteOrganization = async () => {
    posthog.capture("Click to Confirm Deleting Team");
    if (!selectedReason || (selectedReason === "Others" && !customReason)) {
      setToastMessage("Please select a reason for deletion.");
      setToastType("error");
      return;
    }

    if (confirmText !== "CONFIRM DELETE") {
      setToastMessage("Please type 'CONFIRM DELETE' to proceed.");
      setToastType("error");
      return;
    }

    try {
      const reason =
        selectedReason === "Others" ? customReason : selectedReason;

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/deleteOrganization`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({ team_id: teamId, reason }),
        }
      );

      const result = await response.json();

      if (result.success) {
        // Redirect to home page
        setToastMessage("Team deleted successfully");
        setToastType("success");
        setTimeout(() => {
          navigate("/");
          navigate(0);
          updateLocalState("isTeamMember", null);
          updateLocalState("userRole", null);
          updateLocalState("adminSettings", null);
        }, 1000);
      } else {
        console.error("Error deleting team account:", result.error);
      }
    } catch (error) {
      console.error("Error deleting team account:", error);
    }
    setShowDeleteModal(false);
  };

  // Function to handle edit mode
  const handleEditClick = () => {
    setEditMode(!editMode); // Toggle edit mode
  };

  // Function to show/hide action buttons for a member
  const handleShowActions = (index) => {
    setShowActions(showActions === index ? null : index); // Toggle action buttons visibility
  };

  const handleAddMember = async (email) => {
    posthog.capture("Click to Add Invite icon button after entering email");
    if (email && !invitedUsers.some((user) => user.email === email)) {
      const currentUserEmail = await checkCurrentUserEmail();
      if (currentUserEmail && email === currentUserEmail) {
        setEmailError("You cannot invite yourself");
        return;
      }
      setToastMessage("Sending Invitation....");
      setToastType("success");

      const isAssociated = await checkEmailAssociation(email);
      if (!isAssociated) {
        try {
          const result = await apiService.sendInvitation(teamId, email);
          if (result.success) {
            setToastMessage("Invitation sent successfully");
            setToastType("success");
            setInvitedUsers((prevInvitedUsers) => {
              const newInvitedUsers = [...prevInvitedUsers, { email }];
              updateLocalState("adminSettings", {
                ...localState.adminSettings,
                invitedUsers: newInvitedUsers,
              });
              return newInvitedUsers;
            });
            setNewEmail(""); // Clear the input after successful addition
            setEmailError("");
          } else {
            setEmailError(result.error || "Failed to send invitation");
          }
        } catch (error) {
          setEmailError("An error occurred while sending the invitation");
        }
      } else {
        setEmailError("This email is already associated with a team");
      }
    } else {
      setEmailError("This email is already in the invited list");
    }
  };

  const checkEmailAssociation = async (email) => {
    try {
      const response = await apiService.handleProtectedRoute(
        "checkEmailOrganization",
        { email }
      );
      return response.isInOrganization;
    } catch (error) {
      console.error("Error checking email association:", error);
      return false;
    }
  };

  const checkCurrentUserEmail = async () => {
    try {
      const response = await apiService.handleProtectedRoute(
        "fetchUserEmail",
        {}
      );
      return response.success ? response.email : null;
    } catch (error) {
      console.error("Error fetching current user email:", error);
      return null;
    }
  };

  const handleResendInvite = async (email) => {
    posthog.capture("Click to Resend Invite button");
    setToastMessage("Resending Invitation....");
    setToastType("success");
    try {
      const result = await apiService.sendInvitation(teamId, email);
      if (result.success) {
        setToastMessage(result.message || "Invitation resent successfully");
        setToastType("success");
      } else {
        setToastMessage(result.error || "Failed to resend invitation");
        setToastType("error");
      }
    } catch (error) {
      setToastMessage("An error occurred while resending the invitation");
      setToastType("error");
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const confirmLeaveOrganization = async () => {
    posthog.capture("Click to Confirm Leaving Team Button");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/leaveOrganization`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({ team_id: teamId }),
        }
      );

      const result = await response.json();

      if (result.success) {
        // Redirect to home page
        navigate("/");
        navigate(0);
      } else {
        setToastMessage(result.error || "Failed to leave team");
        setToastType("error");
        console.error("Error leaving team:", result.error);
      }
    } catch (error) {
      console.error("Error leaving team:", error);
    }
    setShowLeaveModal(false);
  };

  const handleCancelInvite = async (email) => {
    posthog.capture("Click to Cancel Invite button");
    setToastMessage("Cancelling Invitation....");
    setToastType("success");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/cancelInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({ team_id: teamId, email }),
        }
      );

      const result = await response.json();

      if (result.success) {
        const updatedInvitedUsers = invitedUsers.filter(
          (user) => user.email !== email
        );
        setInvitedUsers(updatedInvitedUsers);
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          invitedUsers: updatedInvitedUsers,
        });
        setToastMessage("Invitation cancelled");
        setToastType("success");
      } else {
        setToastMessage(result.error || "Failed to cancel invitation");
        setToastType("error");
      }
    } catch (error) {
      console.error("Error cancelling invitation:", error);
      setToastMessage("An error occurred while cancelling the invitation");
      setToastType("error");
    }
  };

  // Add these functions
  const handleEnableLink = async () => {
    posthog.capture("Click to Enable Invite Link");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/createPublicTeamInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({ team_id: teamId }),
        }
      );
      const result = await response.json();
      if (result.success) {
        setPublicInviteLink(result.inviteLink);
        setIsLinkEnabled(true);
        setToastMessage("Invitation link created successfully");
        setToastType("success");
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          publicInvitationLink: result.inviteLink,
        });
      }
    } catch (error) {
      setToastMessage("Failed to create invitation link");
      setToastType("error");
    }
  };

  const handleRegenerateLink = async () => {
    posthog.capture("Click to Regenerate Invite Link");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/regeneratePublicTeamInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({ team_id: teamId }),
        }
      );
      const result = await response.json();
      if (result.success) {
        setPublicInviteLink(result.inviteLink);
        setIsLinkEnabled(true);
        setToastMessage("Invitation link created successfully");
        setToastType("success");
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          publicInvitationLink: result.inviteLink,
        });
      }
    } catch (error) {
      setToastMessage("Failed to create invitation link");
      setToastType("error");
    }
  };

  const handleDisableLink = async () => {
    posthog.capture("Click to Disable Invite Link");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/disablePublicTeamInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
          body: JSON.stringify({
            team_id: teamId,
            token: publicInviteLink.split("token=")[1].split("&")[0],
          }),
        }
      );
      const result = await response.json();

      if (result.success) {
        setPublicInviteLink("");
        setIsLinkEnabled(false);
        setToastMessage("Invitation link disabled");
        setToastType("success");
        updateLocalState("adminSettings", {
          ...localState.adminSettings,
          publicInvitationLink: "",
        });
      }
    } catch (error) {
      setToastMessage("Failed to disable invitation link");
      setToastType("error");
    }
  };

  const canLeaveOrganization = teamMembers.length > 1;

  const planMapper = {
    Team: {
      Monthly: "price_1QRfgW2MK4knwytnK8prGjPb",
      Annual: "price_1QRfim2MK4knwytnRFe2jzsX",
    },
  };

  const createPortalSession = async (customerId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/create-portal-session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customerId }),
        }
      );

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url; // Redirect to the Stripe Customer Portal
      } else {
        console.error("Failed to retrieve portal session URL");
      }
    } catch (error) {
      console.error("Error creating portal session:", error);
    }
  };

  const handleSubscribe = async (data) => {
    try {
      console.log(
        "user data userProfileData",
        data,
        localState.userProfileData
      );

      const stripe = await stripePromise;
      const priceId =
        planMapper["Team"][
          localState?.userProfileData?.subscription_type
            ?.toLowerCase()
            ?.includes("annual")
            ? "Annual"
            : `Monthly`
        ]; // Basic Plan Price ID
      const payment_type = localState?.userProfileData?.subscription_type
        ?.toLowerCase()
        ?.includes("annual")
        ? "team_annual"
        : `team_monthly`;
      const teamUserId = localState.adminSettings.teamMembers.filter(
        (member) => member.email === selectedTeamMembers.invitedEmails[0]
      )[0].user_id;

      // Get checkout session
      const checkoutResponse = await apiService.UpgradeMemberCheckout({
        teamId: teamId,
        teamUserId,
        priceId,
        memberCount: selectedTeamMembers.invitedEmails.length,
        payment_type,
        upgradeType: "team_member",
      });

      // Redirect to Stripe checkout using sessionId
      await stripe.redirectToCheckout({
        sessionId: checkoutResponse.sessionId,
      });
    } catch (error) {
      console.log("Error upgrading member plan session:", error);
      console.error("Error upgrading member plan session:", error);
      setToastMessage(
        error.response?.data?.error || "Error upgrading member plan session:"
      );
      setToastType("error");
    }
  };

  return (
    <div className="mb-8">
      {/* {localState.userRole === "Admin" && (
        <>
          <h2 className="text-base font-normal mt-4 ml-2 mb-2">Billing</h2>
          <div className="bg-[#1D1B1D] px-3 py-5 rounded-lg mb-4">
            <div
              className={`flex justify-between items-center ${
                publicInviteLink && `pb-4 border-b border-b-[#2C2A2C]`
              }`}
            >
              <div>
                <label className="block text-base font-light">
                  Manage Billing
                </label>
                <p className="text-sm font-extralight text-[#878188] mr-1">
                  {`You are currently subscribed to the ${localState.userProfileData?.subscription_type} plan.`}
                </p>
              </div>
              <button
                onClick={() => createPortalSession("cus_RMZyR9qjyqyJZP")}
                className="font-medium text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] px-2 h-8"
              >
                Manage
              </button>
            </div>
          </div>
        </>
      )} */}
      {/* Display error message if any */}
      <h2 className="text-base font-normal mt-4 ml-2 mb-2">Members</h2>
      {/* {localState.userRole === "Admin" && (
        <div className="bg-[#1D1B1D] px-3 py-5 rounded-lg mb-4">
          <div
            className={`flex justify-between items-center ${
              publicInviteLink && `pb-4 border-b border-b-[#2C2A2C]`
            }`}
          >
            <div>
              <label className="block text-base font-light">Invite Link</label>
              <p className="text-sm font-extralight text-[#878188] mr-1">
                A secret link for inviting new member
              </p>
            </div>
            {!publicInviteLink ? (
              <button
                onClick={handleEnableLink}
                className="font-medium text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] px-2 h-8"
              >
                Enable
              </button>
            ) : (
              <div className="flex gap-2">
                <button
                  onClick={handleDisableLink}
                  className="font-medium text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] px-2 h-8"
                >
                  Disable
                </button>
                <button
                  onClick={handleRegenerateLink}
                  className="font-medium text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] px-2 h-8"
                >
                  Regenerate
                </button>
              </div>
            )}
          </div>
          {publicInviteLink && (
            <div className="flex items-center mt-4 gap-2">
              <div className="flex flex-1 relative">
                <input
                  type="text"
                  value={publicInviteLink}
                  readOnly
                  className="flex-1 px-3 pl-9 py-2 bg-transparent rounded-full border border-[#4E4A4F] text-sm font-extralight overflow-hidden text-ellipsis"
                />
                <Link className="w-3.5 h-3.5 absolute left-4 top-1/2 transform -translate-y-1/2 text-[#ffff]" />
              </div>

              <button
                onClick={() => {
                  navigator.clipboard.writeText(publicInviteLink);
                  setToastMessage("Link copied to clipboard");
                  setToastType("success");
                }}
                className="flex text-[#878188] items-center gap-1 text-sm flex-row h-8 px-2 text- hover:text-white rounded-md duration-200 [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
              >
                <Copy className="w-4 h-4" /> Copy
              </button>
            </div>
          )}
        </div>
      )} */}
      {(localState.userRole === "Admin" || userRole == "Admin") && (
        <>
          <div className="bg-[#1D1B1D] px-3 py-5 rounded-lg">
            <div className="flex flex-row items-center justify-between pb-2 pr-2">
              <label className="block text-base font-light">
                Invite via Email
              </label>
              {emailError && (
                <span className="text-red-400 text-sm font-extralight">
                  {emailError}
                </span>
              )}
            </div>

            <div
              id="invite-section"
              className="flex items-center space-x-2 relative"
            >
              <input
                type="email"
                className="w-full px-3 h-9 rounded-full placeholder-[#878188] font-extralight bg-[#1D1B1D] text-sm text-white border border-[#4E4A4F] focus:outline-none"
                placeholder="Enter email address to send invite"
                value={newEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !validateEmail(newEmail)) {
                    e.preventDefault();
                    setEmailError("Invalid email address");
                  }
                  if (e.key === "Enter" && validateEmail(newEmail)) {
                    e.preventDefault();
                    setNewEmail("");
                    handleAddMember(newEmail);
                  }
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  setNewEmail(e.target.value);
                  setEmailError(""); // Clear error when input changes
                }}
              />
              <button
                type="button"
                className={`absolute right-1 top-1 p-1.5 ${
                  newEmail
                    ? `bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]`
                    : `bg-[#4E4A4F] text-[#878188]`
                } rounded-full`}
                onClick={(e) => {
                  if (validateEmail(newEmail)) {
                    setNewEmail("");
                    handleAddMember(newEmail);
                  } else {
                    setEmailError("Invalid email address");
                  }
                }}
              >
                <Plus />
              </button>
            </div>
          </div>
        </>
      )}
      {/* Team Members Section */}
      <div
        className={`bg-[#1D1B1D] px-3 py-1 rounded-lg mb-8 ${
          localState.userRole === "Admin" || userRole == "Admin"
            ? ` mt-4`
            : `mt-0`
        } `}
      >
        <div className="flex justify-between items-center py-4 border-b border-b-[#2C2A2C]">
          <div className="flex gap-6">
            <div className="flex fex-row gap-2 items-center">
              <Persons className="w-4 h-4" />

              <p className="text-base font-light">
                {teamMembers.length}{" "}
                {teamMembers.length > 1 ? `Members` : `Member`}
              </p>
            </div>
            {teamMembers.length > 0 && (
              <div className="flex fex-row gap-2 items-center">
                <Envelope className="w-4 h-4" />
                <p className="text-base font-light">
                  {invitedUsers.length} Invited
                </p>
              </div>
            )}
          </div>
          {(localState.userRole === "Admin" || userRole == "Admin") && (
            <button
              className={`flex flex-row gap-2 hover:text-white duration-200 items-center text-sm font-medium [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] ${
                editMode ? `text-white` : `text-[#878188]`
              } px-2 h-8 rounded-md`}
              onClick={handleEditClick}
            >
              <PencilToLine className="w-3.5 h-3.5" />
              Edit
            </button>
          )}
        </div>
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`flex px-1 font-light ${
              invitedUsers.length || index !== teamMembers.length - 1
                ? `border-b border-b-[#2C2A2C] py-4`
                : `py-4 `
            } ${
              editMode
                ? `md:flex-row flex-col md:items-center items-end`
                : `items-center`
            } justify-between text-[#878188]`}
          >
            {/* <div className="flex justify-between items-center w-2/3">
              <span>{member.role}</span>
              <span className="w-1/2"></span>
            </div> */}
            <div className="flex gap-2 items-center w-full text-sm font-extralight">
              <div className="w-full max-w-[80px]">{member.role}</div>
              <div className=" w-full break-all">{member.email}</div>
              {/* <span className="bg-red-700 text-red-200 px-3 py-1 rounded flex items-center justify-center"> */}
              {/* <Dropdown
                options={[
                  {
                    label: "Upgrade minutes",
                    icon: "Filmstrip",
                    id: "audio-option",
                  },
                ]}
                onOptionSelect={() => {
                  if (member.role === "Member") {
                    setOpenTeamPurchaseModal(true);
                    setSelectedTeamMembers({
                      ...selectedTeamMembers,
                      invitedEmails: [
                        ...selectedTeamMembers.invitedEmails,
                        member?.email,
                      ],
                    });
                  }
                }}
                className={`w-[160px] mb-1 font-small text-sm text-[#878188] bg-[#100f10] border border-solid border-[#2c2a2c] rounded-md z-10 `}
                // customInput
                buttonLabel={`Remaining: ${
                  member?.remaining_credits ?? 0
                } mins`}
              /> */}
              {localState.userRole === "Admin" && (
                <span
                  className={`font-light text-right w-full ${
                    member?.remaining_credits < 5
                      ? member?.remaining_credits == 0
                        ? `text-[#FF5454]`
                        : `text-[#DDA012]`
                      : `text-[#00A431]`
                  }`}
                >
                  Remaining: {member?.remaining_credits || 0} mins
                </span>
              )}
            </div>

            {/* Action buttons for each member */}
            {editMode && (
              <div
                className={`flex gap-1 mx-2 ${
                  Number(member.user_id) !== Number(getCookie("user_id"))
                    ? "md:mt-0 mt-3"
                    : "hidden mt-0"
                } `}
              >
                {member.role !== "Admin" &&
                Number(member.user_id) !== Number(getCookie("user_id")) ? (
                  <button
                    onClick={() => handleMakeAdmin(member)}
                    className="text-[#878188] font-normal text-sm hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] duration-200 h-8 w-[6rem] rounded-md hover:text-white"
                  >
                    Make Admin
                  </button>
                ) : (
                  Number(member.user_id) !== Number(getCookie("user_id")) && (
                    <button
                      onClick={() => handleRemoveAdmin(member)}
                      className="text-[#878188] w-28 font-normal text-sm hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] duration-200 px-3 h-8 rounded-md hover:text-white"
                    >
                      Remove as Admin
                    </button>
                  )
                )}
                {Number(member.user_id) !== Number(getCookie("user_id")) && (
                  <button
                    onClick={() => handleRemoveMember(member)}
                    className="p-2 h-8 w-8 items-center justify-center hover:text-white rounded-md duration-200 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
                  >
                    <PersonXmark />
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
        {invitedUsers.map((invitedUser, index) => (
          <div
            key={index}
            className={`flex px-1 py-4 font-light ${
              index !== invitedUsers.length - 1 &&
              `  py-4 border-b border-b-[#2C2A2C]`
            } ${
              editMode
                ? `md:flex-row flex-col md:items-center items-end`
                : `items-center`
            } text-[#878188]`}
          >
            <div className="flex items-center w-full gap-2 text-sm font-extralight">
              <div className="w-full max-w-[80px]">Invited</div>
              <div className="break-all w-full">{invitedUser.email}</div>
              {/* Deny Button */}
              {/* <span className="flex gap-5">
                <button
                  className="px-2 py-1 g rounded-md text-sm bg-opacity-10 text-gray-400"
                  style={{
                    color: "white",
                  }}
                >
                  Deny
                </button> */}

              {/* Accept Button */}
              {/* <button className="px-2 py-1 rounded-md text-sm text-black bg-white">
                  Accept
                </button>
              </span> */}

              {/* Display the invited user's email */}
            </div>
            {editMode && (
              <div className="flex gap-1 mt-3 md:mt-0">
                <button
                  onClick={() => handleResendInvite(invitedUser.email)}
                  className="text-[#878188] font-normal w-28 text-sm hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] duration-200 px-2 h-8 rounded-md hover:text-white md:mt-0"
                >
                  Resend Invite
                </button>
                <button
                  onClick={() => handleCancelInvite(invitedUser.email)}
                  className="h-8 w-8 p-2 items-center justify-center hover:text-white rounded-md duration-200 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
                >
                  <Xmark className="w-3.5 h-3.5" />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <h2 className="text-base font-normal ml-2 mb-2">Contacts</h2>
      <div className="flex flex-row justify-between items-center bg-[#1D1B1D] px-3 py-5 rounded-lg mb-8">
        <div>
          <label className="block text-base font-light">Support</label>
          <p className="text-sm font-extralight text-[#878188] mr-1">
            The email adddress to contact for support
          </p>
        </div>
        <label className="block text-sm font-extralight md:px-0 pl-2">
          support@wubble.ai
        </label>
        {/* <button
          className="font-medium hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] px-4 py-2"
          // onClick={handleEditClick}
        >
          View Trust Center
        </button> */}
      </div>
      <h2 className="text-base font-normal ml-2 mb-2">System</h2>
      <div className="flex flex-col bg-[#1D1B1D] py-1 px-3 rounded-lg">
        {/* <div className="flex flex-row justify-between items-center border-b border-b-[#2C2A2C] py-5">
          <label className="block text-base font-light">
            Security documentation
          </label>

          <button
            className="font-medium md:text-sm text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] md:px-2 px-2 md:py-0 py-2 md:h-8"
            // onClick={handleEditClick}
          >
            View Trust Center
          </button>
        </div> */}

        <div className="flex flex-row justify-between items-center py-1">
          <p className="text-sm font-light text-[#878188] py-4 w-2/3">
            Ensure that any data shared complies with your team's security
            policies and confidentiality guidelines.
          </p>
          <button
            className="font-medium md:text-sm text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] md:px-2 px-2 md:py-0 py-2 md:h-8"
            onClick={() =>
              window.open("https://info.wubble.ai/terms-of-use", "_blank")
            }
          >
            Learn more
          </button>
        </div>

        {/* <p className="text-sm font-light text-[#878188] py-4">
          Your provider Workspace is limiting your file retention to 7 days.
        </p> */}
      </div>
      <div className="flex flex-col w-full justify-between items-center bg-[#1D1B1D] px-3 py-1 rounded-lg mt-4 ">
        {canLeaveOrganization && (
          <div
            className={`flex w-full flex-row justify-between items-center py-5 ${
              localState.userRole == "Admin"
                ? `border-b border-b-[#2C2A2C]`
                : ``
            }`}
          >
            <div>
              <label className="block text-base font-light">Leave Team</label>
              <p className="text-sm font-extralight text-[#878188]">
                Permanently leave {teamName}
              </p>
            </div>
            <button
              className="font-medium md:text-sm text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] md:px-2 px-2 md:py-0 py-2 md:h-8"
              onClick={() => {
                posthog.capture("Click to Leave Teamm");
                setShowLeaveModal(true);
              }}
            >
              Learn more
            </button>
          </div>
        )}
        {(localState.userRole == "Admin" || userRole == "Admin") && (
          <div className="flex w-full flex-row justify-between items-center bg-[#1D1B1D] py-5 rounded-b-lg">
            <div>
              <label className="block text-base font-light">Delete Team</label>
              <p className="text-sm font-extralight text-[#878188]">
                Permanently delete {teamName}
              </p>
            </div>
            <button
              className="font-medium md:text-sm text-sm hover:text-white duration-200 rounded-md [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-[#878188] md:px-2 px-2 h-8"
              onClick={handleDeleteOrganization}
            >
              Learn more
            </button>
          </div>
        )}
      </div>
      {openTeamPurchaseModal && (
        <>
          <div className="w-full max-w-3xl bg-[#100F10] px-4 py-4 rounded-lg ">
            <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
              {/* Top Left: Logo and Text */}
              <div
                className="relative h-9 flex items-center gap-2 rounded-2.5"
                id="sidebar-logo"
                onClick={() => navigate("/")}
              >
                <img
                  className="w-10 h-10 object-cover rounded-full cursor-pointer"
                  src="/logo@2x.png"
                  alt="Logo"
                  id="sidebar-logo-image"
                />
                <img
                  className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
                  src="/text_only_white.svg"
                  alt="White Text"
                  id="sidebar-logo-text-white"
                />
                <img
                  className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
                  src="/text_only_gradient.svg"
                  alt="Gradient Text"
                  id="sidebar-logo-text-gradient"
                />
              </div>

              <div className="flex items-center gap-2">
                <div
                  className={`w-3 h-3 rounded-full bg-white"
                      }`}
                ></div>{" "}
                {/* Circle 1 */}
                <div className={`w-3 h-3 rounded-full bg-white`}></div>{" "}
                {/* Circle 2 */}
              </div>

              {/* Right: Continue Button */}
              <div></div>
            </div>

            <Modal closeModal={() => setShowMakeAdminModal(false)}>
              <TeamPurchaseSummary
                teamMembersInvited={selectedTeamMembers}
                onUpdateTeamMembers={handleSubscribe}
              />
            </Modal>
          </div>
        </>
      )}
      {showMakeAdminModal && (
        <Modal closeModal={() => setShowMakeAdminModal(false)}>
          <div className="text-white w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <h2 className="text-lg text-left px-3 py-3">
              {selectedMember.role === "Admin" ? "Remove Admin" : "Make Admin"}
            </h2>
            <div className="w-full border-t border-t-[#2d2a2d]"></div>
            <p className="text-sm font-light text-left pt-3 pb-5 px-3">
              {selectedMember.role === "Admin"
                ? `Remove admin privileges from ${
                    selectedMember?.email || selectedMember?.name
                  }?`
                : `Are you sure you want to grant admin privileges to ${
                    selectedMember?.email || selectedMember?.name
                  } for the team?`}
            </p>
            <div className="flex justify-between items-center px-3">
              <button
                onClick={() =>
                  window.open("https://info.wubble.ai/faqs/accounts", "_blank")
                }
                className="flex flex-row gap-1 mb-2.5 items-center text-[#878188] font-medium text-sm hover:text-white duration-200 rounded-md"
              >
                <CircleExclamation className="flex" /> Learn about admin
              </button>
              <div className="flex space-x-3">
                <button
                  onClick={() => setShowMakeAdminModal(false)}
                  className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-sm text-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    selectedMember.role === "Admin"
                      ? confirmRemoveAdmin()
                      : confirmMakeAdmin()
                  }
                  className="px-3 h-9 mb-3 mr-3 hover:bg-[#E0DFE1] bg-white text-[#100f10] text-sm font-normal rounded-md"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showRemoveModal && (
        <Modal closeModal={() => setShowRemoveModal(false)}>
          <div className="text-white w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <h2 className="text-lg text-left px-3 py-3">Remove Member</h2>
            <div className="w-full border-t border-t-[#2d2a2d]"></div>
            <p className="text-sm font-light text-left pt-3 pb-5 px-3">
              Are you sure you want to remove{" "}
              <b className="font-medium">
                {selectedMember?.email || selectedMember?.name}
              </b>{" "}
              from your team?
            </p>
            <div className="flex justify-end space-x-3 px-3">
              <button
                onClick={() => setShowRemoveModal(false)}
                className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-sm text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={confirmRemoveMember}
                className="px-3 h-9 mb-3 mr-3 hover:bg-[#dd3f3f] bg-[#BB2D2D] text-[#ffff] text-sm font-normal rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showLeaveModal && (
        <Modal closeModal={() => setShowLeaveModal(false)}>
          <div className="text-white w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <h2 className="text-lg text-left px-3 py-3">Leave Team</h2>
            <div className="w-full border-t border-t-[#2d2a2d]"></div>
            <p className="text-sm font-light text-left pt-3 pb-5 px-3">
              Are you sure you want to leave this team?
            </p>
            <div className="flex justify-end space-x-3 px-3">
              <button
                onClick={() => setShowLeaveModal(false)}
                className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-sm text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => confirmLeaveOrganization()}
                className="px-3 h-9 mb-3 mr-3 hover:bg-[#dd3f3f] bg-[#BB2D2D] text-white text-sm font-normal rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal closeModal={() => setShowDeleteModal(false)}>
          <div className="text-white px-4 w-full bg-[#1d1b1d] border border-solid border-[#3c393c] rounded-lg">
            <h2 className="text-lg text-left py-3">Delete Team</h2>
            <div className="w-full border-t border-t-[#2d2a2d]"></div>
            {toastMessage && (
              <p className="font-light text-sm bg-[#340000] text-[#FFC7C7] py-1 rounded-md mt-2">
                {toastMessage}
              </p>
            )}
            <p className="text-sm font-extralight text-left pt-3">
              Please let us know why you’re choosing to delete this team
              account:
            </p>
            <div className="flex flex-col gap-2">
              <select
                className="w-full px-3 h-9 py-1 font-extralight text-sm bg-[#1d1b1d] mt-2 rounded-full border border-solid border-[#878188] text-[#878188]"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
              >
                <option value="">Select a reason</option>
                <option
                  className="text-white font-extralight"
                  value="No longer needed"
                >
                  No longer needed
                </option>
                <option
                  className="text-white font-extralight"
                  value="Switching to another service"
                >
                  Switching to another service
                </option>
                <option
                  className="text-white font-extralight"
                  value="Cost-related reasons"
                >
                  Cost-related reasons
                </option>
                <option className="text-white font-extralight" value="Others">
                  Others (please specify)
                </option>
              </select>
              {selectedReason === "Others" && (
                <input
                  type="text"
                  className="w-full h-9 px-3 py-1 mb-2 font-extralight text-sm bg-[#1d1b1d] mt-2 rounded-full border border-solid border-[#878188] placeholder-text-[#878188]"
                  placeholder="Please specify your reason"
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                />
              )}
            </div>
            <p className="text-base font-light text-left pt-3 pb-2">
              By deleting this team, you will:
            </p>
            <ul className="flex flex-col justify-start text-left list-disc text-sm font-extralight pb-4 pl-6">
              <li className="pl-1">
                Permanently remove all projects associated with this team.
              </li>
              <li className="pl-1">
                Lose access to billing information and invoices for the team
                plan.
              </li>
              <li className="pl-1">
                Miss out on future updates and new features tailored to your
                needs.
              </li>
            </ul>
            <p className="text-sm font-extralight text-left">
              To proceed, please type "CONFIRM DELETE" below.
            </p>
            <input
              type="text"
              className="w-full h-9 px-3 py-1 mb-4 font-extralight text-sm bg-[#1d1b1d] mt-2 rounded-full border border-solid border-[#878188] placeholder-text-[#878188]"
              placeholder="Please type “CONFIRM DELETE” to proceed"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-3 h-9 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-sm text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteOrganization}
                className={`px-3 h-9 mb-3 mr-3 ${
                  selectedReason &&
                  (selectedReason !== "Others" ||
                    (selectedReason === "Others" && customReason)) &&
                  confirmText === "CONFIRM DELETE"
                    ? `hover:bg-[#dd3f3f] bg-[#BB2D2D] text-white`
                    : `bg-[#4e4a4f] text-[#878188]`
                } text-sm font-normal rounded-md`}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          className={`md:top-20 md:left-[52%] w-11/12 top-32 left-[50%]`}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default TeamMembers;
