// src/components/JoinOrganization.js

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../modal/Modal";
import { useAuth } from "../../contexts/AuthContext";
import { getCookie } from "../../utils/cookieUtils";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { componentMapper } from "../../utils/componentMapper";
import Toast from "../generic_components/Toast";
import { MusicNote } from "@gravity-ui/icons";

const JoinOrganization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const [invitationToken, setInvitationToken] = useState("");
  const [teamName, setTeamName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isRegisteredUser, setIsRegisteredUser] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [basicDetails, setBasicDetails] = useState({});
  const { updateLocalState } = useLocalState();
  const [infoMessage, setInfoMessage] = useState("");
  // New state variable to control rendering when sign-in is required
  const [requiresSignIn, setRequiresSignIn] = useState(false);
  const [canJoinTeam, setCanJoinTeam] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    setInvitationToken(token);
    // if (errorMessage === "Invalid or expired invitation.") {
    //   navigate("/");
    // }
    if (token) {
      // Set flag in localState or sessionStorage indicating the user is joining an organization
      updateLocalState("joiningOrganization", { token });
      sessionStorage.setItem("joiningOrganization", token);

      // const isInOrganization = sessionStorage.getItem("joiningOrganization");

      // if (isInOrganization) {
      //   sessionStorage.removeItem("joiningOrganization");
      // }

      const verifyToken = async () => {
        try {
          // Extract isPublic parameter from URL
          const queryParams = new URLSearchParams(location.search);

          const response = await fetch(
            `${
              process.env.REACT_APP_SERVER_ENDPOINT
            }/api/validateInvitationToken?token=${token}&isPublic=${
              queryParams.get("public") === null ? "false" : "true"
            }`,
            { method: "GET", headers: { "Content-Type": "application/json" } }
          );
          const result = await response.json();

          // console.log("Response from server:", result);

          if (result.success) {
            setTeamName(result.teamName);
            setIsRegisteredUser(result.isRegisteredUser);

            const userId = getCookie("user_id");
            const token = getCookie("token");

            if (!userId) {
              if (!result.isRegisteredUser) {
                setRequiresSignIn(false);
              }
              if (result.isRegisteredUser && !token) {
                setRequiresSignIn(true);
              }
            } else if (
              userId &&
              result.isRegisteredUser &&
              Number(result.userId) !== Number(userId)
            ) {
              setRequiresSignIn(true);
            } else if (
              userId &&
              result.isRegisteredUser &&
              Number(result.userId) == Number(userId) &&
              !result.teamName
            ) {
              navigate("/");
            } else if (token && userId) {
              setCanJoinTeam(true);
            } else setRequiresSignIn(false);
          } else {
            setErrorMessage(result.message);
          }
        } catch (error) {
          setErrorMessage("Failed to verify invitation token.");
        }
      };
      verifyToken();
    } else {
      !teamName && setErrorMessage("Invalid invitation link.");
    }
  }, [location.search, isLoggedIn]);

  const acceptInvitation = async () => {
    // Extract isPublic parameter from URL
    const queryParams = new URLSearchParams(location.search);
    const isPublic = queryParams.get("public") === null ? "false" : "true";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/acceptInvitation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`, // Include the authorization header
          },
          body: JSON.stringify({ token: invitationToken, isPublic }),
        }
      );

      const result = await response.json();

      if (result.success) {
        sessionStorage.removeItem("joiningOrganization");
        updateLocalState("joiningOrganization", null);

        // Invitation accepted, show the next div with basic details
        setBasicDetails(result.teamName); // Assuming team details come in the response
        // setShowDetails(true);
        navigate("/");
        navigate(0);
      } else {
        setErrorMessage(result.message || "Failed to accept the invitation.");
      }
    } catch (error) {
      setErrorMessage("Failed to accept the invitation.");
    }
  };

  const handleContinueClick = () => {
    // Redirect to home page after showing details
    navigate("/");
    navigate(0);
  };

  const handleSignUpClick = async () => {
    try {
      setModalContent({
        inputJson: "signup1.json",
      });
      setShowModal(true);
    } catch (error) {
      setErrorMessage("Failed to load sign-up modal.");
    }
  };

  // New function to handle 'Sign In' button click
  const handleSignInClick = async () => {
    try {
      setModalContent({
        inputJson: "signIn.json",
      });
      setShowModal(true);
    } catch (error) {
      setErrorMessage("Failed to load sign-in modal.");
    }
  };

  // Define renderComponents inside the component
  const renderComponents = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid jsonData format or empty array:", data);
      return null;
    }

    return data.map((item, index) => {
      const { name, props, children } = item;

      // Handle inputJson key to import JSON pages into other JSON pages
      if (item.inputJson && location?.pathname !== "/chatPage") {
        const jsonFile = require(`../../jsonFiles/${item.inputJson}`);
        return <div key={index}>{renderComponents(jsonFile)}</div>;
      }

      const ComponentToRender = componentMapper[name];

      if (!ComponentToRender) {
        console.error(`Component "${name}" not found in componentMapper.`);
        return null;
      }

      const componentProps = {
        ...props,
        ...(props.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...(props.trigger === "closeModal"
          ? {
              onClick: () => setShowModal(false),
            }
          : {}),
        ...(name === "InputWithButton"
          ? { closeModal: () => setShowModal(false) }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent1
          ? {
              onClickOption1: () => {
                setModalContent(props.modalContent1);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent2
          ? {
              onClickOption2: () => {
                setModalContent(props.modalContent2);
                setShowModal(true);
              },
            }
          : {}),
      };

      return (
        <React.Fragment key={index}>
          {infoMessage && (
            <Toast
              key={`toast-${index}`}
              type="info"
              message={infoMessage}
              onClose={() => setInfoMessage("")}
            />
          )}
          <ComponentToRender {...componentProps}>
            {children && renderComponents(children)}
          </ComponentToRender>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="flex flex-col w-full items-center ">
      <div className="w-full max-w-3xl bg-[#100F10] px-4 py-4 rounded-lg h-full">
        <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
          <div
            className="relative h-9 flex items-center gap-2 rounded-2.5"
            id="sidebar-logo"
            onClick={() => navigate("/")}
          >
            <img
              className="w-10 h-10 object-cover rounded-full cursor-pointer"
              src="/logo@2x.png"
              alt="Logo"
              id="sidebar-logo-image"
            />
            <img
              className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
              src="/text_only_white.svg"
              alt="White Text"
              id="sidebar-logo-text-white"
            />
            <img
              className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
              src="/text_only_gradient.svg"
              alt="Gradient Text"
              id="sidebar-logo-text-gradient"
            />
          </div>
        </div>
        {requiresSignIn ? (
          <>
            <h2 className="text-3xl font-medium mt-6 mb-1">
              Please sign in to continue
            </h2>
            <p className="font-extralight text-sm pb-6 text-[#C5C3C6]">
              You need to sign in to accept the invitation.
            </p>
            <button
              onClick={handleSignInClick}
              className="h-9 px-3 rounded-lg bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-sm font-normal text-white hover:from-[#e509e4] hover:to-[#256bb8]"
            >
              Sign In
            </button>
          </>
        ) : (
          <>
            <h2 className="text-3xl font-medium mt-6 mb-1">
              {isRegisteredUser || canJoinTeam
                ? teamName && `Join ${teamName}`
                : "Please create an account"}
            </h2>
            <p className="font-extralight text-sm pb-6 text-[#C5C3C6]">
              {isRegisteredUser || canJoinTeam
                ? "Accept your invite to get all the benefits of our platform."
                : `To join ${
                    teamName || "this team"
                  }, you'll need to sign up first.`}
            </p>
            {!isRegisteredUser && !canJoinTeam ? (
              <button
                onClick={handleSignUpClick}
                className="h-9 px-3 rounded-lg bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-sm font-normal text-white hover:from-[#e509e4] hover:to-[#256bb8]"
              >
                Sign Up
              </button>
            ) : (
              <div className="flex flex-row gap-4 mx-1">
                <button
                  className="text-[#878188] hover:text-white rounded-lg px-3 h-9 text-sm font-normal"
                  onClick={() => {
                    sessionStorage.removeItem("joiningOrganization");
                    updateLocalState("joiningOrganization", null);
                    navigate("/");
                  }}
                >
                  Decline
                </button>
                <button
                  onClick={acceptInvitation}
                  // disabled={
                  //   errorMessage === "Invalid or expired invitation."
                  //     ? true
                  //     : false
                  // }
                  className="h-9 px-3 rounded-lg bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-sm font-normal text-white hover:from-[#e509e4] hover:to-[#256bb8]"
                >
                  Accept
                </button>
              </div>
            )}
            {errorMessage && (
              <p className="text-red-300 mt-4 text-sm font-extralight px-2 py-2 bg-red-950 rounded-lg">
                {errorMessage}
              </p>
            )}
          </>
        )}
      </div>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          {modalContent && renderComponents([modalContent])}
        </Modal>
      )}
    </div>
  );
};

export default JoinOrganization;

// : (
//   <div>
//     <h2 className="text-2xl font-light mt-4 mb-1">
//       Welcome to {basicDetails || "the organization"}
//     </h2>
//     <p className="font-extralight text-sm text-[#C5C3C6]">
//       Let's make music together, faster!
//     </p>
//     <div className="bg-[#100f10] flex flex-col justify-between ">
//       {/* Features Grid */}
//       <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 px-2 py-4 my-2 text-white">
//         {/* Each Feature */}
//         {[...Array(6)].map((_, index) => (
//           <div key={index} className="flex flex-col space-y-1">
//             <div className="flex items-start space-x-3">
//               {/* Music Icon */}
//               <MusicNote className="w-4 h-4 mt-1" />
//               {/* Feature Name */}
//               <div>
//                 <h3 className="text-sm font-medium">Feature name</h3>
//                 <p className="text-[#878188] text-xs font-extralight">
//                   Feature description
//                 </p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Subscription Box */}
//       <div className="flex flex-col bg-[#252525] p-4 rounded-lg items-start">
//         <div className="flex flex-row gap-1">
//           <h2 className="text-sm text-[#D54DFF] font-medium">
//             Enterprise
//           </h2>
//           <span className="text-white text-sm font-medium">
//             Subscription Active
//           </span>
//         </div>
//         <p className="text-[#878188] text-sm font-light">
//           Thank you for your support
//         </p>
//       </div>
//     </div>
//     <button
//       onClick={handleContinueClick}
//       className="flex items-center h-9 px-3 mt-6 rounded-lg text-sm font-normal bg-gradient-to-b from-[#fc10f2] to-[#3078e4] hover:from-[#e509e4] hover:to-[#256bb8] text-white"
//     >
//       Continue
//     </button>
//   </div>
// )}
