import React from "react";
import { useNavigate } from "react-router-dom";
import { CircleExclamation } from "@gravity-ui/icons";

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-screen items-center bg-[#1D1B1D] p-2">
      <div className="w-full max-w-3xl bg-[#100F10] px-4 py-4 rounded-lg">
        <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
          <div
            className="relative h-9 flex items-center gap-2 rounded-2.5"
            id="sidebar-logo"
            onClick={() => navigate("/")}
          >
            <img
              className="w-10 h-10 object-cover rounded-full cursor-pointer"
              src="/logo@2x.png"
              alt="Logo"
              id="sidebar-logo-image"
            />
            <img
              className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
              src="/text_only_white.svg"
              alt="White Text"
              id="sidebar-logo-text-white"
            />
            <img
              className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
              src="/text_only_gradient.svg"
              alt="Gradient Text"
              id="sidebar-logo-text-gradient"
            />
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center justify-center mt-4 mb-2 gap-2">
            <CircleExclamation className="text-red-500 w-8 h-8" />
            <h2 className="flex text-3xl font-medium text-white">
              Payment Failed
            </h2>
          </div>
          <p className="font-extralight text-sm pb-4 text-[#C5C3C6]">
            Your payment process to our subscription has failed. Please retry
            the process later to subscribe the basic plan.
          </p>
          <button
            onClick={() => navigate("/")}
            className="h-9 px-3 rounded-lg bg-white/10 text-sm font-normal text-white hover:bg-white/20 duration-200"
          >
            Back to home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
