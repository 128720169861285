import React, { useEffect } from "react";

const Success = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  })
  return (
    <div>
      <h2>Payment Successful!</h2>
      <p>Thank you for your purchase.</p>
    </div>
  );
};

export default Success;
