import { ArrowUpRightFromSquare } from "@gravity-ui/icons";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  setSubscriptionModal,
  setRedirectAfterSelectedPlan,
} from "../../store/reducers/subscriptionSlice";
import { getCookie } from "../../utils/cookieUtils";
import Account from "./Account";
import ProfileSettings from "./ProfileSettings";
import TeamSettings from "./TeamSettings";

const Settings = () => {
  const posthog = usePostHog();
  const [activeTab, setActiveTab] = useState("personal");
  const { localState, updateLocalState } = useLocalState();
  const [userProfileData, setUserProfileData] = useState(null);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isTeamMember, setIsTeamMember] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [userProfileData, teamData] = await Promise.all([
          apiService.handleProtectedRoute(
            localState.isTeamMember
              ? "fetchTeamUserProfile"
              : "fetchUserProfile",
            {}
          ),
          apiService.handleProtectedRoute("fetchTeamProfile", {}),
        ]);

        // Process user profile data
        if (userProfileData?.user?.avatar?.data) {
          userProfileData.user.avatarBase64 = `data:image/jpeg;base64,${arrayBufferToBase64(
            userProfileData.user.avatar.data
          )}`;
        }

        // Process team data
        if (teamData?.teamDetails?.logo?.data) {
          teamData.teamDetails.teamavatar = `data:image/jpeg;base64,${arrayBufferToBase64(
            teamData.teamDetails?.logo?.data
          )}`;
        }

        // Store all data in localState
        updateLocalState("userProfileData", userProfileData);
        updateLocalState("teamSettingsData", teamData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [localState.isTeamMember]);

  useEffect(() => {
    if (localState.userProfileData) {
      setUserProfileData(localState.userProfileData);
    }
  }, [localState.userProfileData]);

  useEffect(() => {
    const checkTeamMembership = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/check-team-membership`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );

        const data = await response.json();

        setIsTeamMember(data.isTeamMember);
        updateLocalState("isTeamMember", data.isTeamMember);
      } catch (error) {
        console.error("Error checking team membership:", error);
      }
    };

    if (localState.isTeamMember) {
      setIsTeamMember(localState.isTeamMember);
      return;
    }
    checkTeamMembership();
  }, [localState.isTeamMember]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    if (localState.organizationInviteClicked) {
      setActiveTab("organization");
      setTimeout(
        () => {
          const inviteSection = document.getElementById("invite-section");
          if (inviteSection) {
            inviteSection.scrollIntoView({ block: "center" });
            const inviteInput = document.querySelector(
              'input[placeholder="Enter email address to send invite"]'
            );
            if (inviteInput) {
              inviteInput.focus();
            }
          }
          updateLocalState("organizationInviteClicked", false);
        },
        !localState.teamSettingsData ? 3000 : 100
      );
    }
  }, [localState.organizationInviteClicked]);

  useEffect(() => {
    if (localState.organizationClicked) {
      setActiveTab("organization");
      updateLocalState("organizationClicked", false);
    }
  }, [localState.organizationClicked]);

  const LoadingPlaceholder = () => (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] scrollable-content-team-settings overflow-y-auto rounded-lg relative w-full">
        <div className="flex flex-col items-center justify-top w-full">
          <div className="relative max-w-3xl items-center w-full h-full">
            {/* General Section */}
            <div className="px-4 py-4">
              <div className="h-6 w-20 rounded mb-2 ml-2">General</div>
              <div className="space-y-4">
                <div className="py-1 px-3 bg-[#1d1b1d] rounded-lg">
                  <div className="flex justify-between items-center py-5">
                    <div className="flex flex-col space-y-2">
                      <div className="h-5 w-20 bg-[#2C2A2C] rounded"></div>
                      <div className="h-4 w-40 bg-[#2C2A2C] rounded"></div>
                    </div>
                    <div className="h-5 w-24 bg-[#2C2A2C] rounded"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Accounts Section */}
            <div className="px-4 py-4">
              <div className="h-6 w-20 rounded mb-2 ml-2">Accounts</div>
              <div className="space-y-4">
                <div className="py-1 px-3 bg-[#1d1b1d] rounded-lg">
                  <div className="flex justify-between items-center border-b border-[#2c2a2c] py-5">
                    <div className="h-5 w-24 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-32 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="flex justify-between items-center py-4">
                    <div className="h-5 w-20 bg-[#2C2A2C] rounded"></div>
                    <div className="h-8 w-32 bg-[#2C2A2C] rounded"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* System Section */}
            <div className="px-4 py-4">
              <div className="h-6 w-20 rounded mb-2 ml-2">System</div>
              <div className="pb-10">
                <div className="py-1 px-3 bg-[#1d1b1d] rounded-lg">
                  <div className="flex justify-between items-center border-b border-[#2c2a2c] py-4">
                    <div className="h-5 w-24 bg-[#2C2A2C] rounded"></div>
                    <div className="h-8 w-20 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="flex justify-between items-center py-4">
                    <div className="h-5 w-28 bg-[#2C2A2C] rounded"></div>
                    <div className="h-8 w-16 bg-[#2C2A2C] rounded"></div>
                  </div>
                </div>

                <div className="md:flex md:flex-row flex px-4 md:space-y-0 md:py-4 py-4 space-y-4 flex-col md:justify-between md:items-center bg-[#1d1b1d] rounded-lg mt-6">
                  <div className="flex space-x-6">
                    <div className="h-5 w-24 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-32 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="h-5 w-28 bg-[#2C2A2C] rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const LoadingTeamsPlaceholder = () => (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] scrollable-content-team-settings overflow-y-auto rounded-lg relative w-full">
        <div className="flex flex-col items-center justify-top w-full">
          <div className="relative max-w-3xl items-center w-full h-full">
            {/* Team Details Section */}
            <div className="px-4 py-4">
              <div className="space-y-2 mb-0">
                <div className="h-6 w-20 rounded mb-2 ml-2">General</div>
                <div className="py-1 px-3 bg-[#1d1b1d] rounded-lg">
                  <div className="flex justify-between items-center py-5 border-b border-[#2c2a2c]">
                    <div className="h-7 w-11/12 bg-[#2C2A2C] rounded-lg"></div>
                  </div>
                  <div className="flex justify-between items-center py-5 border-b border-[#2c2a2c]">
                    <div className="h-7 w-11/12 bg-[#2C2A2C] rounded-lg"></div>
                  </div>
                  <div className="flex justify-between items-center py-5">
                    <div className="h-7 w-11/12 bg-[#2C2A2C] rounded-lg"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Members Section */}
            <div className="px-4 py-4">
              <div className="h-6 w-32 rounded mb-2 ml-2">Members</div>
              <div className="py-3 px-3 bg-[#1d1b1d] rounded-lg">
                <div className="h-[50px] w-full bg-[#2C2A2C] rounded"></div>
              </div>
              <div className="py-3 px-3 bg-[#1d1b1d] rounded-lg mt-4">
                <div className="h-[100px] w-full bg-[#2C2A2C] rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const LoadingProfilePlaceholder = () => (
    <div className="flex md:flex-row flex-col bg-zinc-900 text-white relative">
      <div className="flex-1 flex-col justify-center bg-[#100f10] scrollable-content-team-settings overflow-y-auto rounded-lg relative w-full">
        <div className="flex flex-col items-center justify-top w-full">
          <div className="relative max-w-3xl items-center w-full h-full">
            <div className="px-4 py-4">
              <div className="space-y-2">
                <div className="h-6 w-20 rounded mb-2 ml-2">General</div>
                <div className="py-1 px-3 bg-[#1d1b1d] rounded-lg">
                  <div className="flex justify-between items-center py-5 border-b border-[#2c2a2c]">
                    <div className="h-5 w-1/6 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-6 mx-4 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="flex justify-between items-center py-5 border-b border-[#2c2a2c]">
                    <div className="h-5 w-1/6 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-6 mx-4 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="flex justify-between items-center py-5 border-b border-[#2c2a2c]">
                    <div className="h-5 w-1/6 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-6 mx-4 bg-[#2C2A2C] rounded"></div>
                  </div>
                  <div className="flex justify-between items-center py-5">
                    <div className="h-5 w-1/6 bg-[#2C2A2C] rounded"></div>
                    <div className="h-5 w-6 mx-4 bg-[#2C2A2C] rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderActiveTab = () => {
    switch (activeTab) {
      case "personal":
        if (isLoading && !localState.userProfileData) {
          return <LoadingPlaceholder />;
        }
        return <Account userData={localState.userProfileData} />;
      case "profile":
        if (isLoading && !localState.userProfileData) {
          return <LoadingProfilePlaceholder />;
        }
        return <ProfileSettings />;
      case "organization":
        if (
          isLoading &&
          !localState.teamSettingsData &&
          !localState.adminSettings
        ) {
          return <LoadingTeamsPlaceholder />;
        }
        return <TeamSettings teamData={localState.teamSettingsData} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-[#100f10] w-full border border-[#2e2e2d] rounded-md">
      <div className="flex flex-row justify-center px-4 border-b border-b-[#2C2A2C]">
        <div className="flex flex-row justify-between items-center md:px-3 w-full max-w-3xl mx-auto">
          <h1 className="hidden md:block text-2xl font-normal text-white">
            Settings
          </h1>
          <div className="md:flex md:w-auto flex flex-row md:justify-around w-full">
            <button
              className={`md:mr-5 w-full px-0 h-14 ${
                activeTab === "personal"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => {
                setActiveTab("personal");
                posthog.capture("Click Settings Account Tab");
              }}
            >
              Account
            </button>
            <button
              className={`md:mr-5 w-full px-0 h-14 ${
                activeTab === "profile"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => {
                setActiveTab("profile");
                posthog.capture("Click Settings Profile Tab");
              }}
            >
              Profile
            </button>
            <button
              className={`flex w-full ${
                !isTeamMember && `hover:text-white duration-200`
              } flex-row items-center gap-2 justify-center px-0 h-14 ${
                activeTab === "organization"
                  ? "text-white border-b border-b-white"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
              onClick={() => {
                if (!isTeamMember) {
                  posthog.capture("Click Create Team Navigation");
                  dispatch(setRedirectAfterSelectedPlan("/create-team"));
                  dispatch(setSubscriptionModal(true));
                  // navigate("/create-team");
                } else {
                  posthog.capture("Click Settings Team Tab");
                  setActiveTab("organization");
                }
              }}
            >
              <p className="flex text-nowrap">
                {!isTeamMember ? "Create a Team" : "Team"}
              </p>
              {!isTeamMember && <ArrowUpRightFromSquare className="mt-0.5" />}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 w-full justify-center">
        {renderActiveTab()}
      </div>
    </div>
  );
};

export default Settings;
