import React, { useState, useEffect } from "react";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { ArrowUpRightFromSquare } from "@gravity-ui/icons";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import Account from "./Account";
import ProfileSettings from "./ProfileSettings";
import TeamSettings from "./TeamSettings";
import { getCookie } from "../../utils/cookieUtils";

const ActivePlan = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const { localState, updateLocalState } = useLocalState();
  const [userProfileData, setUserProfileData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isTeamMember, setIsTeamMember] = useState(false);

  // console.log("localState", localState?.userProfileData);

  const getPlanDisplay = (subscriptionType) => {
    if (!subscriptionType)
      return {
        text: "Trial",
        style: {
          background:
            "var(--Color-Light-Opacity-White-5, rgba(255, 255, 255, 0.05))",
        },
      };

    // For Trial Subscription
    if (subscriptionType === "Trial Subscription") {
      return {
        text: "Trial",
        style: {
          background:
            "var(--Color-Light-Opacity-White-5, rgba(255, 255, 255, 0.05))",
        }, // default style
      };
    }

    if (subscriptionType === "Unknown") {
      return {
        text: "Trial",
        style: {
          background:
            "var(--Color-Light-Opacity-White-5, rgba(255, 255, 255, 0.05))",
        }, // default style
      };
    }

    // Split the subscription type
    const words = subscriptionType.split(" ");

    // For Enterprise plans
    if (words.some((word) => word === "Enterprise")) {
      return {
        text: "Enterprise",
        style: {
          background: "linear-gradient(135deg, #FA9A20 0%, #FC10F2 100%)",
        },
      };
    }

    // For Business plans
    if (words.some((word) => word === "Business")) {
      return {
        text: "Business",
        style: {
          background: "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
        },
      };
    }

    // For Basic plans
    if (words.some((word) => word === "Basic")) {
      return {
        text: "Basic",
        style: {
          background: "linear-gradient(to top, #686369, #2C2A2C)",
        }, // default style
      };
    }

    return { text: words[0], style: {} };
  };

  const { text, style } = getPlanDisplay(
    localState?.userProfileData?.subscription_type
  );

  return (
    <div
      style={style}
      className="flex items-center justify-center h-5 border border-[#2e2e2d] rounded-md px-1"
    >
      <div className="text-xs font-normal">{text}</div>
    </div>
  );
};

export default ActivePlan;
